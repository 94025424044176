import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  defaultGray,
  grayHover,
  grayActive,
  white
} from '../../colorPallette.json'

export const Container = styled.div`
  height: 1.8rem;
  display: flex;
  justify-content: space-around;
  padding: 0 .1rem;
  border: 1px solid ${defaultGray};
  border-radius: .3rem;
  align-items: center;
  margin-bottom: 1rem;
  background: ${white};
  @media(max-width: 375px) {
    padding: .3rem .1rem;
  }
`

export const Input = styled.input`
  width: 90%;
  height: 1rem;
  outline: none;
  border: none;
  margin: 0;
  padding: .2rem;
`

export const Icon = styled(FontAwesomeIcon).attrs({
  color: white,
  icon:['fas', 'search'],
})`
  width: 1rem !important;
  height: 1rem !important;
`

export const Button = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background: ${defaultGray};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .3rem;
  cursor: pointer;
  &:hover {
    background: ${grayHover};
  }
  &:active {
    background: ${grayActive};
  }
`