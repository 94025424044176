///internals
import React from 'react'
import PropTypes from 'prop-types'
///components
import {
  Input,
  Container
} from './styled'

const GeneralInput = ({ loading, ...props }) => (
  <Container>
    <Input {...props} />
    {
      loading && <div
        className='loader'
      />
    }
  </Container>
)

GeneralInput.defaultProps = {
  type: 'text'
}

GeneralInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}

export default GeneralInput
