import React, { Component } from 'react'
import { 
  VerticalNavBar
} from '../../components'


export default class AdminHome extends Component {
  render () {
    const { location: {pathname} } = this.props
    return (
      <div>
        <VerticalNavBar pathname={pathname}/>
      </div>
    )
  }
}