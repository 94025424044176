import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
  MainContainer,
  Colors,
  ColorBox,
  ColorSquare
} from './styled'
import {
  yellow,
  green,
  blue,
  red
} from '../../colorPallette.json'

moment.locale('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
  monthsShort: 'en_feb,mar_abr_may_jun_jul_agt_sept_oct_nov_dic'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort : 'Dom._Lun._Mar._Mie._Jue._Vie._Sab.'.split('_')
})

const localizer = BigCalendar.momentLocalizer(moment)
const messages = {
  allDay: 'Todo el día',
  previous: 'anterior',
  next: 'siguiente',
  today: 'Hoy',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
  showMore: total => `+ Ver mas (${total})`
}

export default class Calendar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      events: this.props.events
    }
  }
  handleSelect = ({ start, end }) => {
    const title = window.prompt('New Event name')
    if (title)
      this.setState({
        events: [
          ...this.state.events,
          {
            title,
            start,
            end,
          },
        ],
      })
  }
  changeStyle ({ color }) {    
    var style = {
      backgroundColor: color
    }
    return {
      style
    }
  }
  render () {
    const { history } = this.props
    return (
      <MainContainer>
        <BigCalendar
          localizer={localizer}
          events={this.props.events}
          startAccessor='start'
          endAccessor='end'
          culture='es'
          selectable
          onSelectEvent={event => {
            const { id } = event
            history.push(`/admin/citas-detalle/${id}`, { fromCalendar: true })
          }}
          eventPropGetter={this.changeStyle}
          messages={messages}
          popup
          rtl='es-MX'
        />
        <Colors>
          <ColorBox>
            <ColorSquare
              color={blue}
            />
            Citas
          </ColorBox>
          <ColorBox>
            <ColorSquare
              color={'#FF5722'}
            />
            En Servicio
          </ColorBox>
          <ColorBox>
            <ColorSquare
              color={yellow}
            />
            Detenidas
          </ColorBox>
          <ColorBox>
            <ColorSquare
              color={green}
            />
            Finalizadas
          </ColorBox>
          <ColorBox>
            <ColorSquare
              color={red}
            />
            Canceladas
          </ColorBox>
        </Colors>
      </MainContainer>
    )
  }
}

// Calendar.defaultProps = {
//   events: events
// }

Calendar.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      status: PropTypes.string,
      color: PropTypes.string
    })
  )
}