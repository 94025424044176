import swal from '@sweetalert/with-react'
import moment from 'moment'
import {
  getClientOrCreate,
  createUser,
  createAppointment,
  createAppointmentFromEmail
} from './actions'

const URL = process.env.REACT_APP_BASE_URL

export async function handleCarByVin (e, onSucess, onError, connection) {
  const vin = e.target.value
  if (vin.length > 0) {
    const { getCarByVin } = this.props
    const car = await getCarByVin(vin, connection)
    if (car && car.hasOwnProperty('error')) {
      // this.setState({ vinErrors: true })
      onError('vin', 'El vin proporcionado no esta registrado con nosotros,\n te recomendamos buscar por año, marca y modelo')
      return
    }
    if (car && !car.hasOwnProperty('error')) {
      if (car && car.year) onSucess('year', car.year)
      if (car && car.brand) onSucess('brand', car.brand)
      if (car && car.model) onSucess('model', car.model)
      if (car && car.car_id) onSucess('car_id', car.car_id)
      this.setState({ modelFromVIN: true })
      this.setState({ vinErrors: false })
    } else {
      this.setState({ modelFromVIN: false })
      this.setState({ vinErrors: false })
      onSucess('year', '')
      onSucess('brand', '')
      onSucess('model', '')
      onSucess('car_id', '')
      onSucess('vin', vin)
    }
    return
  } else {
    this.setState({ vinErrors: false })
    this.setState({ modelFromVIN: false })
    onSucess('year', '')
    onSucess('brand', '')
    onSucess('model', '')
    onSucess('car_id', '')
    onSucess('vin', vin)
  }
}

export async function onSubmitForm (values, actions, isAdmin) {
  this.setState(prevState => {
    return {
      ...prevState,
      isLoading: true
    }
  })
  const {
    connections
  } = this.props
  const officesConnections = connections.map(conn => conn.name)
  const connection = connections.filter(conn => conn.name === values.office)
  const { appointmentDate, appointmentHour } = values
  const date = moment(appointmentDate).format('DD/MM/YYYY')
  const hour = moment(appointmentHour).format('HH:mm')
  if (officesConnections.includes(values.office)) {
    this.setState({
      step: 'Verificando correo',
      redirect: true
    })
    const [client, clientError] = await getClientOrCreate(values)
    if (clientError) {
      this.setState({
        error: clientError.error,
        status: 'failed',
        step: 'Ops! courrio un error'
      })
    }
    if (client && client.hasOwnProperty('client_id')) {
      const clientID = client.client_id
      let { email } = values
      const [userkey, error] = await verifyExistentUser(email)
      var key
      if (error) {
        this.setState({ step: 'Creando cuenta de usuario' })
        const [newkey, createUserError] = await createUser({ ...values, person_id: clientID, from_web: true })
        // missing catch the createUserError in this line
        if (newkey && newkey.key) key = newkey.key
        if (createUserError) {
          console.log('error create', createUserError)
          if (createUserError.error) {
            this.setState({
              error: createUserError.error,
              step: 'Ops! courrio un error',
              status: 'failed',
              isLoading: false
            })
          } else {
            this.setState({
              error: createUserError.email,
              step: 'Ops! courrio un error',
              status: 'failed',
              isLoading: false
            })
          }
          return
        }
      }
      if (userkey && userkey.key && !error) {
        key = userkey.key
      }
      this.setState({ step: 'Creando cita' })
      const appointment = await createAppointment({
        ...values,
        person_id: clientID,
        token: key,
        appointmentDate: date,
        appointmentHour: hour
      }, connection)
      if (appointment.hasOwnProperty('error')) {
        this.setState({
          status: 'failed',
          step: 'Ops! ocurrio un error',
          error: handleErrors(appointment.error)
        })
      } else if (appointment.hasOwnProperty('model')) {
        this.setState({
          status: 'failed',
          step: 'Ops! ocurrio un error',
          error: 'VIN incorrecto'
        })
      } else {
        this.setState({
          status: 'success',
          step: 'Cita creada',
          appointment: appointment
        })
        if (!isAdmin) {
          setTimeout(() => {
            this.redirect()
          }, 3000)
        } else {
          this.getAppointments()
          swal('Cita Registrada', 'Se ha regisrado la cita exitosamente', 'success', {
            timer: 4000,
            button: false,
            closeOnClickOutside: false
          }).then(res => {
            this.redirect('/admin/citas', isAdmin)
          })
        }
      }
      // show the modal of success appointment
      // then navigate to the confirmation screen and send the appointment info
      this.setState(prevState => {
        return {
          ...prevState,
          isLoading: false
        }
      })
    } else {
      // if an error occurs show the error in some point of the form
      const error = client
      this.setState({
        status: 'failed',
        isLoading: false,
        step: 'Ops! courrio un error',
        error: error.error
      })
      swal('!Uh-Oh!', error.error, 'warning', {
        timer: 4000,
        button: false,
        closeOnClickOutside: false
      })
    }
  } else {
    this.setState({
      step: 'Creando cita',
      redirect: false
    })
    const [appointmentFromEmail, error] = await createAppointmentFromEmail({
      ...values,
      appointmentDate: date,
      appointmentHour: hour
    })
    if (appointmentFromEmail) {
      this.setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          status: 'success',
          step: 'Cita creada'
        }
      })
      if (isAdmin) {
        swal('Cita Registrada', 'Se envio el correo con la fecha y hora de la cita al cliente', 'success', {
          timer: 4000,
          button: false,
          closeOnClickOutside: false
        }).then(res => {
          this.redirect('/admin/citas', isAdmin)
        })
      }
    }
    if (error) {
      this.setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          status: 'failed',
          step: 'Ops! ocurrio un problema',
          error: 'Al parecer aun no se cuentan con fechas y horas disponibles para esta sucursal, contacta con automotriz tabasco para mas información'
        }
      })
      if (isAdmin) {
        swal('!Uh-Oh!', 'Al parecer no contamos con fecha y hora disponible para la sucursal seleccionada', 'warning', {
          timer: 4000,
          button: false,
          closeOnClickOutside: false
        })
      }
    }
  }
}

const verifyExistentUser = async email => {
  try {
      const response = await fetch(`${URL}/get-key-by-email/?email=${email}`)
      const data = await response.json()
      if (data && data.error) return [null, data.error]
      else return [data, null]
  }
  catch(e) {
      return [null, e]
  }
}

function handleErrors (error) {
  if ('You have a previous appointment' === error.substring(0, Math.min(error.length, 31))) return 'Ya tiene una cita en esta fecha con el mismo automovil, intenta en otra fecha'
  else return error
}