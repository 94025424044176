import _ from 'lodash'

const initialState = {
  sms_notification: false,
  email_notification: false,
  push_notification: false,
  time_for_last_notification: '',
  conmutator_phone: '',
  offices: {},
  officesArray: [],
  file: '',
  holidays: []
}

const Configuration = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_HOLIDAYS':
      return {
        ...state,
        holidays: payload
      }
    case 'GET_SETTINGS_SUCCESS':
      return {
        ...state,
        ...payload
      }
    case 'GET_OFFICES_SUCCESS':
      if (_.isEmpty(payload)) {
        return {
          ...state,
          offices: {},
          officesArray: initialState.officesArray
        }
      } else {
        return {
          ...state,
          offices: {
            ...getOfficesInFormat(payload)
          },
          officesArray: [].concat(initialState.officesArray, payload)
        }
      }
    default:
      return state
  }
}

function getOfficesInFormat (offices) {
  const initialState = {}
  if (offices && Array.isArray(offices)) {
    const keys = offices.map(office => office.location)
    const values = offices.map(office => {
      const { location, ...args } = office
      return args
    })
    keys.forEach((key, index) => {
      initialState['offices'] = {
        ...initialState.offices,
        [key]: values[index]
      }
    })
  }
  return initialState.offices
}

export default Configuration
