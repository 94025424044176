import React, { Component } from 'react'
import _ from 'lodash'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { connect } from 'react-redux'
import Panel from './panel'

class PeopleTabs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tabIndex: 0,
      searchValue: ''
    }
    this.searchByValue = this.searchByValue.bind(this)
  }
  searchByValue () {
    const {
      tabIndex,
      searchValue
    } = this.state
    const {
      consultants,
      mechanics
    } = this.props
    if (!tabIndex) {
      if (_.isEmpty(searchValue)) {
        return consultants
      } else {
        return consultants.filter(consultant => {
          return consultant.external_id.includes(searchValue)
        })
      }
    } else {
      if (_.isEmpty(searchValue)) {
        return mechanics
      } else {
        return mechanics.filter(mechanic => {
          return mechanic.external_id.includes(searchValue)
        })
      }
    }
  }
  render () {
    const { tabIndex } = this.state
    const {
      handleSubmit
    } = this.props
    return (
      <Tabs
        selectedIndex={tabIndex}
        onSelect={tabIndex => {
          this.setState({
            tabIndex,
            searchValue: ''
          })
        }}
      >
        <TabList>
          <Tab>Asesores</Tab>
          <Tab>Mecánicos</Tab>
        </TabList>
        <TabPanel>
          <Panel
            form='Consultants'
            onSearch={({ target: { value } }) => this.setState({ searchValue: value.toUpperCase() })}
            searchPlaceholder='Buscar asesores por ID (EHC o AKM1)'
            workers={this.searchByValue()}
            handleSubmit={handleSubmit}
          />
        </TabPanel>
        <TabPanel>
          <Panel
            form='Mechanics'
            onSearch={({ target: { value } }) => this.setState({ searchValue: value.toUpperCase() })}
            searchPlaceholder='Buscar mecánicos por ID (EHC o AKM1)'
            workers={this.searchByValue()}
            handleSubmit={handleSubmit}
          />
        </TabPanel>
      </Tabs>
    )
  }
}

function mapStateToProps ({ Workers }) {
  return {
    consultants: Workers.consultants,
    mechanics: Workers.mechanics
  }
}

export default connect(mapStateToProps, null)(PeopleTabs)
