import styled from 'styled-components'
import { darkBlue, white } from '../../colorPallette'

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${darkBlue};
  padding: 35px 5vw 20px 5vw;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @media (min-width: 1500px) {
    padding: 3rem 5vw 2.5rem 5vw;
  }

  @media (max-width: 900px) {
    padding: 35px 6vw 20px 6vw;
  }
`

export const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;

  @media (min-width: 1500px) {
    margin-top: 2.5rem;
  }

  @media (max-width: 450px) {
    margin-top: 90px;
  }
`

export const TopLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;

  @media (max-width: 700px) {
    flex: 1;
  }
`

export const TopRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  @media (max-width: 700px) {
    flex: 1;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({paddingRight}) => paddingRight && 'padding-right: 5vw'};
`

export const IconsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  ${({paddingRight}) => paddingRight && 'padding-right: 5vw'};

  @media (max-width: 700px) {
    padding: 0;
    ${({main}) => main && 'flex: 2'};
  }

  @media (max-width: 550px) {
    ${({empty}) => empty && 'display: none'};
    padding-right: 7vw;
  }
`

export const FlexRow = styled.div`
  display: flex;
  ${({flex}) => flex && `flex: ${flex}`};
`

export const TopInnerLeft = styled(FlexRow)`
  flex: 3;

  @media (max-width: 900px) {
    flex-direction: column;
    padding-right: 10vw;
    flex-wrap: wrap;

    & > div {
      padding: 0;
      flex-basis: 50%;
    }

    & > div:last-child {
      flex-basis: 30%;
    }
  }

  @media (max-width: 700px) {
    padding-right: 15vw;

    & > div:first-child {
      flex-basis: 5%;
    }
  }

  @media (max-width: 550px) {
    padding-right: 5vw;
  }

  @media (max-width: 450px) {
    padding-right: 0;
  }
`

export const BottomInnerLeft = styled(FlexRow)`
  margin-top: 45px;

  @media (min-width: 1500px) {
    margin-top: 4rem;
  }

  @media (max-width: 900px) {
    margin-top: 0;
    margin-bottom: -5px;
    align-items: flex-end;
  }

  @media (max-width: 450px) {
    margin-bottom: -75px;
  }
`

export const TopInnerRight = styled(FlexRow)`
  flex: 7;

  @media (max-width: 900px) {
    flex-wrap: wrap;

    & > div {
      flex-basis: 50%;
    }

    & > div:last-child {
      margin-top: 25px;
    }
  }

  @media (max-width: 700px) {
    margin-left: 70px;

    & > div:first-child {
      margin: 0;
    }

    & > div {
      flex-basis: 100%;
      margin-top: 20px;
    }
  }

  @media (max-width: 450px) {
    margin: 0;
  }
`

export const CircleIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: ${white};

  @media (min-width: 1500px) {
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;

    & svg {
      width: 1.5rem !important;
      height: 1.5rem;
    }
  }
`

export const AppDownload = styled.a`
  display: flex;
  flex-shrink: 0;
  ${({margin}) => margin && 'margin: 0 15px 0 20px'};

  & img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1500px) {
    ${({margin}) => margin && 'margin: 0 2rem'};
  }
`

export const DownloadTitle = styled.span`
  color: ${white};
  font-weight: 100;
  font-size: 15px;

  @media (min-width: 1500px) {
    font-size: 1.5rem;
    font-weight: 200;
  }
`

export const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  & a {
    width: 120px;
    height: 45px;
  }

  & > div {
    align-items: center;
  }

  & > div > a:last-child {
    height: 31px;
    width: 90px;
  }

  @media (min-width: 1500px) {
    & a {
      width: 13rem;
      height: 5rem;
    }

    & > div > a:last-child {
      width: 10rem;
      height: 3.3rem;
    }
  }

  @media (max-width: 900px) {
    margin-top: 35px;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    flex: 1.4;

    & > div {
      margin-top: 15px;
    }
  }

  @media (max-width: 450px) {
    align-items: flex-start;
    & > div {
      flex-direction: column;
    }

    & > div > a {
      margin: 10px 0 0 0;
    }
  }
`

export const Text = styled.span`
  color: ${white};
  font-weight: 100;
  font-size: 11px;
  line-height: 14px;

  @media (min-width: 1500px) {
    font-size: 1rem;
    line-height: 1.3rem;
  }
`

export const PrivacyNotice = styled.a`
  color: ${white};
  font-weight: 100;
  text-decoration: none;
  font-size: 10px;

  @media (min-width: 1500px) {
    font-size: 1rem;
  }
`

export const Title = styled.span`
  color: ${white};
  font-size: 14px;
  margin-bottom: 10px;

  @media (min-width: 1500px) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
`
