import styled from 'styled-components'

export const LoginBox = styled.div`
  width: 15rem;
  height: 15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #c5c5c5;
  border-radius: 5px;
  padding: 1rem;
`

export const Container = styled.div`
  width: 100%;
  padding-top: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Input = styled.input`
  width: 10rem;
  height: 3rem;
  border: 1px solid black;
  border-radius: 4px;
`

export const Button = styled.button`
  width: 6rem;
  height: 3rem;
  border-radius: 4px;
`
