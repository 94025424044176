import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import * as es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
import {
  InputSelect,
  GeneralInput,
  Checkbox,
  GeneralButton,
  Footer,
  Loader
} from '../../components'
import {
  Maincontainer,
  Title,
  Legend,
  StyledForm,
  FormRow,
  IntructionsText,
  Block,
  TextArea,
  Top,
  CheckboxTextContainer,
  Column,
  BottomContent,
  MessageError,
  CustomInput,
  LoaderContainer,
  Box,
  StyledIcon,
  BottomBox,
  TopBox,
  CenterBox,
  AdminContainer,
  LoadingContainerForAdmin
} from './styled'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import {
  getBrands,
  getModelsByBrand,
  // getYearByModel,
  getCarByVin,
  getModels,
  getOffices,
  getCalendar
} from './actions'
import { adminScheme, getPublicSchema } from './yup'
import {
  getTomorrow,
  calculateSundays,
  getTime,
  excludeHours
} from './utils'
import {
  handleCarByVin,
  onSubmitForm
} from './apiCalls'
import { fetchHolidays } from '../adminConfig/actions'
import {
  GetAppointments
} from '../adminAppointments/actions'
import {
  red,
  green
} from '../../colorPallette.json'

registerLocale('es', es)

const initialValues = {
  name: '',
  lastname: '',
  motherLastName: '',
  email: '',
  servicetype: '',
  vin: '',
  phone: '',
  office: '',
  brand: '',
  model: '',
  checkterms: false,
  appointmentDate: '',
  appointmentHour: '',
  car_id: ''
}

class Apointment extends Component {
  constructor (props) {
    super(props)
    this.state = {
      startDate: getTomorrow(),
      sundays: calculateSundays(),
      isLoading: false,
      vinErrors: false,
      redirect: false,
      hasClickedOutside: false,
      hasClickedOutsideHour: false,
      calendar: null,
      excluded: []
    }
    this.dateChange = this.dateChange.bind(this)
    this.selectChange = this.selectChange.bind(this)
    this.onSubmitForm = onSubmitForm.bind(this)
    this.handleCarByVin = handleCarByVin.bind(this)
    this.handleDatePicker = this.handleDatePicker.bind(this)
    this.resetStatus = this.resetStatus.bind(this)
    this.redirect = this.redirect.bind(this)
    this.isFromAdmin = this.isFromAdmin.bind(this)
    this.onMonthChange = this.onMonthChange.bind(this)
    this.getAppointments = this.getAppointments.bind(this)
    this.getExcludedHours = this.getExcludedHours.bind(this)
    this.getConnectionByOffice = this.getConnectionByOffice.bind(this)
    this.onChangeOffice = this.onChangeOffice.bind(this)
  }
  async componentDidMount () {
    const today = moment()
    const month = today.format('MM')
    const year = today.format('YYYY')
    const { cleanReducers, getOffices, getCalendar, getHolidays } = this.props
    if (typeof getHolidays === 'function') getHolidays()
    cleanReducers('FETCH_BRANDS_SUCCESS')
    cleanReducers('FETCH_MODEL_BY_BRANDS_SUCCESS')
    getOffices()
    const calendar = await getCalendar(
      month,
      year,
      'automotriz_tab'
    )
    const keys = Object.keys(calendar)
    const excludedDates = keys.map(async key => {
      if (!_.isEmpty(calendar[key]) && calendar[key].length >=38) {
        const [d, m, y] = await key.split('/')
        return new Date(y, m, d)
      }
    })
    this.setState({
      calendar,
      sundays: [].concat(this.state.sundays, excludedDates)
    })
  }
  getExcludedHours () {
    const { calendar } = this.state
    const excluded = excludeHours()
    if (this.formikRef) {
      const { values } = this.formikRef.state
      if (values.appointmentDate) {
        const d = moment(new Date(values.appointmentDate)).format('DD/MM/YYYY')
        if (calendar[d]) {
          const excludedFromAPI = calendar[d].map(async hours => {
            const [hour, minutes] = await hours.split(':')
            const date = new Date()
            date.setHours(hour)
            date.setMinutes(minutes)
          })
          return [].concat(excluded, excludedFromAPI)
        }
        return excluded
      }
    }
    return excluded
  }
  async onMonthChange (date) {
    const { getCalendar, connections } = this.props
    const connectionOffices = connections.map(conn => conn.name)
    const { values } = this.formikRef.state
    const d = moment(new Date(date))
    const month = d.format('MM')
    const year = d.format('YYYY')
    const calendar = await getCalendar(
      month,
      year,
      connectionOffices.includes(values.office)
        ? this.getConnectionByOffice(values.office)
        : this.getConnectionByOffice('Villahermosa')
    )
    const keys = Object.keys(calendar)
    const excludedDates = keys.map(async key => {
      if (!_.isEmpty(calendar[key]) && calendar[key].length >= 38) {
        const [d, m, y] = await key.split('/')
        return new Date(y, m, d)
      }
    })
    this.setState({
      calendar,
      sundays: [].concat(this.state.sundays, excludedDates)
    })
  }
  getAppointments () {
    const { getAppointmentsAfter } = this.props
    if (typeof getAppointmentsAfter === 'function') {
      getAppointmentsAfter()
    }
  }
  dateChange (date) {
    this.setState({
      startDate: date
    })
  }
  selectChange (e, callback, action) {
    const { values } = this.formikRef.state
    const {
      brand,
      model
    } = values
    callback(e)
    action()
    if (
      e.target.name === 'year' &&
      !_.isEmpty(brand)
    ) {
      this.formikRef.setFieldValue('brand', '')
      this.formikRef.setFieldValue('model', '')
      this.formikRef.setFieldValue('car_id', '')
      this.formikRef.setFieldValue('vin', '')
    }
    if (
      e.target.name === 'brand' &&
      !_.isEmpty(model)
    ) {
      this.formikRef.setFieldValue('model', '')
      this.formikRef.setFieldValue('car_id', '')
      this.formikRef.setFieldValue('vin', '')
    }
  }
  handleDatePicker (field, value, onSuccess) {
    onSuccess(field, value)
  }
  redirect (route = '/confirmacion-cita', isAdmin) {
    let { history: { push } } = this.props
    if (!isAdmin) {
      let { appointment } = this.state
      push(route, appointment)
    } else {
      push(route)
    }
  }
  lowerCaseEmail (e) {
    let email = e.target.value
    return email.toLowerCase()
  }
  resetStatus () {
    this.setState({
      status: '',
      isLoading: false,
      step: '',
      error: ''
    })
  }
  isFromAdmin () {
    const { path } = this.props
    const regEx = /\/admin\/agendar-cita/
    return regEx.test(path)
  }
  getMomentTime (time) {
    const [hour, minutes, seconds] = time.split(':')
    return moment().hour(parseInt(hour)).minutes(parseInt(minutes)).seconds(parseInt(seconds)).format('HH:mm A')
  }
  async onChangeOffice (e, handleChange, setFieldValue) {
    const event = e
    const { connections, getCalendar } = this.props
    const { values } = this.formikRef.state
    const connectionOffices = connections.map(conn => conn.name)
    const { value } = event.target
    if (value === 'Sucursal*') {
      setFieldValue('office', '')
      setFieldValue('year', '')
      setFieldValue('brand', '')
      setFieldValue('model', '')
      setFieldValue('car_id', '')
      setFieldValue('vin', '')
    }
    if (value !== 'Sucursal*' && value !== values.office) {
      setFieldValue('office', value)
      setFieldValue('year', '')
      setFieldValue('brand', '')
      setFieldValue('model', '')
      setFieldValue('car_id', '')
      setFieldValue('vin', '')
    }
    const today = moment()
    const month = today.format('MM')
    const year = today.format('YYYY')
    const calendar = await getCalendar(
      month,
      year,
      connectionOffices.includes(value)
        ? this.getConnectionByOffice(value)
        : this.getConnectionByOffice('Villahermosa')
    )
    const keys = Object.keys(calendar)
    const excludedDates = keys.map(async key => {
      if (!_.isEmpty(calendar[key]) && calendar[key].length >= 38) {
        const [d, m, y] = await key.split('/')
        return new Date(y, m, d)
      }
    })
    this.setState({
      calendar,
      sundays: [].concat(this.state.sundays, excludedDates)
    })
  }
  render () {
    const {
      brands,
      models,
      isLoadingVIN,
      isLoadingBrands,
      isloadingModelsByBrands,
      history: { push },
      officesForSelect,
      offices,
      connections,
      holidays
    } = this.props
    const {
      sundays,
      isLoading,
      step,
      vinErrors,
      modelFromVIN,
      status,
      error,
      redirect,
      hasClickedOutside,
      hasClickedOutsideHour
    } = this.state
    const isAdmin = this.isFromAdmin()
    const connectionOffices = connections.map(conn => conn.name)
    return (
      <AdminContainer isAdmin={isAdmin}>
        {
          isAdmin && (
            <div style={{ width: '95%' }}>
              <h2 style={{ margin: 0, paddingBottom: '1rem', fontSize: '1.1rem' }}>AGENDAR CITA</h2>
            </div>
          )
        }
        <Maincontainer isAdmin={isAdmin}>
          {
            (isAdmin && isLoading) && (
              <LoadingContainerForAdmin>
                <Loader />
              </LoadingContainerForAdmin>
            )
          }
          {
            (((step && step !== '') || isLoading) && !isAdmin) &&
            <LoaderContainer>
              <Box status={status}>
                <TopBox>
                  {isLoading ? <Loader />
                    : status === 'failed' ? <StyledIcon color={red} icon={['far', 'times-circle']} />
                      : <StyledIcon color={green} icon={['far', 'check-circle']} />
                  }
                </TopBox>
                <CenterBox>
                  <span>
                    {error || step}
                  </span>
                </CenterBox>
                {
                  status === 'success' && redirect ? (
                    <BottomBox>
                      <span>Espere a ser redireccionado o haga click en el boton redireccionar</span>
                      <GeneralButton onClick={this.redirect}>
                        Redireccionar
                      </GeneralButton>
                    </BottomBox>
                  ) : error ? (
                    <BottomBox>
                      <span>Intente de nuevo</span>
                      <GeneralButton onClick={this.resetStatus}>
                        Cerrar
                      </GeneralButton>
                    </BottomBox>
                  ) : !redirect && (
                    <BottomBox>
                      <span>Se a generado tu cita, revisa tu correo</span>
                      <GeneralButton onClick={() => push('/')}>
                        Ir a la pagina de inicio
                      </GeneralButton>
                    </BottomBox>
                  )
                }
              </Box>
            </LoaderContainer>
          }
          {
            !isAdmin ? (
              <Top>
                <Title>PROGRAMA UNA CITA DE SERVICIO</Title>
                <Legend>*Campos obligatorios</Legend>
              </Top>
            ) : (
              <Top isAdmin={isAdmin}>
                <Legend>* Campos obligatorios</Legend>
              </Top>
            )
          }
          <Formik
            initialValues={initialValues}
            validationSchema={
              isAdmin ? adminScheme() : getPublicSchema(connectionOffices)
            }
            onSubmit={(values, actions) => this.onSubmitForm(values, actions, isAdmin)}
            ref={ref => { this.formikRef = ref }}
          >
            {({
              errors,
              touched,
              isValid,
              handleChange,
              values,
              setFieldValue,
              handleSubmit,
              setFieldError,
              isSubmitting,
              setFieldTouched
            }) => {
              const EXCLUDED_HOURS = this.getExcludedHours()
              return (
                <StyledForm>
                  <FormRow>
                    <Column>
                      <GeneralInput
                        placeholder='Nombre(s)*'
                        name='name'
                        type='text'
                        id='name'
                        onChange={handleChange}
                        hasError={errors.name && touched.name}
                        tabIndex='1'
                      />
                      <MessageError>
                        {errors.name && touched.name && <span>{errors.name}</span>}
                      </MessageError>
                      <GeneralInput
                        margin='1.5rem 0 0 0'
                        placeholder='Apellido Materno*'
                        name='motherLastName'
                        type='text'
                        id='motherLastName'
                        onChange={handleChange}
                        hasError={errors.motherLastName && touched.motherLastName}
                        tabIndex='3'
                      />
                      <MessageError>
                        {errors.motherLastName && touched.motherLastName && <span>{errors.motherLastName}</span>}
                      </MessageError>
                      <GeneralInput
                        margin='1.5rem 0 0 0'
                        placeholder='Correo electrónico*'
                        name='email'
                        type='email'
                        id='email'
                        onChange={e => setFieldValue('email', this.lowerCaseEmail(e))}
                        hasError={errors.email && touched.email}
                        tabIndex='5'
                      />
                      <MessageError>
                        {errors.email && touched.email && <span>{errors.email}</span>}
                      </MessageError>
                    </Column>
                    <Column>
                      <GeneralInput
                        placeholder='Apellido Paterno*'
                        name='lastname'
                        type='text'
                        id='lastname'
                        onChange={handleChange}
                        hasError={errors.lastname && touched.lastname}
                        tabIndex='2'
                      />
                      <MessageError>
                        {errors.lastname && touched.lastname && <span>{errors.lastname}</span>}
                      </MessageError>
                      <GeneralInput
                        margin='1.5rem 0 0 0'
                        placeholder='Telefono*'
                        name='phone'
                        type='text'
                        id='phone'
                        onChange={e => {
                          if (e.target.value.length <= 10) {
                            handleChange(e)
                          }
                        }}
                        hasError={errors.phone && touched.phone}
                        tabIndex='4'
                      />
                      <MessageError>
                        {errors.phone && touched.phone && <span>{errors.phone}</span>}
                      </MessageError>
                      <InputSelect
                        margin='1.5rem 0 0 0'
                        options={officesForSelect}
                        placeholder='Sucursal*'
                        height='2rem'
                        width='100%'
                        name='office'
                        id='office'
                        onChange={e => {
                          e.persist()
                          this.onChangeOffice(e, handleChange, setFieldValue)
                        }}
                        hasError={errors.office && touched.office}
                      />
                      <MessageError>
                        {errors.office && touched.office && <span>{errors.office}</span>}
                      </MessageError>
                    </Column>
                  </FormRow>
                  <FormRow margin='25px 0 0 0'>
                    <Column>
                      <Block width='100%'>
                        <IntructionsText>
                          Si cuenta con el VIN de su vehiculo.Ingréselo en la siguiente casilla.
                        </IntructionsText>
                        <GeneralInput
                          placeholder='VIN'
                          name='vin'
                          type='search'
                          id='vin'
                          onChange={e => {
                            const vin = e.target.value.toUpperCase()
                            if (vin.length < 18) {
                              setFieldTouched('vin', true)
                              setFieldValue('vin', vin)
                              if (vin.length === 17) {
                                this.handleCarByVin(
                                  e,
                                  setFieldValue,
                                  setFieldError,
                                  connectionOffices.includes(values.office)
                                    ? this.getConnectionByOffice(values.office)
                                    : this.getConnectionByOffice('Villahermosa')
                                )
                              }
                            }
                          }}
                          hasError={(errors.vin && touched.vin) || vinErrors}
                          disabled={
                            ((values.year && values.year !== 'Año') && !modelFromVIN) || _.isEmpty(values.office)
                          }
                          loading={isLoadingVIN}
                        />
                        <MessageError>
                          {errors.vin && touched.vin && <span>{errors.vin}</span>}
                        </MessageError>
                      </Block>
                    </Column>
                  </FormRow>
                  <FormRow margin='18px 0 0 0'>
                    <Column responsiveHeight='7.3rem' flexEnd>
                      <Block>
                        <IntructionsText margin='0'>
                          Si no cuenta con un VIN seleccione el modelo y marca de su vehículo.
                        </IntructionsText>
                        <InputSelect
                          margin='7px 0 0 0'
                          options={[
                            2000,
                            2001,
                            2002,
                            2003,
                            2004,
                            2005,
                            2006,
                            2007,
                            2008,
                            2009,
                            2010,
                            2011,
                            2012,
                            2013,
                            2014,
                            2015,
                            2016,
                            2017,
                            2018,
                            2019
                          ].reverse()}
                          placeholder='Año'
                          height='2rem'
                          width='100%'
                          name='year'
                          id='year'
                          disabled={values.vin || _.isEmpty(values.office)}
                          onChange={e => {
                            setFieldError('vin', '')
                            setFieldTouched('vin', false)
                            this.selectChange(
                              e,
                              handleChange,
                              () => this.props.getModelsByBrand(
                                e.target.value,
                                connectionOffices.includes(values.office)
                                  ? this.getConnectionByOffice(values.office)
                                  : this.getConnectionByOffice('Villahermosa')
                              )
                            )
                          }}
                        />
                        <MessageError>
                          {errors.year && touched.year && <span>{errors.year}</span>}
                        </MessageError>
                      </Block>
                      <InputSelect
                        margin='1rem 0 0 0'
                        options={models}
                        placeholder='Modelo'
                        height='2rem'
                        width='100%'
                        name='model'
                        id='model'
                        value={values.model}
                        onChange={event => {
                          const [model, carId] = event.target.value.split('-')
                          if (model) {
                            handleChange(event)
                          }
                          if (carId) {
                            setFieldValue('car_id', carId)
                          }
                        }}
                        disabled={!_.isEmpty(values.vin) || _.isEmpty(values.brand) || _.isEmpty(values.office)}
                        loading={isloadingModelsByBrands}
                      />
                      <MessageError>
                        {errors.model && touched.model && <span>{errors.model}</span>}
                      </MessageError>
                    </Column>
                    <Column responsiveHeight='8rem' forceflexEnd>
                      <Block>
                        <InputSelect
                          options={brands}
                          placeholder='Marca del vehiculo'
                          height='2rem'
                          width='100%'
                          name='brand'
                          id='brand'
                          onChange={e => this.selectChange(
                            e,
                            handleChange,
                            () => this.props.getModels(
                              values.year,
                              e.target.value,
                              connectionOffices.includes(values.office)
                                ? this.getConnectionByOffice(values.office)
                                : this.getConnectionByOffice('Villahermosa')
                            )
                          )}
                          value={values.brand}
                          className='brandselect'
                          hasError={errors.brand && touched.brand}
                          loading={isLoadingBrands}
                          disabled={!_.isEmpty(values.vin) || _.isEmpty(values.year) || _.isEmpty(values.office)}
                        />
                        <MessageError>
                          {errors.brand && touched.brand && <span>{errors.brand}</span>}
                        </MessageError>
                      </Block>
                      <InputSelect
                        margin='1rem 0 0 0'
                        options={['Servicio de diagnostico', 'Servicio de mantenimiento', 'Servicio de reparación']}
                        placeholder='Tipo de servicio*'
                        height='2rem'
                        width='100%'
                        name='servicetype'
                        id='servicetype'
                        onChange={handleChange}
                        hasError={errors.servicetype && touched.servicetype}
                      />
                      <MessageError>
                        {errors.servicetype && touched.servicetype && <span>{errors.servicetype}</span>}
                      </MessageError>
                    </Column>
                  </FormRow>
                  <FormRow height='9rem' margin='1rem 0 0 0'>
                    <TextArea
                      component='textarea'
                      placeholder='Observaciones(El pedal se siente muy duro, el auto derrama aceite.....)'
                      name='observations'
                      onChange={handleChange}
                    />
                    <MessageError>
                      {errors.observations && touched.observations && <span>{errors.observations}</span>}
                    </MessageError>
                  </FormRow>
                  {
                    connectionOffices.includes(values.office)
                      ? <FormRow>
                        <Column>
                          <DatePicker
                            selected={values.appointmentDate}
                            locale='es'
                            placeholderText='Seleccione una fecha para su cita'
                            onMonthChange={this.onMonthChange}
                            customInput={<CustomInput />}
                            onChange={date => {
                              this.handleDatePicker('appointmentDate', date, setFieldValue)
                            }}
                            name='appointmentDate'
                            id='appointmentDate'
                            dateFormat='dd/MM/YYYY'
                            excludeDates={[...sundays, ...holidays]}
                            minDate={getTomorrow()}
                            disabled={!connectionOffices.includes(values.office)}
                            onClickOutside={() => this.setState({ hasClickedOutside: true })}
                          />
                          <MessageError>
                            {values.appointmentDate === '' && hasClickedOutside && <span>Campo requerido</span>}
                          </MessageError>
                        </Column>
                        <Column>
                          <DatePicker
                            selected={values.appointmentHour}
                            onChange={hour => {
                              this.handleDatePicker('appointmentHour', hour, setFieldValue)
                            }}
                            placeholderText='Seleccione una hora para su cita'
                            customInput={<CustomInput />}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            dateFormat='h:mm aa'
                            timeCaption='Hora'
                            minTime={getTime(true)}
                            maxTime={getTime()}
                            name='appointmentHour'
                            excludeTimes={EXCLUDED_HOURS}
                            disabled={!connectionOffices.includes(values.office)}
                            onClickOutside={() => this.setState({ hasClickedOutsideHour: true })}
                          />
                          <MessageError>
                            {_.isEmpty(values.appointmentDate) && hasClickedOutsideHour && <span>{errors.appointmentHour}</span>}
                          </MessageError>
                        </Column>
                      </FormRow>
                      : (!_.isEmpty(offices) && !connectionOffices.includes(values.office) && !_.isEmpty(values.office))
                        ? (
                          <FormRow>
                            <Column style={{ width: '100%' }}>
                              <h4>Esta es la fecha y hora definida para atender las citas en {values.office}</h4>
                              <FormRow>
                                <Column>
                                  <GeneralInput
                                    type='text'
                                    value={moment(offices[values.office].date_of_appointments).format('DD/MM/YYYY')}
                                    disabled
                                  />
                                  <MessageError>
                                    {errors.startdate && touched.startdate && <span>{errors.startdate}</span>}
                                  </MessageError>
                                </Column>
                                <Column>
                                  <GeneralInput
                                    type='text'
                                    value={this.getMomentTime(offices[values.office].hour_of_appointments)}
                                    disabled
                                  />
                                  <MessageError>
                                    {errors.enddate && touched.enddate && <span>{errors.enddate}</span>}
                                  </MessageError>
                                </Column>
                              </FormRow>
                            </Column>
                          </FormRow>
                        )
                        : null
                  }
                  <BottomContent isAdmin={isAdmin}>
                    {
                      !isAdmin &&
                      (
                        <Checkbox
                          values='Aceptar'
                          id='checkterms'
                          size={'1rem'}
                          margin='4rem 0 1rem 0'
                          name='checkterms'
                          onChange={handleChange}
                        >
                          <CheckboxTextContainer>
                            Aceptar
                            <a
                              href='https://auto-tabasco.com.mx/Privacidad/'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              Términos y condiciones
                            </a>
                          </CheckboxTextContainer>
                          <MessageError>
                            {errors.checkterms && touched.checkterms && <span>{errors.checkterms}</span>}
                          </MessageError>
                        </Checkbox>
                      )
                    }
                    <GeneralButton
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      type='submit'
                      disabled={!isValid}
                      onClick={handleSubmit}
                      width='13rem'
                    >
                      {
                        (isAdmin && isSubmitting) ? <div className='login_loader' /> : 'CONFIRMAR'
                      }
                    </GeneralButton>
                  </BottomContent>
                </StyledForm>
              )
            }}
          </Formik>
          {
            !isAdmin && (
              <Footer />
            )
          }
        </Maincontainer>
      </AdminContainer>
    )
  }
  getConnectionByOffice (office) {
    const { connections } = this.props
    return connections.filter(conn => conn.name === office).shift().connection
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getBrands: () => dispatch(getBrands()),
    getModelsByBrand: (year, office) => dispatch(getModelsByBrand(year, office)),
    getCarByVin: (vin, connection) => dispatch(getCarByVin(vin, connection)),
    cleanReducers: type => dispatch({ type, payload: [] }),
    getModels: (year, brand, office) => dispatch(getModels(year, brand, office)),
    getOffices: () => dispatch(getOffices()),
    getAppointmentsAfter: () => dispatch(GetAppointments()),
    getCalendar: (month, year, connection) => dispatch(getCalendar(month, year, connection)),
    getHolidays: () => dispatch(fetchHolidays())
  }
}

function mapStateToProps ({
  Cars,
  Configuration: {
    offices,
    officesArray,
    holidays
  },
  Connections: {
    connections,
    selected
  }
}) {
  const officesConnections = !selected
    ? connections.map(conn => conn.name)
    : [selected.name]
  const actualOfffices = officesArray.map(office => office.location)
  return {
    brands: Cars.brands,
    models: Cars.models,
    isLoadingVIN: Cars.loadingVin,
    carByVin: Cars.carByVin,
    offices: offices,
    officesForSelect: [].concat(actualOfffices, officesConnections).sort(),
    connections: connections,
    isLoadingBrands: Cars.loadingBrands,
    isloadingYearsByModels: Cars.loadingYearsByModels,
    isloadingModelsByBrands: Cars.loadingModelsByBrands,
    holidays
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Apointment)
