export default {
  chryslerLogo: require('./chrysler.jpg'),
  dodgeLogo: require('./logo_dodge.png'),
  fiatLogo: require('./logo_fiat.jpg'),
  jeepLogo: require('./logo_jeep.png'),
  ramLogo: require('./logo_ram.jpg'),
  home: require('./home.jpg'),
  app1: require('./app1.jpg'),
  app2: require('./app2.jpg'),
  app3: require('./app3.jpg'),
  appStoreLg: require('./app_store_lg.png'),
  googlePlayLg: require('./google_play_lg.png'),
  appStoreSm: require('./app_store_sm.png'),
  googlePlaySm: require('./google_play_sm.png')
}
