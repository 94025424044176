import styled from 'styled-components'

export const AdminRoutesContainer = styled.main`
  display: flex;
  height: 100vh;
  @media (max-width: 500px) {
    min-height: 100vh;
  }
`

export const AdminBoxContainer = styled.div`
  width: ${({ hasSession }) => hasSession ? `calc(100% - 12rem)` : '100%'};
  display: flex;
  flex-direction: column;
  overflow: scroll;
  @media (max-width: 500px) {
    width: 100%;
    min-height: 100%;
  }
`
