import styled from 'styled-components'
import {
  primary
} from '../../colorPallette.json'

export const LoaderDiv = styled.div`
  border: .5rem solid #FFF;
  border-radius: 50%;
  border-top: .5rem solid ${primary};
  width: 4rem;
  height: 4rem;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`
