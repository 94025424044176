import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { primary, grayLabel, defaultGray, black, white } from '../../colorPallette'
import home from '../../assets/img/home.jpg'

export const Title = styled.h2`
  color: ${primary}
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;

  @media (min-width: 1500px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (max-width: 400px) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const Container = styled.div`
  padding-top: 3.4rem;
  scroll-behavior: smooth;
`

export const HomeTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 30rem;
  align-items: center;
  justify-content: space-around;
  background-image: url(${home});
  background-size: cover;
  background-position: center;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }
`

export const ScheduleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35vw;
  padding-left: 4rem;

  & h2 {
    text-align: center;
  }

  & button {
    margin-top: 20px;
  }

  @media (min-width: 1500px) {
    width: 40vw;

    & button {
      margin-top: 2rem;
    }
  }

  @media (max-width: 900px) {
    width: 50vw;
    padding-right: 3rem;
  }

  @media (max-width: 700px) {
    width: 65vw;
    align-self: center;
    padding: 0;
  }

  @media (max-width: 600px) {
    width: 85vw;
  }

  @media (max-width: 450px) {
    width: 100%;
    & h2 {
      width: 90%;
      font-size: 18px;
      line-height: 23px;
    }

    & span {
      margin-top: 5px;
    }

    & button {
      margin-top: 8px;
    }
  }

  @media (max-width: 360px) {
    & h2 {
      font-size: 16px;
      line-height: 21px;
    }

    & span {
      font-size: 14px;
      line-height: 18px;
    }
  }
`

export const TopImage = styled.img`
  width: 100%;
  ${({zIndex}) => zIndex && 'z-index: -1'};
`

export const Image =  styled.img`
  width: 40%;
  margin-top: 5rem;
  margin-left: 5rem;
  @media (max-width: 400px) {
    margin-left: 0;
  }
`

export const SlimText = styled.span`
  font-weight: 200;
  color: ${white}
  text-align: center;
  width: 80%;
  margin-top: 15px;

  @media (max-width: 700px) {
    font-size: 14px;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }
`

export const ScheduleText = styled(SlimText)`
  color: ${black};
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;

  @media (min-width: 1500px) {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
`

export const FlexibleBox = styled.div`
  display: flex;
  ${({flex}) => flex && `flex: ${flex}`};
  justify-content: center;
  align-items: center;
`

export const MiddleTopMessage = styled(FlexibleBox)`
  padding: 0 7vw;
  flex-direction: column;

  @media (min-width: 1500px) {
    padding: 0 4vw;
  }

  @media (max-width: 768px) {
    padding: 0 15vw;
  }
`

export const MiddleTopContainer = styled(FlexibleBox)`
  flex-direction: row-reverse;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin-top: 20px;

    & span, h2 {
      text-align: center;
    }

    & h2 {
      margin-top: 30px;
    }

    & img {
      width: 65%;
    }
  }
`

export const MiddleBottomContainer = styled(FlexibleBox)`
  flex-direction: row-reverse;
  align-items: flex-start;

  & > div:last-child {
    flex-direction: column;
  }
  
  @media (min-width: 1500px) {
    & img {
      width: 50vw;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    & > div:last-child {
      flex-direction: row;
    }
  }
`

export const MiddleIconsContainer = styled(FlexibleBox)`
  flex-direction: column;
  flex: 1;

  & > div:nth-child(1) span, & > div:nth-child(2) span {
    width: 60%;
  }

  @media (min-width: 1500px) {
    & > div {
      margin-top: 7rem;
    }

    & > div:first-child {
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    margin-top: 40px;
    flex-wrap: wrap;
    flex-direction: row;

    & > div {
      flex: 1;
      margin: 0 0 30px 0;
      align-self: flex-start;
      flex-basis: 50%;
    }

    & > div:last-child {
      flex-basis: 100%;
      margin: 35px 0 0 0;
    }
  }

  @media (max-width: 400px) {
    padding: 0 5vw;

    & span {
      width: 80%;
      text-align: center;
    }
  }
`

export const BottomContainer = styled(FlexibleBox)`
  padding: 10vh 0;
  flex-direction: column;

  & > div:first-child {
    margin-bottom: 35px;
  }

  & > div:nth-child(2) {
    margin-bottom: 30px;
  }

  @media (min-width: 1500px) {
    h2 {
      font-size: 2.7rem;
    }

    & > div:first-child {
      margin-bottom: 3rem;
    }

    & > div:nth-child(2) {
      margin-bottom: 4rem;
    }
  }

  @media (max-width: 768px) {
    padding: 10vh 0;
    h2, span {
      text-align: center;
    }
  }

  @media (max-width: 500px) {
    & > div:first-child {
      padding: 0 4vh;
    }
  }
`

export const MiddleTopLeftContainer = styled(FlexibleBox)`
  flex-direction: column;
  flex: 1;

  & div:first-child span {
    margin-top: 35px;
  }

  & div:last-child {
    margin-top: 75px;
  }

  & div:last-child span {
    width: 60%;
  }

  @media (min-width: 1500px) {
    & div:last-child {
      margin-top: 4rem;
    }
  }

  @media (max-width: 1100px) and (min-width: 769px) {
    & h2 {
      font-size: 2.5vw;
      line-height: 3.5vw;
    }

    & div:first-child span {
      font-size: 15px;
      margin-top: 25px;
    }

    & > div:last-child {
      margin-top: 4vw;
    }
  }

  @media (max-width: 768px) {
    & div:last-child {
      margin: 35px 0;
    }
  }
`

export const MiddleLeftBottom = styled(FlexibleBox)`
  flex-direction: column;
  margin-top: 75px;

  & > h2 {
    margin-bottom: 20px;
  }

  & img {
    width: 150px;
  }

  & a:first-child > img {
    margin-right: 50px;
    width: 200px;
  }

  @media (min-width: 1500px) {
    & > h2 {
      margin-bottom: 2vw;
    }

    & img {
      width: 15vw;
    }

    & a:first-child img {
      width: 19vw;
    }
  }

  @media (max-width: 1100px) and (min-width: 768px) {
    margin-top: 50px;
    & > h2 {
      font-size: 2.5vw;
      line-height: 3.5vw;
    }

    & a > img {
      width: 15vw;
    }

    & a:first-child > img {
      margin-right: 2vw;
      width: 20vw;
    }
  }

  @media (max-width: 500px) {
    & > h2 {
      font-size: 18px;
    }

    & img {
      width: 115px;
    }

    & a:first-child > img {
      margin-right: 5vw;
      width: 155px;
    }
  }
`

export const Text = styled.span`
  color: ${grayLabel};
  font-size: 16px;
  line-height: 20px;

  @media (min-width: 1500px) {
    font-size: 1.7rem;
    line-height: 2rem;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 500px) {
    font-size: 12px;
  }
`

export const IconBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({middleBottom}) => middleBottom && 'margin-top: 75px;'};

  & span {
    margin-top: 20px;
    text-align: center;
  }

  @media (min-width: 1500px) {
    margin-top: 1.5rem;
  }

  @media (min-width: 1100px) {
    ${({middleBottom}) => middleBottom && 'margin-top: 5vw'};
  }
  
  @media (max-width: 1100px) and (min-width: 769px) {
    ${({middleBottom}) => middleBottom && 'margin-top: 50px'};
  }
`

export const Icon = styled(FontAwesomeIcon).attrs({
  color: defaultGray
})`
  width: 100px !important;
  height: 100px;

  @media (min-width: 1500px) {
    width: 7vw !important;
    height: 7vw !important;
  }

  @media (max-width: 1100px) and (min-width: 769px) {
    width: 70px !important;
    height: 70px;
  }

  @media (max-width: 768px) {
    width: 75px !important;
    height: 75px;
  }

  @media (max-width: 500px) {
    width: 65px !important;
    height: 65px;
  }

  @media (max-width: 400px) {
    width: 60px !important;
    height: 60px;
  }
`

export const BrandsContainer = styled(FlexibleBox)`
  & img {
    margin-right: 45px;
    width: 12%;
  }

  & > img:last-child {
    margin: 0;
  }

  & > img:nth-child(4) , & > img:nth-child(5) {
    width: 8%;
  }

  @media (min-width: 1500px) {
    & img {
      margin-right: 3rem;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    padding: 0 10vw;
    justify-content: space-around;

    & > img {
      flex-basis: 25%;
      margin: 0 0 15px 25px;
    }

    & > img:first-child {
      margin: 0 0 15px 0;
    }
  }
`


export const ArrowDown = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'arrow-circle-down'],
  color: defaultGray,
  size: '3x'
})`
  width: 100%;
  height: 100%;
`

export const Button = styled.button`
  display: ${({ scrolled }) => scrolled ? 'none' : 'initial'};
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  z-index: 100;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: none;
  left: 50%;
  opacity: .3;
  position: absolute;
  transition: .3s;
  background: transparent;
  animation: arrowMove 1s ease-in infinite;
  bottom: 1rem;
  &:hover {
    opacity: 1;
  }
  @keyframes arrowMove {
    0% {
      bottom: -1.5rem;
    }
    50%{
      bottom: -2.3rem;
    }
    100% {
      bottom: -1.5rem;
    }
  }
`

export const ArrowDownContainer = styled.div`
  width: 100%;
  height: 1rem;
  position: relative;
`