const inititalState = {
  activityIndicator: false
}

const UI = (state = inititalState, { type, payload }) => {
  switch (type) {
    case 'SHOW_INDICATOR':
      return {
        ...state,
        activityIndicator: true
      }
    case 'HIDE_INDICATOR':
      return {
        ...state,
        activityIndicator: false
      }
    default:
      return state
  }
}

export default UI
