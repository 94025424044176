// import _ from 'lodash'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import { onFormatDate } from './utils'
import { getUserFromSession } from '../../utils/session'
import { formatURL } from '../adminAppointments/utils'

const URL = process.env.REACT_APP_BASE_URL

function sendHolidays (file, key) {
  return async (dispatch, getState) => {
    if (file) {
      const {
        Connections: {
          selected
        }
      } = getState()
      const { connection: conn } = selected
      var formData = new FormData()
      formData.append('file', file)
      const ENDPOINT = formatURL(`${URL}/holidays/`, '', '', '', conn)
      const fileUpload = await fetch(ENDPOINT, {
        method: 'POST',
        headers: {
          Authorization: `Token ${key}`
        },
        body: formData
      })
      const fileResponse = await fileUpload.json()
      if (fileResponse && fileResponse.data) {
        dispatch(
          getHolidays(
            onFormatDate(fileResponse.data)
          )
        )
      }
    }
  }
}

export const handleSubmit = (values, file) => {
  return async (dispatch, getState) => {
    const { offices, ...form } = values
    const { key } = getUserFromSession()
    const {
      Connections: {
        selected
      }
    } = getState()
    const { connection: conn } = selected
    const ENDPOINT_SETTINGS = formatURL(`${URL}/settings/`, '', '', '', conn)
    const ENDPOINT_OFFICES = formatURL(`${URL}/offices/`, '', '', '', conn)
    delete form['officesArray']
    const settings = fetch(ENDPOINT_SETTINGS, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${key}`
      },
      body: JSON.stringify({
        ...form,
        time_for_last_notification: form.time_for_last_notification.slice(0, 2),

      })
    })
    const officesToUpdate = {
      offices: {}
    }
    officesToUpdate['offices'] = offices
    const offi = fetch(ENDPOINT_OFFICES, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${key}`
      },
      body: JSON.stringify(officesToUpdate)
    })
    dispatch(sendHolidays(file, key))
    try {
      const [settingResponse, officeResponse] = await Promise.all([settings, offi])
      const settingR = await settingResponse.json()
      const officeR = await officeResponse.json()
      if (settingR && settingR.id) dispatch(getSettingsSuccess(settingR))
      if (officeR && Array.isArray(officeR)) dispatch(getOfficesSuccess(officeR))
      return [settingR, officeR]
    } catch (error) {
      window.alert(JSON.stringify(error))
      return [null, error]
    }
  }
}

export const getHolidays = (payload) => {
  return {
    type: 'GET_HOLIDAYS',
    payload
  }
}

export const getSettings = () => {
  return (dispatch, getState) => {
    const { key } = getUserFromSession()
    const {
      Connections: {
        selected
      }
    } = getState()
    const { connection: conn } = selected
    const ENDPOINT = formatURL(`${URL}/settings/`, '', '', '', conn)
    fetch(ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${key}`
      }
    })
      .then(response => response.json())
      .then(res => {
        if (res && !res.error) {
          dispatch(getSettingsSuccess(res))
        } else {
          // window.alert(JSON.stringify(res))
        }
      })
      .catch(e => window.alert(JSON.stringify(e)))
  }
}

function getSettingsSuccess (payload) {
  return {
    type: 'GET_SETTINGS_SUCCESS',
    payload
  }
}

export const getOffices = () => {
  return (dispatch, getState) => {
    const { key } = getUserFromSession()
    const {
      Connections: {
        selected
      }
    } = getState()
    const { connection: conn } = selected
    const ENDPOINT = formatURL(`${URL}/offices/`, '', '', '', conn)
    fetch(ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${key}`
      }
    })
      .then(response => response.json())
      .then(res => {
        if (res && !res.error) {
          dispatch(getOfficesSuccess(res))
        } else {
          // window.alert(JSON.stringify(res))
        }
      })
      .catch(e => window.alert(JSON.stringify(e)))
  }
}

export function getOfficesSuccess (payload) {
  return {
    type: 'GET_OFFICES_SUCCESS',
    payload
  }
}

export function deleteOffice (id) {
  return async (dispatch, getState) => {
    const { key } = getUserFromSession()
    const {
      Connections: {
        selected
      }
    } = getState()
    const { connection: conn } = selected
    const ENDPOINT = formatURL(`${URL}/offices/`, '', '', '', conn)
    const response = await fetch(ENDPOINT, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${key}`
      },
      body: JSON.stringify({
        id
      })
    })
    const json = await response.json()
    if (json && json.hasOwnProperty('ok')) {
      dispatch(getOffices())
      swal('Completado', 'se ha eliminado exitosamente la sucursal.', 'success', {
        timer: 2000,
        button: false,
        closeOnClickOutside: false
      })
    } else {
      swal('Oops!', 'Algo salio mal, intentalo de nuevo o más tarde.', 'error', {
        timer: 2000,
        button: false,
        closeOnClickOutside: false
      })
    }
  }
}

export function fetchHolidays () {
  return async (dispatch, getState) => {
    try {
      const {
        Connections: {
          selected
        }
      } = getState()
      if (!_.isNull(selected)) {
        const { connection: conn } = selected
        const ENDPOINT = formatURL(`${URL}/holidays/`, '', '', '', conn)
        const { key } = getUserFromSession()
        const response = await fetch(ENDPOINT, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${key}`
          }
        })
        const json = await response.json()
        if (json && json.data) dispatch(getHolidays(onFormatDate(json.data)))
      } else {
        const ENDPOINT = formatURL(`${URL}/holidays/`, '', '', '', 'automotriz_tab')
        const { key } = getUserFromSession()
        const response = await fetch(ENDPOINT, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${key}`
          }
        })
        const json = await response.json()
        if (json && json.data) dispatch(getHolidays(onFormatDate(json.data)))
      }
    } catch (error) {
      window.alert(JSON.stringify(error))
    }
  }
}
