import React, { Component } from 'react'
import { Formik } from 'formik'
import {
  GeneralInput,
  GeneralButton as Button,
  SearchInput
} from '../'
import {
  Flex,
  Worker,
  FormContainer,
  InputWrapper,
  Label,
  ListContainer
} from './styled'
import {
  MessageError
} from '../../containers/makeApointment/styled'
import schema from './schema'

export default class Panel extends Component {
  constructor (props) {
    super(props)
    this.INITIAL_VALUES = {
      external_id: '',
      name: '',
      email: '',
      phone: ''
    }
    this.form = props.form
    this.state = {
      selected: 0
    }
    this.onSelect = this.onSelect.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleChangePhone = this.handleChangePhone.bind(this)
  }
  onSelect (index, worker) {
    const {
      selected
    } = this.state
    if (selected === index) {
      this.setState({
        selected: 0
      }, () => {
        if (this.formikRef) {
          const {
            setValues,
            setFieldTouched
          } = this.formikRef
          setValues({
            external_id: '',
            name: '',
            email: '',
            phone: ''
          })
          setFieldTouched('email', false)
          setFieldTouched('phone', false)
          setFieldTouched('name', false)
          setFieldTouched('external_id', false)
        }
      })
    } else {
      this.setState({
        selected: index
      }, () => {
        if (this.formikRef) {
          const {
            setFieldTouched,
            setValues
          } = this.formikRef
          if (!worker.email && !worker.phone) {
            setValues({
              ...worker,
              email: '',
              phone: ''
            })
          } else {
            setValues(worker)
          }
          setFieldTouched('email', false)
          setFieldTouched('phone', false)
        }
      })
    }
  }
  onSubmit (form, actions) {
    const { handleSubmit } = this.props
    if (typeof handleSubmit === 'function') {
      handleSubmit(form, actions, this.form)
    }
  }
  disableButton (isValid, isSubmitting, haveAccount) {
    return !isValid || isSubmitting || haveAccount
  }
  haveAccount (values) {
    return typeof values.user === 'number'
  }
  handleChangePhone (e) {
    const {
      handleChange
    } = this.formikRef
    const {
      target: {
        value
      }
    } = e
    if (value.length <= 10) {
      handleChange(e)
    }
  }
  render () {
    const {
      onSearch,
      searchPlaceholder,
      workers
    } = this.props
    const {
      selected
    } = this.state
    return (
      <>
        <Flex>
          <Flex direction='column'>
            <SearchInput
              onChange={onSearch}
              placeholder={searchPlaceholder}
              style={{ width: '60%' }}
            />
            <ListContainer id='list_container'>
              {
                workers.map((worker, index) => {
                  return (
                    <Worker
                      index={worker.id}
                      key={worker.external_id}
                      info={worker}
                      selected={selected}
                      onSelect={this.onSelect}
                    />
                  )
                })
              }
            </ListContainer>
          </Flex>
          <Flex style={{ paddingLeft: 10 }}>
            <Formik
              initialValues={this.INITIAL_VALUES}
              ref={ref => { this.formikRef = ref }}
              onSubmit={this.onSubmit}
              validationSchema={schema}
            >
              {
                ({
                  values,
                  handleChange,
                  isValid,
                  errors,
                  setFieldTouched,
                  touched,
                  isSubmitting,
                  handleSubmit
                }) => {
                  const disabled = this.disableButton(
                    isValid,
                    isSubmitting,
                    this.haveAccount(values)
                  )
                  return (
                    <FormContainer>
                      <h4>REGISTRO DE ASESORES</h4>
                      <InputWrapper>
                        <Label>Automotriz Tabasco ID</Label>
                        <GeneralInput
                          value={values.external_id}
                          name='external_id'
                          onChange={handleChange}
                          disabled
                          onBlur={() => setFieldTouched('external_id')}
                        />
                        <MessageError>
                          {
                            (touched.external_id && errors.external_id) &&
                            <span>{errors.external_id}</span>
                          }
                        </MessageError>
                      </InputWrapper>
                      <InputWrapper>
                        <Label>Nombre del Asesor</Label>
                        <GeneralInput
                          value={values.name}
                          name='name'
                          onChange={handleChange}
                          disabled
                          onBlur={() => setFieldTouched('name')}
                        />
                        <MessageError>
                          {
                            (touched.name && errors.name) &&
                            <span>{errors.name}</span>
                          }
                        </MessageError>
                      </InputWrapper>
                      <InputWrapper>
                        <Label>Email del Asesor</Label>
                        <GeneralInput
                          value={values.email}
                          name='email'
                          onChange={handleChange}
                          disabled={!selected}
                          onBlur={() => setFieldTouched('email')}
                        />
                        <MessageError>
                          {
                            (touched.email && errors.email) &&
                            <span>{errors.email}</span>
                          }
                        </MessageError>
                      </InputWrapper>
                      <InputWrapper>
                        <Label>Teléfono del Asesor</Label>
                        <GeneralInput
                          value={values.phone}
                          name='phone'
                          onChange={this.handleChangePhone}
                          disabled={!selected}
                          type='number'
                          onBlur={() => setFieldTouched('phone')}
                        />
                        <MessageError>
                          {
                            (touched.phone && errors.phone) &&
                            <span>{errors.phone}</span>
                          }
                        </MessageError>
                      </InputWrapper>
                      <InputWrapper>
                        <Button
                          padding='0px'
                          margin='20px 0px'
                          height='2rem'
                          type='submit'
                          width='100%'
                          onClick={handleSubmit}
                          noHover
                          disabled={disabled}
                        >
                          {this.form === 'Consultants' ? 'Crear cuenta de asesor' : 'Crear cuenta de mecánicos'}
                        </Button>
                      </InputWrapper>
                    </FormContainer>
                  )
                }
              }
            </Formik>
          </Flex>
        </Flex>
      </>
    )
  }
}
