const initialSate = {
    loadingBrands: false,
    loadingModelsByBrands: false,
    loadingYearsByModels: false,
    loadingVin: false,
    brands: [],
    models: [],
    carByVin: null
}

export default (state = initialSate, { type, payload }) => {
    switch(type) {
        case 'FETCH_CAR_BY_VIN_FAILED':
            return {
                ...state,
                loadingVin: false
            }
        case 'FETCH_CAR_BY_VIN_REQUEST':
            return {
                ...state,
                loadingVin: true
            }
        case 'FETCH_BRANDS':
            return {
                ...state,
                loadingBrands: true
            }
        case 'FETCH_MODELS_BY_BRAND':
            return {
                ...state,
                loadingModelsByBrands: true
            }
        case 'FETCH_YEARS_BY_MODEL':
            return {
                ...state,
                loadingYearsByModels: true
            }
        case 'FETCH_BRANDS_SUCCESS':
            return {
                ...state,
                brands: payload,
                loadingBrands: false
            }
        case 'FETCH_MODEL_BY_BRANDS_SUCCESS':
            return {
                ...state,
                models: payload,
                loadingModelsByBrands: false
            }
        case 'FETCH_CAR_BY_VIN_SUCCESS':
            return {
                ...state,
                carByVin: payload,
                loadingVin: false
            }
        default: 
            return state
    }
}