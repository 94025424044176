import React, { Component } from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import _ from 'lodash'
import { connect } from 'react-redux'
import styled from 'styled-components'
import DatePicker, { registerLocale } from 'react-datepicker'
import * as es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
import { getUserFromSession } from '../../utils/session'
import {
  GeneralInput,
  GeneralButton as Button
} from '../../components'
import {
  MessageError
} from '../makeApointment/styled'
// import { primary } from '../../colorPallette'
import { STORE } from '../../App'
import { getOfficesSuccess } from './actions'
import {
  getTomorrow,
  calculateSundays,
  getTime
} from '../makeApointment/utils'
import { formatURL } from '../adminAppointments/utils';

const URL = process.env.REACT_APP_BASE_URL

const sundays = calculateSundays()

registerLocale('es', es)

const Input = styled.input`
  width: 100% !important;
  height: 2rem;
  border: 1px solid #737373;
  outline: none;
  padding: 0 1rem;
  color: #737373;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
`

const schema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  date: Yup.date().required('Campo requerido'),
  hour: Yup.date().required('Campo requerido')
})

class CustomInput extends Component {
  render () {
    const text = this.props.value !== '' ? this.props.value : this.props.placeholder
    return (
      <Input
        type='button'
        value={text}
        onClick={this.props.onClick}
        onBlur={this.props.onBlur}
      />
    )
  }
}

export function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function handleDatePicker (field, value, onSuccess) {
  onSuccess(field, value)
}

class NewOfficeForm extends Component {
  constructor (props) {
    super(props)
    this.addOffice = this.addOffice.bind(this)
  }
  addOffice () {
    const { connection } = this.props
    const { key } = getUserFromSession()
    const { values } = this.formikRef.state
    const form = {
      offices: {
        [values.name]: {
          date_of_appointments: moment(values.date).format('YYYY-MM-DD'),
          hour_of_appointments: moment(values.hour).format('HH:mm')
        }
      }
    }
    const conn = connection ? connection.connection : 'automotriz_tab'
    const ENDPOINT = formatURL(`${URL}/offices/`, '', '', '', conn)
    fetch(ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${key}`
      },
      body: JSON.stringify(form)
    })
      .then(results => {
        return results.json()
      })
      .then(offices => {
        if (offices && Array.isArray(offices)) {
          STORE.dispatch(getOfficesSuccess(offices))
          this.props.onCancel()
        }
        if (offices && _.get('error', false)) {
          window.alert('Ocurrio un error al tratar de registrar una sucursal')
        }
      })
  }
  render () {
    return (
      <Formik
        initialState={{
          name: '',
          date: '',
          hour: ''
        }}
        ref={ref => { this.formikRef = ref }}
        validationSchema={schema}
      >
        {({
          handleChange,
          values,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          isValid,
          errors,
          setFieldTouched,
          touched,
          ...formik
        }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, width: '40%' }}>
              <div style={{ flex: 1 }}>
                <h5 style={{ textAlign: 'left', margin: '0px 0px 10px 0px', fontSize: '0.8rem' }}>Nombre de la sucursal</h5>
                <GeneralInput
                  placeholder='Sucursal'
                  height='1.9rem'
                  onChange={handleChange}
                  name='name'
                  id='name'
                  autoComplete='off'
                  autoCapitalize='on'
                  onBlur={() => setFieldTouched('name')}
                  value={values.name && capitalizeFirstLetter(values.name)}
                />
                <MessageError>
                  {errors.name && touched.name && <span>{errors.name}</span>}
                </MessageError>
              </div>
              <div style={{ flex: 1, width: '100%' }}>
                <h5 style={{ textAlign: 'left', margin: '20px 0px 10px 0px', fontSize: '0.8rem' }}>Fecha de citas</h5>
                <DatePicker
                  selected={values.date}
                  locale='es'
                  placeholderText='Seleccione una fecha para su cita'
                  customInput={<CustomInput />}
                  onChange={date => {
                    handleDatePicker('date', date, setFieldValue)
                  }}
                  dateFormat='dd/MM/YYYY'
                  excludeDates={sundays}
                  minDate={getTomorrow()}
                  onBlur={() => setFieldTouched('date')}
                />
                <MessageError>
                  {errors.date && touched.date && <span>{errors.date}</span>}
                </MessageError>
              </div>
              <div style={{ flex: 1 }}>
                <h5 style={{ textAlign: 'left', margin: '20px 0px 10px 0px', fontSize: '0.8rem' }}>Hora de citas</h5>
                <DatePicker
                  selected={values.hour}
                  onChange={hour => {
                    handleDatePicker('hour', hour, setFieldValue)
                  }}
                  placeholderText='Seleccione una hora para su cita'
                  customInput={<CustomInput />}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat='h:mm aa'
                  timeCaption='Hora'
                  minTime={getTime(true)}
                  maxTime={getTime()}
                  onBlur={() => setFieldTouched('hour')}
                />
                <MessageError>
                  {errors.hour && touched.hour && <span>{errors.hour}</span>}
                </MessageError>
              </div>
              <div style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <Button
                  padding='0px'
                  margin='20px 0px'
                  height='2rem'
                  type='submit'
                  disabled={!isValid || isSubmitting}
                  onClick={this.addOffice}
                  noHover
                >
                  Agregar oficina
                </Button>
                <Button
                  padding='0px'
                  margin='20px 0px'
                  height='2rem'
                  background='red'
                  type='submit'
                  onClick={this.props.onCancel}
                  noHover
                >
                  Cancelar
                </Button>
              </div>
            </div>
          )
        }}
      </Formik>
    )
  }
}

export function onFormatDate (holidays) {
  if (holidays) {
    return holidays.map((holiday) => {
      const [year, month, day] = holiday.date.split('-')
      return new Date(moment(moment().date(day).month(month - 1).year(year)))
    })
  }
  return []
}

function mapStateToProps ({ Connections }) {
  return {
    connection: Connections.selected
  }
}

export default connect(mapStateToProps, null)(NewOfficeForm)
