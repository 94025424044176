import styled from 'styled-components'
// import {
//   defaultGray,
//   darkBlue,
//   lightGray,
//   primary,
//   yellow
// } from '../../colorPallette.json'

export const MainContainer = styled.div`
  background-color: #F5F5F5;
  padding: 1.5rem 0rem;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
`

export const WhiteContainer = styled.div`
  display: flex;
  background-color: #FFF;
  padding: 15px;
  width: 95%;
  box-sizing: border-box;
  border: 1px solid #878E94;
  /* margin-bottom: 30px; */
  max-height: 600px;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
`

export const ContentTop = styled.div`
  display: flex;
  height: 5%;
  width: 95%;
  a {
    color: #232F88;
    text-decoration: none;
  }
`

export const ContentBottom = styled.div`
  display: flex;
  height: 95%;
  width: 95%;
  align-items: center;
  justify-content: center;
`

