import React, { Component } from 'react'
import {
  MainContainer,
  StyledLink,
  Icon,
  Image,
  ImageContainer,
  CloseButton,
  LocationText,
  LocationContainer,
  LocationButton,
  LocationsContainer,
  LocationName,
  Collapsable
} from './styled'
import logo from '../../assets/img/Logowhite.png'
import { connect } from 'react-redux'
import { CloseMenu } from '../../actions/'

class VerticalNavBar extends Component {
  constructor () {
    super()
    this.state = {
      display: false,
      left: '-14rem',
      isCollapsed: true
    }
    this.renderLocations = this.renderLocations.bind(this)
  }
  render () {
    const { isCollapsed } = this.state
    const {
      changeDisplay,
      left,
      closeMenu,
      location: { pathname },
      user: { is_superuser },
      locations
    } = this.props
    return (
      <MainContainer changeDisplay={changeDisplay} left={left}>
        <ImageContainer>
          <Image src={logo} />
        </ImageContainer>
        {
          locations && locations.selected && (
            <LocationContainer>
              <LocationText>
                <Icon
                  icon={['far', 'dot-circle']}
                  color='green'
                />
                Estás conectado a: {locations.selected.name}
              </LocationText>
            </LocationContainer>
          )
        }
        <StyledLink
          actualpath={pathname}
          to='/admin/agendar-cita'
        >
          <Icon
            icon={['far', 'calendar-plus']}
          />
          AGENDAR CITA
        </StyledLink>
        <StyledLink
          actualpath={pathname}
          to='/admin/calendario'
        >
          <Icon
            icon={['far', 'calendar-alt']}
          />
          CALENDARIO
        </StyledLink>
        <StyledLink
          actualpath={pathname}
          to='/admin/citas'
        >
          <Icon
            icon={['far', 'calendar-check']}
          />
          CITAS
        </StyledLink>
        {
          is_superuser && (
            <StyledLink
              actualpath={pathname}
              to='/admin/configuracion'
            >
              <Icon
                icon={['fas', 'cog']}
              />
              CONFIGURACIÓN
            </StyledLink>
          )
        }
        {
          is_superuser && (
            <StyledLink
              actualpath={pathname}
              to='/admin/asesores-tecnicos'
            >
              <Icon
                icon={['fas', 'user']}
              />
              ASESORES/MECÁNICOS
            </StyledLink>
          )
        }
        <StyledLink
          actualpath={pathname}
          to='/admin/perfil'
        >
          <Icon
            icon={['fas', 'user']}
          />
          PERFIL
        </StyledLink>
        <Collapsable>
          <LocationButton onClick={() => this.handleCollapse()}>
            <Icon icon={['fas', 'map-marker-alt']} />
            <span>SUCURSALES</span>
            <Icon
              icon={['fas', isCollapsed ? 'chevron-right' : 'chevron-down']}
              style={{
                marginLeft: '.6rem'
              }}
            />
          </LocationButton>
          {this.renderLocations()}
        </Collapsable>
        <CloseButton onClick={closeMenu}>
          <Icon
            icon={['far', 'times-circle']} 
          />
          CERRAR MENÚ
        </CloseButton>
      </MainContainer>
    )
  }

  handleCollapse () {
    const { isCollapsed } = this.state
    this.setState({ isCollapsed: !isCollapsed })
  }

  renderLocations () {
    const { isCollapsed } = this.state
    const {
      connections,
      selected
    } = this.props.locations
    return (
      <LocationsContainer isCollapsed={isCollapsed}>
        {connections.map((location, index) => {
          return (
            <LocationName
              key={index}
              onClick={() => this.selectLocation(location)}
              selected={(selected) && selected.name === location.name}
            >
              {selected && selected.name === location.name &&
                <Icon
                  icon={['far', 'dot-circle']}
                  color='green'
                />
              }
              {location.name}
            </LocationName>
          )
        })}
      </LocationsContainer>
    )
  }

  selectLocation (locationData) {
    const {
      dispatch,
      history
    } = this.props
    dispatch({
      type: 'SHOW_INDICATOR'
    })
    setTimeout(() => {
      dispatch({
        type: 'SELECT_CONNECTION',
        payload: locationData
      })
      this.setState({ isCollapsed: true })
      dispatch({
        type: 'HIDE_INDICATOR'
      })
    }, 2000)
    setTimeout(() => {
      if (history.location.pathname === '/admin/calendario') {
        history.push('/admin/citas')
      }
      window.location.reload()
    }, 2100)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    closeMenu: () => dispatch(CloseMenu()),
    dispatch
  }
}

function mapStateToProps ({ Menu, User, Connections }) {
  return {
    changeDisplay: Menu.menu,
    left: Menu.left,
    user: User,
    locations: Connections
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerticalNavBar)
