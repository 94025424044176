import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PrivateRoute } from '../utils/privateRoute'
import {
  PageNotFound,
  NavBar,
  VerticalNavBar,
  AdminHeader
} from '../components'
import {
  Apointment,
  ApointmentConfirmartion,
  Home,
  AdminAppointments,
  ApointmentDetail,
  AppointmentsCalendar,
  AdminConfig,
  AdminProfile,
  AdminPeople,
  AdminLogin
} from '../containers'
import {
  AdminRoutesContainer,
  AdminBoxContainer
} from './styled'
import { getUserFromSession } from '../utils/session'

export const PublicRoutes = ({ location }) => {
  return (
    <div>
      <NavBar location={location} />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/agendar-cita' component={Apointment} />
        <Route path='/confirmacion-cita' component={ApointmentConfirmartion} />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  )
}

const PrivateHeader = () => <Route component={AdminHeader} />
const PrivateNavBar = () => <Route component={VerticalNavBar} />

export const AdminRoutes = () => {
  const session = getUserFromSession()
  return (
    <AdminRoutesContainer>
      {session && <PrivateNavBar />}
      <AdminBoxContainer hasSession={session}>
        {session && <PrivateHeader />}
        <Switch>
          <PrivateRoute path='/admin/agendar-cita' component={Apointment} />
          <PrivateRoute path='/admin/citas' component={AdminAppointments} />
          <PrivateRoute path='/admin/citas-detalle/' component={ApointmentDetail} />
          <PrivateRoute path='/admin/confirmacion-cita' component={ApointmentConfirmartion} />
          <PrivateRoute path='/admin/calendario' component={AppointmentsCalendar} />
          <PrivateRoute path='/admin/configuracion' component={AdminConfig} />
          <PrivateRoute path='/admin/asesores-tecnicos' component={AdminPeople} />
          <PrivateRoute path='/admin/perfil' component={AdminProfile} />
          <Route
            exact
            path='/admin'
            component={AdminLogin}
          />
          <Route path='*' component={() => <PageNotFound isPage session />} />
        </Switch>
      </AdminBoxContainer>
    </AdminRoutesContainer>
  )
}
