import styled from 'styled-components'
import {
  primary,
  white,
  grayLabel
} from '../../colorPallette.json'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`

export const Radio = styled.div`
  width: .9rem;
  height: .9rem;
  background: ${({ checked }) => checked ? primary : white};
  border-radius: .5rem;
  border-color: ${primary};
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  margin-right: 1rem;
  &&:hover {
    background: ${({ checked }) => !checked && 'rgba(0,0,0,.25)'};
  }
`

export const Mark = styled.div`
  height: .4rem;
  width: .4rem;
  border-radius: .2rem;
  background: ${({ checked }) => checked ? white : 'transparent'};
`

export const CheckContainer = styled.div`
  width: 10rem;
  display: flex;
  align-items: center;
  margin: .1rem 0;
`

export const Label = styled.div`
  font-size: 1rem;
  cursor: pointer;
  color: ${grayLabel};
`