import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import swal from 'sweetalert'
import { GeneralInput } from '../../components'
import {
  MainContainer,
  Heading,
  FormContainer,
  SaveButton,
  Label,
  Row,
  HeaderContainer,
  NewAdminButton
} from './styled'
import {
  updateUser,
  changePassword,
  adminRegister
} from './actions'

import { schema } from './schema'

class AdminProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      formData: {},
      addAdmin: false
    }
    this.onInputChange = this.onInputChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onClickAddAdmin = this.onClickAddAdmin.bind(this)
    this.createNewAdmin = this.createNewAdmin.bind(this)
  }
  render () {
    const {
      formData: {
        username,
        email,
        password1,
        password2
      },
      addAdmin
    } = this.state
    const {
      user: { is_superuser }
    } = this.props
    return (
      <MainContainer>
        <HeaderContainer>
          <Heading>Datos de perfil</Heading>
          {
            is_superuser && <NewAdminButton onClick={this.onClickAddAdmin}>{addAdmin ? 'Regresar' : 'Nuevo Administrador'}</NewAdminButton> 
          }
        </HeaderContainer>
        <FormContainer>
          <Formik>
            {formikProps => (
              <div>
                <Label htmlFor="username">Nombre de  usuario</Label>
                <GeneralInput
                  name='username'
                  value={username}
                  id='username'
                  onChange={this.onInputChange}
                  width='45%'
                  style={{fontSize: '18px'}}
                />
                <Label htmlFor="email">Correo electrónico</Label>
                <GeneralInput 
                  name='email'
                  value={email}
                  id='email'
                  type='email'
                  onChange={this.onInputChange}
                  width='45%'
                  style={{fontSize: '18px'}}
                  disabled={!addAdmin}
                />
                <Label htmlFor="">Contraseña</Label>
                <GeneralInput
                  name='password1'
                  value={password1}
                  type='password'
                  onChange={this.onInputChange}
                  width='45%'
                  style={{fontSize: '18px'}}
                />
                <Label htmlFor="">Confirmar contraseña</Label>
                <GeneralInput
                  name='password2'
                  value={password2}
                  type='password'
                  onChange={this.onInputChange}
                  width='45%'
                  style={{fontSize: '18px'}}
                />
              </div>
            )}
          </Formik>
          <Row>
            <SaveButton onClick={addAdmin ? this.createNewAdmin :this.onSubmit}>Guardar</SaveButton>
          </Row>
        </FormContainer>
      </MainContainer>
    )
  }
  componentWillMount () {
    let {
      user: {
        username,
        email
      }
    } = this.props
    this.setState({formData: {username, email}})
  }
  onInputChange ({currentTarget: {name, value}}) {
    this.setState(({formData}) => {
      formData[name] = value
      return {formData}
    })
  }
  onClickAddAdmin () {
    let {
      user: {
        username,
        email
      }
    } = this.props
    const { addAdmin } = this.state
    if (!addAdmin) {
      this.setState(({formData, addAdmin}) => {
        addAdmin = !addAdmin
        formData['username'] = ''
        formData['email'] = ''
        formData['password1'] = ''
        formData['password2'] = ''
        return {formData, addAdmin}
      })
    } else {
      this.setState(({formData, addAdmin}) => {
        addAdmin = !addAdmin
        formData['username'] = username
        formData['email'] = email
        formData['password1'] = ''
        formData['password2'] = ''
        return {formData, addAdmin}
      })
    }
  }
  createNewAdmin () {
    const { formData } = this.state
    const {user: {key}} =  this.props
    schema.validate(formData)
    .then(result => {
      var user = adminRegister(formData, key)
      if (user) {
        this.onClickAddAdmin()
      }
    })
    .catch(err => {
      console.log(err)
      err.hasOwnProperty('message') ? swal("", err.message, "error") : swal("", "Ocurrió un error, por favor vuelva a intentar", "error")
    })
  }
  onSubmit () {
    const {
      updateUser,
      user: { key }
    } = this.props
    const {
      formData: {
        username,
        email,
        password1,
        password2
      }
    } = this.state
    // put your code here
    updateUser(username, email)
    if ((password1 && password2) && (password1.length > 0 && password2.length > 0)) {
      if (password1 === password2) {
        swal({
          text: '¿Está seguro que desea cambiar su contraseña?',
          buttons: ['Cancelar', 'Aceptar'],
          icon: 'warning'
        })
        .then(value => {
          if (value) {
            changePassword(password1, password2, key)
            .then(() => {
              this.setState(({formData}) => {
                formData['password1'] = ''
                formData['password2'] = ''
                return {formData}
              })
            })
          }
        })
      } else {
        swal('Ocurrió un error', 'Los campos de Contraseña no coinciden', 'error')
      }
    }
  }
}

function mapStateToProps ({User}) {
  return {
    user: User
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateUser: (username, email) => dispatch(updateUser(username, email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile)
