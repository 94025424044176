import styled from 'styled-components'
import {
  white,
  primaryHover,
  primaryActive,
  primary
} from '../../colorPallette.json'

export const Button = styled.button`
  width: ${({width}) => width || '10rem'};
  height: ${({ height }) => height || '3rem'};
  padding: ${({padding}) => padding || '0 .5rem'};
  background: ${({background}) => background || primary};
  margin: ${({margin}) => margin || '0 .5rem'};
  color: ${({color}) => color || white};
  font-size: .9rem;
  font-weight: 100;
  transition: .3s;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px;
  opacity: ${({disabled}) => disabled ? .5 : 1};
  ${({ noHover }) => {
    if (!noHover) {
      return `
        &:hover {
          background: ${primaryHover};
          box-shadow: 0px 0px 5px 0px ${primaryActive};
        }
        &:active {
          background: ${primaryActive};
          box-shadow: none;
        }
      `
    }
  }}
  ${({ hasLoading }) => {
    if (hasLoading) {
      return `
        display: flex;
        align-items: center;
        justify-content: center;
      `
    }
  }};
`
