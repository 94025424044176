import styled from 'styled-components'
import { defaultGray, lightGray } from '../../colorPallette.json'
import { Field } from 'formik'

export const Container = styled.div`
  ${({ loading }) => !loading && `background: url('https://png.pngtree.com/svg/20161117/chevron_thin_down_29179.png')
    no-repeat center;`};
  background-position-x: 95%;
  background-size: 16px;
  height: ${({ height }) => height || '2rem'};
  width: ${({ width }) => width || '300px'};
  margin: ${({margin}) => margin};
  position: relative;
`

export const Select = styled(Field)`
  width: 100%;
  height: 100%;
  padding: 0 10px 0 25px;
  color: ${defaultGray};
  ${({ hasError }) => `border: 1px solid ${hasError ? '#C5130F' : defaultGray};`};
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  outline: none;
  font-size: ${({ size }) => size || '.7rem'};
  font-family: Helvetica, Arial, sans-serif;
  ${({ disabled }) => disabled && `background-color: ${lightGray};`};
  ${({ disabled }) => disabled && `opacity: 0.5;`};
`
