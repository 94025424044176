import styled from 'styled-components'
import { defaultGray, lightGray } from '../../colorPallette.json'
import { Field } from 'formik'

export const Input = styled(Field)`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '2rem'};
  margin: ${({ margin }) =>
    margin ? (margin.length > 0 ? margin : '.5rem') : 0};
  ${({ hasError }) => `border: 1px solid ${hasError ? '#C5130F' : defaultGray};`};
  outline: none;
  padding: 0 1rem;
  color: ${defaultGray};
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  ${({ disabled }) => disabled && `background-color: ${lightGray};`};
  ${({ disabled }) => disabled && `opacity: 0.8;`};
`

export const Container = styled.div`
  position: relative;
  font-size: 0.8rem;
`
