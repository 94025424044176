import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import {
  Container,
  FooterTop,
  FooterBottom,
  PrivacyNotice,
  TopLeft,
  TextContainer,
  Title,
  Text,
  IconsContainer,
  CircleIcon,
  TopRight,
  DownloadContainer,
  AppDownload,
  DownloadTitle,
  FlexRow,
  TopInnerLeft,
  TopInnerRight,
  BottomInnerLeft
} from './styled'
import { darkBlue } from '../../colorPallette'
import Assets from '../../assets/img'

const Footer = ({ Configuration }) => (
  <Container>
    <FooterTop>
      <TopLeft>
        <TopInnerLeft>
          <TextContainer paddingRight>
            <Title>Ubicación</Title>
            <Text>Av. Adolfo Ruiz Cortines #919, Col. Arboledas, Cp. 86079, Villahermosa</Text>
          </TextContainer>
          <TextContainer>
            <Title>Conmutador</Title>
            <Text>{Configuration.conmutator_phone || '9933580618'}</Text>
          </TextContainer>
        </TopInnerLeft>
        <BottomInnerLeft flex={1}>
          <IconsContainer paddingRight main>
            <CircleIcon href='https://www.facebook.com/ata.com.mx/?ref=br_rs' target='_blank'>
              <FontAwesomeIcon icon={['fab', 'facebook-f']} color={darkBlue} />
            </CircleIcon>
            <CircleIcon href='https://twitter.com/AutomoTab' target='_blank'>
              <FontAwesomeIcon icon={['fab', 'twitter']} color={darkBlue} />
            </CircleIcon>
            <CircleIcon href='https://www.instagram.com/automotriztabasco/' target='_blank'>
              <FontAwesomeIcon icon={['fab', 'instagram']} color={darkBlue} />
            </CircleIcon>
          </IconsContainer>
          <IconsContainer empty />
        </BottomInnerLeft>
      </TopLeft>
      <TopRight>
        <TopInnerRight>
          <TextContainer>
            <Title>Horario de Ventas</Title>
            <Text>Lunes a viernes 8:30 a.m. - 8:30 p.m.</Text>
            <Text>Sábados 8:30 a.m. - 8:30 p.m.</Text>
            <Text>Domingos 10:00 a.m. - 6:00 p.m.</Text>
          </TextContainer>
          <TextContainer>
            <Title>Horario de Servicio</Title>
            <Text>Lunes a viernes 9:00 a.m. - 5:30 p.m.</Text>
            <Text>Sábados 8:00 a.m. - 2:00 p.m.</Text>
          </TextContainer>
          <TextContainer>
            <Title>Horario de Refacciones</Title>
            <Text>Lunes a viernes 9:00 a.m. - 7:30 p.m.</Text>
            <Text>Sábados 8:30 a.m. - 2:00 p.m.</Text>
          </TextContainer>
        </TopInnerRight>
        <DownloadContainer>
          <DownloadTitle>Descarga la app:</DownloadTitle>
          <FlexRow>
            <AppDownload margin href='https://play.google.com/store/apps/details?id=com.kondosoft.automotrizTabasco' target='_blank'>
              <img alt='Google Play' src={Assets.googlePlayLg} />
            </AppDownload>
            <AppDownload href='https://apps.apple.com/mx/app/garage-ata/id1468124017' target='_blank'>
              <img alt='App Store' src={Assets.appStoreLg} />
            </AppDownload>
          </FlexRow>
        </DownloadContainer>
      </TopRight>
    </FooterTop>
    <FooterBottom>
      <PrivacyNotice
        href='https://auto-tabasco.com.mx/Privacidad/'
        target='_blank'
        rel='noopener noreferrer'
      >
        Aviso de Privacidad
      </PrivacyNotice>
    </FooterBottom>
  </Container>
)

export default connect(({ Configuration }) => ({ Configuration }), null)(Footer)
