import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  darkBlue,
  white,
  primary
} from '../../colorPallette.json'

export const MainContainer = styled.div`
  height: 3.5rem;
  width: 100%;
  position: fixed;
  background: ${darkBlue};
  padding-right: 3rem;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  @media (max-width: 1000px) {
    padding-right: .5rem;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  width: 9rem;
  justify-content: center;
  align-items: center;
`

export const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  @media (max-width: 400px) {
    width: 70%;
  }
`

export const Logo = styled.img`
  width: 60%;
  height: 80%;
  margin-left: 3rem;
`

export const List = styled.ul`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const ItemList = styled.li`
  width: 8rem;
  padding: 0 .5rem;
  height: 100%;
  cursor: pointer;
  transition: .3s;
  background: ${({ pathname, route }) => (pathname === route) && primary};
  &:hover {
    background: ${primary};
  }
`

export const LinkTo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: ${white};
  font-size: .7rem;
  font-family: sans-serif;
  font-weight: 100;
  text-decoration: none;
  transition: .3s;
`
