import {
  formatURL
} from '../adminAppointments/utils'

const URL = process.env.REACT_APP_BASE_URL

export const getOffices = () => {
  return async (dispatch, getState) => {
    try {
      const {
        Connections: {
          selected
        }
      } = getState()
      const conn = selected ? selected.connection : 'automotriz_tab'
      const ENDPOINT = formatURL(`${URL}/offices/`, '', '', '', conn)
      const response = await fetch(ENDPOINT)
      const offices = await response.json()
      if (offices && Array.isArray(offices)) {
        dispatch({
          type: 'GET_OFFICES_SUCCESS',
          payload: offices
        })
        return null
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const createAppointmentFromEmail = async ({
  name,
  lastname,
  motherLastName,
  phone,
  email,
  office,
  model,
  brand,
  car_id,
  servicetype,
  year
}) => {
  try {
    const response = await fetch(`${URL}/appointment-email/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        model: year,
        car_id,
        office,
        service_type: servicetype,
        car_brand: brand,
        car_model: model,
        name,
        last_name: lastname,
        mother_last_name: motherLastName,
        phone,
        email
      })
    })
    const appointment = await response.json()
    if (appointment.hasOwnProperty('error')) return [null, appointment]
    else return [appointment, null]
  } catch (error) {
    return [null, error]
  }
}

export const createAppointment = async (
  {
    token,
    appointmentDate,
    appointmentHour,
    person_id,
    year,
    car_id,
    office,
    servicetype,
    brand,
    model,
    observations
  },
  connection
) => {
  try {
    const connectionName = connection.shift().connection
    const [carModel] = model.split('-')
    var ENDPOINT = `${URL}/appointments/`
    ENDPOINT = formatURL(ENDPOINT, '', '', '', connectionName)
    const response = await fetch(ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify({
        date: appointmentDate,
        hour: appointmentHour,
        person: person_id,
        model: year,
        carId: car_id,
        office: office,
        service_type: servicetype,
        car_brand: brand,
        car_model: carModel,
        observations
      })
    })
    const json = await response.json()
    return json
  } catch (error) {
    return error
  }
}

function generatePw (name, lastname, motherLastName) {
  return name.slice(0, 2) + lastname.slice(0, 2) + motherLastName.slice(0, 2) + Math.floor(Math.random() * 90 + 10)
}

export const createUser = async ({ name, lastname, motherLastName, email, phone, person_id, from_web }) => {
  const pw = await generatePw(name, lastname, motherLastName)
  try {
    const response = await fetch(`${URL}/rest-auth/registration/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        password1: pw,
        password2: pw,
        name: name,
        last_name: lastname,
        mother_last_name: motherLastName,
        phone: phone,
        ata_id: person_id,
        from_web: from_web
      })
    })
    const userToken = await response.json()
    if (userToken.key) return [userToken, null]
    else return [null, userToken]
  } catch (error) {
    return error
  }
}

export const getClientOrCreate = async form => {
  const { name, lastname, motherLastName, email, phone } = form
  try {
    const response = await fetch(`${URL}/person/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        last_name: lastname,
        mother_last_name: motherLastName,
        name,
        phone,
        email: email.toLowerCase()
      })
    })
    const client = await response.json()
    if (client && client.hasOwnProperty('client_id')) {
      return [client, null]
    } else {
      return [null, client]
    }
  } catch (error) {
    return error
  }
}

export const getCarByVin = (vin, connection) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'FETCH_CAR_BY_VIN_REQUEST' })
    try {
      const ENDPOINT = formatURL(`${URL}/car-vin/`, '', '', '', connection)
      const response = await fetch(`${ENDPOINT}&vin=${vin}`)
      const json = await response.json()
      if (json.hasOwnProperty('car_id')) {
        dispatch(fetchCarByVinSuccess(json))
        return json
      } else {
        dispatch({ type: 'FETCH_CAR_BY_VIN_FAILED' })
        return json
      }
    } catch (error) {
      return error
    }
  }
}

export const getBrands = () => {
  return dispatch => {
    // dispatch({ type: 'FETCH_BRANDS' })
    fetch(`${URL}/car-brands/`)
      .then(response => response.json())
      .then(response => {
        if (Array.isArray(response)) {
          dispatch(fetchBrandsSuccess(response))
        } else {
          dispatch(fetchBrandsSuccess([]))
        }
      })
      .catch(e => console.log(e))
  }
}

export const getModelsByBrand = (year, connection) => {
  return dispatch => {
    dispatch({ type: 'FETCH_BRANDS' })
    const ENDPOINT = formatURL(`${URL}/car-years/`, '', '', '', connection)
    fetch(`${ENDPOINT}&year=${year}`)
      .then(response => response.json())
      .then(response => {
        if (Array.isArray(response)) {
          dispatch(fetchBrandsSuccess(response))
        } else {
          dispatch(fetchBrandsSuccess([]))
        }
      })
  }
}

export const getModels = (year, brand, connection) => {
  return dispatch => {
    dispatch({ type: 'FETCH_MODELS_BY_BRAND' })
    const ENDPOINT = formatURL(`${URL}/car-brands/`, '', '', '', connection)
    fetch(`${ENDPOINT}&year=${year}&brand=${brand}`)
      .then(response => response.json())
      .then(response => {
        if (Array.isArray(response)) {
          dispatch(fetchModelsByBrandSuccess(response))
        } else {
          dispatch(fetchModelsByBrandSuccess([]))
        }
      })
  }
}

function fetchCarByVinSuccess (payload) {
  return {
    type: 'FETCH_CAR_BY_VIN_SUCCESS',
    payload
  }
}

function fetchBrandsSuccess (payload) {
  return {
    type: 'FETCH_BRANDS_SUCCESS',
    payload
  }
}

function fetchModelsByBrandSuccess (payload) {
  return {
    type: 'FETCH_MODEL_BY_BRANDS_SUCCESS',
    payload
  }
}

// function fetchYearsByModelSuccess(payload) {
//   return {
//     type: 'FETCH_YEARS_BY_MODEL_SUCCESS',
//     payload
//   }
// }

export function getCalendar (month, year, connection) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: 'FETCHING_INIT' })
      const ENDPOINT = formatURL(`${URL}/calendar/`, '', '', '', connection)
      const response = await fetch(
        `${ENDPOINT}&month=${month}&year=${year}&excluded=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const json = await response.json()
      if (json && (json.month || json.year)) {
        dispatch({ type: 'FETCHING_FAILURE' })
        window.alert(JSON.stringify(json))
        return
      }
      dispatch({ type: 'FETCHING_SUCCESS' })
      return json
    } catch (error) {
      window.alert(JSON.stringify(error.message))
    }
  }
}
