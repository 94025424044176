const initialState = {
  connections: [],
  selected: null
}

const Connections = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_DB_CONNECTIONS':
      return {
        ...state,
        connections: payload
      }
    case 'SELECT_CONNECTION':
      return {
        ...state,
        selected: payload
      }
    case 'LOGOUT':
      return {
        ...state,
        selected: null
      }
    default:
      return state
  }
}

export default Connections
