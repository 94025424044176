import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Radio,
  Container,
  Mark,
  CheckContainer,
  Label
} from './styled'

export default class InputRadio extends Component {
  constructor () {
    super()
    this.state = {
      checked: 1
    }
  }
  changeCheck (index) {
    this.setState(({ checked }, { onChange, values }) => {
      typeof onChange === 'function' && onChange(values[index])
      checked = index
      return { checked }
    })
  }
  render () {
    const { labels } = this.props
    const { checked } = this.state
    return (
      <Container>
      {
        labels.map((currentValue, i) => {
          return (
            <CheckContainer key={i} onClick={() => this.changeCheck(i)}>
              <Radio className={currentValue} checked={checked === i}>
                <Mark checked={checked === i}/>
              </Radio>
              <Label labelfor={currentValue}>
                {currentValue}
              </Label>
            </CheckContainer>
          )
        })
      }
      </Container>
    )
  }
}

InputRadio.defaultProps = {
  labels: ['Mas antiguos', 'Mas recientes'],
  values: ['asc', 'desc']
}

InputRadio.propTypes = {
  labels: PropTypes.array,
  values: PropTypes.array,
  onChange: PropTypes.func
}