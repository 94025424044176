import React from 'react'
import PropTypes from 'prop-types'
import { Container, Select } from './styled'

const InputSelect = ({
  options,
  placeholder,
  width,
  height,
  size,
  onChange,
  name,
  disabled,
  loading,
  value,
  hasError,
  ...props
}) => {
  return (
    <Container loading={loading} width={width} height={height} {...props}>
      <Select hasError={hasError} onChange={onChange} size={size} component='select' name={name} disabled={options.length === 0 || disabled}>
        {value ? (
          <option>
            {value}
          </option>
        ) : placeholder && (
          <option>
            {placeholder}
          </option>
        )}
        {options.map((option, index) => {
          const currentOption = (typeof option === 'object') ? `${option.UNC_DESCRIPCION}-${option.UNC_IDCATALOGO}` : option
          const currentValue = (typeof option === 'object') ? `${option.UNC_DESCRIPCION}-${option.UNC_IDCATALOGO}` : option
          return (
            <option value={currentOption} key={index} data-car-id={currentValue}>
              {currentOption}
            </option>
          )
        })}
      </Select>
      {loading && <div className='loader' />}
    </Container>
  )
}

InputSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  size: PropTypes.string
}

export default InputSelect
