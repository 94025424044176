import React, { Component } from 'react'
import _ from 'lodash'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { AppointmentCard } from '../../components'

export function formatURL (url, status, service, search, connection) {
  var newEndpoint = url + '?'
  if (!_.isEmpty(connection)) {
    const last = (
      !_.isEmpty(status) &&
      !_.isEmpty(service) &&
      !_.isEmpty(search)
    ) ? ',' : ''
    newEndpoint += 'office__connection=' + connection + last
  }
  if (!_.isEmpty(status)) {
    newEndpoint += '&status__in=' + status
  }
  if (!_.isEmpty(service)) {
    newEndpoint += '&service_type__in=' + service
  }
  if (!_.isEmpty(search)) {
    newEndpoint += '&search=' + search
  }
  return newEndpoint
}

export const switchStatus = (status) => {
  switch (status) {
    case 'Activas':
      return 'ING'
    case 'Canceladas':
      return 'CAN'
    case 'Finalizadas':
      return 'REA'
    case 'Pausadas':
      return 'STOPPED'
    default:
      return status
  }
}

function itemKey (index, data) {
  // Find the item at the specified index.
  // In this case "data" is an Array that was passed to List as "itemData".
  // const item = data[index]
  // Return a value that uniquely identifies this item.
  // Typically this will be a UID of some sort.
  return data[index].id
}

class ItemRenderer extends Component {
  render () {
    // Access the items array using the "data" prop:
    const item = this.props.data[this.props.index]
    return (
      <AppointmentCard
        onClick={this.props.onClick}
        data={item}
        style={this.props.style}
      />
    )
  }
}

class AppointmentsList extends Component {
  render () {
    const { onClick } = this.props
    return (
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              innerRef={ref => {
                if (ref) {
                  ref.style.height = 0
                }
              }}
              itemData={this.props.itemsArray}
              itemKey={itemKey}
              height={height}
              itemCount={this.props.itemsArray.length}
              itemSize={170}
              width={width}
            >
              {
                (props) => {
                  return (
                    <ItemRenderer onClick={onClick} {...props} />
                  )
                }
              }
            </List>
          )
        }}
      </AutoSizer>
    )
  }
}

export default AppointmentsList
