const initialState = {
  menu: false,
  left: '-14rem'
}

export default (state = initialState, { type }) => {
  switch (type) {
    case 'OPEN_MENU': {
      return {
        left: 0,
        menu: true
      }
    }
    case 'CLOSE_MENU': {
      return {
        left: '-14rem',
        menu: false
      }
    }
    default:
      return state
  }
}
