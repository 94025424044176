import React, { Component } from 'react'
import _ from 'lodash'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import {
  Checkbox,
  RadioButton,
  SearchInput
} from '../../components'
import { Heading } from '../adminConfig/styled'
import {
  Container,
  LeftContent,
  RightContent,
  Filters,
  BottomRow,
  FilterRows,
  TitleTextRow,
  TextRow
} from './styled'
import AppointmentsList, {
  switchStatus,
  formatURL
} from './utils'
import { getUserFromSession } from '../../utils/session'

const URL = process.env.REACT_APP_BASE_URL + '/appointments-history/'

class AdminAppointments extends Component {
  constructor (props) {
    super(props)
    this.state = {
      status: '',
      serviceType: [],
      search: '',
      url: `${URL}`,
      orderBy: 'desc'
    }
    this.onChange = this.onChange.bind(this)
    this.changeOrder = this.changeOrder.bind(this)
    this.onClick = this.onClick.bind(this)
    this.keyListener = this.keyListener.bind(this)
    this.navigateToAppointment = this.navigateToAppointment.bind(this)
    this.clearSearchAppointment = this.clearSearchAppointment.bind(this)
  }
  keyListener (event) {
    if (event.keyCode === 13) {
      if (
        this.onClick &&
        typeof this.onClick === 'function'
      ) {
        this.onClick()
      }
    }
  }
  componentDidMount () {
    let { fetchAppointments, connection } = this.props
    const { url, orderBy } = this.state
    const { key } = getUserFromSession()
    const ENDPOINT = formatURL(url, '', '', '', connection)
    fetchAppointments(key, ENDPOINT, orderBy)
    this.searchBox = document.getElementById('searchBox')
    this.searchBox.addEventListener('search', this.clearSearchAppointment)
    window.addEventListener('keydown', this.keyListener)
  }
  componentWillUnmount () {
    window.removeEventListener('keydown', this.keyListener)
    this.searchBox.removeEventListener('search', this.clearSearchAppointment)
  }
  clearSearchAppointment (e) {
    let { stopAppointments, fetchAppointments, connection } = this.props
    const { url, status, serviceType, orderBy } = this.state
    const { key } = getUserFromSession()
    if (_.isEmpty(e.target.value)) {
      stopAppointments()
      const formatedURL = formatURL(
        url,
        status,
        serviceType,
        e.target.value,
        connection
      )
      fetchAppointments(key, formatedURL, orderBy)
    }
  }
  applyFilters (target, filter, isStatus) {
    const { stopAppointments, fetchAppointments, connection } = this.props
    const { url } = this.state
    const { key } = getUserFromSession()
    const parameter = switchStatus(target.name)
    const checked = target.checked
    stopAppointments()
    let { status, serviceType, search, orderBy } = this.state
    var newStatus = status
    var newServiceType = serviceType
    if (isStatus) {
      if (checked) {
        newStatus += `${parameter},`
      } else {
        newStatus = newStatus.replace(`${parameter},`, '')
      }
    } else {
      if (checked) {
        newServiceType += `${parameter},`
      } else {
        newServiceType = newServiceType.replace(`${parameter},`, '')
      }
    }
    this.setState({
      status: newStatus,
      serviceType: newServiceType
    }, () => {
      const formatedURL = formatURL(
        url,
        this.state.status,
        this.state.serviceType,
        search,
        connection
      )
      fetchAppointments(
        key,
        formatedURL,
        orderBy
      )
    })
  }
  onChange ({ target: { value } }) {
    this.setState({ search: value })
  }
  onClick () {
    const { fetchAppointments, stopAppointments, connection } = this.props
    stopAppointments()
    let { status, serviceType, search, url, orderBy } = this.state
    const { key } = getUserFromSession()
    const formatedURL = formatURL(url, status, serviceType, search, connection)
    fetchAppointments(
      key,
      formatedURL,
      orderBy
    )
  }
  changeOrder (value) {
    const { key } = getUserFromSession()
    const { stopAppointments, fetchAppointments, connection } = this.props
    const { status, serviceType, search, url } = this.state
    stopAppointments()
    this.setState({
      orderBy: value
    }, () => {
      const formatedURL = formatURL(url, status, serviceType, search, connection)
      fetchAppointments(
        key,
        formatedURL,
        this.state.orderBy
      )
    })
  }
  navigateToAppointment (id) {
    const { history } = this.props
    history.push(`/admin/citas-detalle/${id}`)
  }
  render () {
    const newData = this.props.appointments
    return (
      <Container>
        <Heading>CITAS</Heading>
        <BottomRow>
          <LeftContent>
            <SearchInput
              ref={this.searchRef}
              onChange={this.onChange}
              onClick={this.onClick}
            />
            <Formik>
              <Filters>
                <TitleTextRow>
                  <span>Filtros</span>
                </TitleTextRow>
                <TextRow>
                  <span>
                    Orden:
                  </span>
                </TextRow>
                <FilterRows>
                  <RadioButton
                    onChange={this.changeOrder}
                  />
                </FilterRows>
                <TextRow>
                  <span>
                    Estatus:
                  </span>
                </TextRow>
                <FilterRows>
                  <Checkbox
                    onChange={e => this.applyFilters(e.currentTarget, 'status', true)}
                    values={[
                      {
                        name: 'Canceladas',
                        value: 'CAN'
                      },
                      {
                        name: 'Citas Agendadas',
                        value: 'ING'
                      },
                      {
                        name: 'Detenidas',
                        value: 'STOPPED'
                      },
                      {
                        name: 'En Servicio',
                        value: 'SERVICE'
                      },
                      {
                        name: 'Servicio Finalizado',
                        value: 'FINISHED'
                      }
                    ]}
                  />
                </FilterRows>
                <TextRow>
                  <span>
                    Tipo:
                  </span>
                </TextRow>
                <FilterRows>
                  <Checkbox
                    onChange={e => this.applyFilters(e.currentTarget, 'type')}
                    values={[
                      {
                        name: 'Diagnostico',
                        value: 'Servicio de diagnostico'
                      },
                      {
                        name: 'Mantenimiento',
                        value: 'Servicio de mantenimiento'
                      },
                      {
                        name: 'Reparación',
                        value: 'Servicio de reparación'
                      }
                    ]}
                  />
                </FilterRows>
              </Filters>
            </Formik>
          </LeftContent>
          <RightContent>
            {
              (newData && Array.isArray(newData) && newData.length > 0)
                ? <AppointmentsList
                  onClick={this.navigateToAppointment}
                  itemsArray={newData}
                />
                : <span>No se encontraron resultados</span>
            }
          </RightContent>
        </BottomRow>
      </Container>
    )
  }
}

function mapStateToProps ({ Appointments, Connections }) {
  const conn = _.get(Connections.selected, 'connection', '')
  return {
    appointments: Appointments.appointments,
    connection: conn
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchAppointments: (token, url, orderBy) => dispatch({
      type: 'GET_APPOINTMENTS',
      token,
      url,
      orderBy
    }),
    stopAppointments: () => dispatch({ type: 'GET_APPOINTMENTS_OUT' })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAppointments)
