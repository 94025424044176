import React from 'react'
import {
  MainContainer,
  LeftContainer,
  RightContainer,
  Logo,
  List,
  ItemList,
  LinkTo
} from './styled'
import logo from '../../assets/img/Logowhite.png'

const NavBar = ({ location:{pathname} }) => (
  <MainContainer>
    <LeftContainer>
      <Logo
        src={logo}
        alt='Logo autotab'
      />
    </LeftContainer>
    <RightContainer>
      <List>
        <ItemList
          pathname={pathname}
          route='/'
        >
          <LinkTo to='/'>
            INICIO
          </LinkTo>
        </ItemList>
        <ItemList
          pathname={pathname}
          route='/agendar-cita'
        >
          <LinkTo to='/agendar-cita'>
            AGENDAR CITA
          </LinkTo>
        </ItemList>
        {/* <ItemList>
          <LinkTo to='#'>
            DESCARGAR APP
          </LinkTo>
        </ItemList> */}
      </List>
    </RightContainer>
  </MainContainer>
)

export default NavBar