const URL = process.env.REACT_APP_BASE_URL

export const getOfficesConnections = () => {
  return async dispatch => {
    const response = await fetch(
      `${URL}/connections/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await response.json()
    if (data && data.error) {
      const { error } = data
      console.log(error)
      return null
    }
    dispatch({
      type: 'GET_DB_CONNECTIONS',
      payload: data
    })
  }
}

export const loginWithEmailAndPassword = async ({ email, password }) => {
  try {
    const response = await fetch(`${URL}/rest-auth/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password
      })
    })
    const credentials = await response.json()
    if (credentials && credentials.key) {
      return [credentials, null]
    } else {
      return [null, credentials]
    }
  } catch (error) {
    console.log(error)
    return [null, error]
  }
}

export const retrieveUserInfo = async ({ key }) => {
  try {
    const response = await fetch(`${URL}/rest-auth/user/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${key}`
      }
    })
    const user = await response.json()
    if (user && user.id) {
      return [user, null]
    } else {
      return [null, user]
    }
  } catch (error) {
    return [null, error]
  }
}

/**
 * [function to get the type of user trying to login]
 * @param  {[string]} email [user's email address]
 * @return {[Object]}       [object with information that indicates if the user is a staff or super user]
 */
export const getUserType = async (email) => {
  try {
    let response = await fetch(`${URL}/get-user-type/${email}/`, { method: 'GET' })
    response = await response.json()
    return response
  } catch (err) {
    return {}
  }
}
