import React , { Component } from 'react'
import { connect } from 'react-redux'
import { singIn } from './actions'
import {
  Container,
  LoginBox
} from './styled'
import {
  GeneralButton,
  GeneralInput
} from '../../components'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: '',
      password: '',
      errorShow: false
    }
    this.onChange = this.onChange.bind(this)
    this.onClick = this.onClick.bind(this)
  }
  onChange({ target: { value, name } }) {
    this.setState({ [name]: value })
  }
  onClick() {
    let { user, password } = this.state
    const {
      history: { push },
      singIn
    } = this.props
    if (user.length > 0 && password.length > 0) singIn(user, push)
    else alert('Complete todos los campos')
  }
  componentDidMount() {
    let { error } = this.props
    if (error) alert('error en el inicio de sesion')
  }
  render() {
    const { user, password } = this.state
    return (
      <Container>
        <LoginBox>
          <GeneralInput
            value={user}
            placeholder='Usuario'
            onChange={this.onChange}
            name='user'
          />
          <GeneralInput
            value={password}
            placeholder='Contraseña'
            onChange={this.onChange}
            type='password'
            name='password'
          />
          <GeneralButton onClick={this.onClick}>Iniciar Sesión</GeneralButton>
        </LoginBox>
      </Container>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    singIn: (user, push) => dispatch(singIn(user, push))
  }
}

function mapStateToProps({ User }) {
  return {
    error: User.error
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
