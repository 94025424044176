import React, { Component } from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import { connect } from 'react-redux'
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import * as es from 'date-fns/locale/es'
import swal from '@sweetalert/with-react'
import 'react-datepicker/dist/react-datepicker.css'
import {
  Checkbox,
  InputSelect,
  GeneralInput,
  GeneralButton as Button
} from '../../components'
import { CustomInput } from '../makeApointment/styled'
import {
  Container,
  FormContainer,
  Flex,
  SelectContainer,
  Office,
  Text,
  Icon,
  Flexible,
  Heading,
  DeleteButton,
  Label
} from './styled'
import { calculateSundays, getTomorrow, getTime } from '../makeApointment/utils'
import {
  getSettings,
  getOffices,
  handleSubmit,
  deleteOffice,
  fetchHolidays
} from './actions'
import NewOffice from './utils'

registerLocale('es', es)

class AdminConfig extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showForm: false
    }
    this.sundays = calculateSundays()
    this.onSubmit = this.onSubmit.bind(this)
    this.deleteOffice = this.deleteOffice.bind(this)
    this.initialState = this.initialState.bind(this)
    this._renderOffices = this._renderOffices.bind(this)
  }
  componentDidMount () {
    const { getSettings, getOffices, getHolidays } = this.props
    if (typeof getSettings === 'function') getSettings()
    if (typeof getOffices === 'function') getOffices()
    if (typeof getHolidays === 'function') getHolidays()
  }
  handleOffices (path, value, onChange) {
    onChange(path, value)
  }
  initialState () {
    const { configuration } = this.props
    return configuration
  }
  hourFormat (hour) {
    if (typeof hour === 'object') {
      return hour
    } else {
      const [h, m, s] = hour.split(':')
      var d = new Date()
      d.setHours(parseInt(h))
      d.setMinutes(parseInt(m))
      d.setSeconds(parseInt(s))
      return d
    }
  }
  deleteOffice (office) {
    const { deleteOffice } = this.props
    const { id } = office
    swal('¿Deseas eliminar esta sucursal?', {
      dangerMode: true,
      buttons: ['Cancelar', 'OK']
    })
      .then(value => {
        if (value) {
          deleteOffice(id)
        }
      })
      .catch(e => {
        // console.log(e)
      })
  }
  _renderOffices (values, setFieldValue) {
    const { offices } = this.props.configuration
    const keys = Object.keys(offices)
    const formikOffices = values.offices
    if (!_.isEmpty(keys)) {
      return keys.map((key, index) => {
        return (
          <Office key={index}>
            <Text paddingB color>{key}</Text>
            <Flexible>
              <Flexible>
                <DatePicker
                  locale='es'
                  selected={(formikOffices && formikOffices[key]) && formikOffices[key].date_of_appointments}
                  placeholderText='Seleccione una fecha para su cita'
                  customInput={<CustomInput />}
                  onChange={date => this.handleOffices(`offices.${key}.date_of_appointments`, date, setFieldValue)}
                  dateFormat='dd/MM/YYYY'
                  excludeDates={this.sundays}
                  minDate={getTomorrow()}
                  withPortal
                />
                <Icon
                  icon={['far', 'calendar-alt']}
                />
              </Flexible>
              <Flexible>
                <DatePicker
                  selected={(formikOffices && formikOffices[key]) && this.hourFormat(formikOffices[key].hour_of_appointments)}
                  onChange={hour => this.handleOffices(`offices.${key}.hour_of_appointments`, hour, setFieldValue)}
                  placeholderText='Seleccione una hora para su cita'
                  customInput={<CustomInput />}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat='h:mm aa'
                  timeCaption='Hora'
                  minTime={getTime(true)}
                  maxTime={getTime()}
                />
                <Icon
                  icon={['far', 'clock']}
                />
              </Flexible>
              <Flexible>
                <DeleteButton
                  onClick={() => this.deleteOffice(formikOffices[key])}
                >
                  <Icon
                    color='#FFF'
                    icon={['far', 'trash-alt']}
                  />
                </DeleteButton>
              </Flexible>
            </Flexible>
          </Office>
        )
      })
    }
    return null
  }
  async onSubmit (values, actions) {
    const { setSubmitting } = actions
    const { handleSubmit } = this.props
    const keys = Object.keys(values.offices)
    const form = { ...JSON.parse(JSON.stringify(values)) }
    keys.forEach(key => {
      const actualValue = values.offices[key]
      if (actualValue.date_of_appointments instanceof Object) {
        form.offices[key].date_of_appointments = moment(actualValue.date_of_appointments).format('YYYY-MM-DD')
      }
      if (actualValue.hour_of_appointments instanceof Object) {
        form.offices[key].hour_of_appointments = moment(actualValue.hour_of_appointments).format('HH:mm')
      }
    })
    delete form['file']
    const [settings, offices] = await handleSubmit(form, values.file)
    if (settings) {
      if (
        !settings.hasOwnProperty('error') ||
        !offices.hasOwnProperty('error')
      ) {
        setSubmitting(false)
        document.getElementById('file').value = null
        swal('Exitosamente', 'tús datos han sido actualizados.', 'success', {
          timer: 2000,
          button: false,
          closeOnClickOutside: false
        })
      }
    }
  }
  toStringMinutes (time) {
    return `${time} Minutos`
  }
  render () {
    const { holidays } = this.props
    return (
      <Container>
        <Heading>CONFIGURACIONES</Heading>
        <Formik
          enableReinitialize
          initialState={{
            appointments_email: '',
            email_notification: false,
            push_notification: false,
            time_for_last_notification: '',
            conmutator_phone: '',
            offices: {},
            file: ''
          }}
          initialValues={this.initialState()}
          onSubmit={this.onSubmit}
        >
          {({
            handleChange,
            values,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            ...formik
          }) => {
            const { time_for_last_notification: timeForLastNotification } = values
            return (
              <FormContainer>
                <Flex direction='column' padding='0px 0px 0px 1.5rem;'>
                  <h5>Notificaciones en general</h5>
                  <Text>Seleccione los medios por los que el cliente recibirá las notificaciones:</Text>
                  <div>
                    <Checkbox
                      onChange={handleChange}
                      values={['Notificación en la app', 'Email']}
                      checkeds={[values.push_notification, values.email_notification]}
                      names={['push_notification', 'email_notification']}
                    />
                  </div>
                </Flex>
                <Flex direction='column' padding='0px 0px 0px 1.5rem;'>
                  <h5>Número de conmutador</h5>
                  <GeneralInput
                    placeholder='Número del conmutador'
                    value={values.conmutator_phone}
                    onChange={handleChange}
                    name='conmutator_phone'
                    id='conmutator_phone'
                    width='130%'
                  />
                </Flex>
                <Flex direction='column' padding='0px 0px 0px 1.5rem;'>
                  <h5>Correo electronico del encargado de citas</h5>
                  <Text style={{ marginBottom: 30 }}>Correo electronico del encargado de citas. (A este correo electronico le llegaran todos los asuntos relacionados a la app como citas y errores de usuarios duplicados en el BS Pro)</Text>
                  <GeneralInput
                    placeholder='Ingresa un correo electronico...'
                    value={values.appointments_email}
                    onChange={handleChange}
                    name='appointments_email'
                    id='appointments_email'
                    width='150%'
                  />
                </Flex>
                <Flex direction='column' padding='0px 0px 0px 1.5rem;'>
                  <h5>Notificación de Recordatorio</h5>
                  <Text>
                    El tiempo seleccionado se utilizara para enviar una notificación de recordatorio de cita a los usuarios
                    con el tiempo seleccionado, por defecto es 1 hra antes de la cita agendada:
                  </Text>
                  <SelectContainer>
                    <InputSelect
                      options={[
                        '5 minutos',
                        '10 minutos',
                        '15 minutos',
                        '20 minutos',
                        '25 minutos',
                        '30 minutos',
                        '35 minutos',
                        '40 minutos',
                        '45 minutos',
                        '50 minutos',
                        '55 minutos',
                        '60 minutos'
                      ]}
                      value={!_.isEmpty(timeForLastNotification) && this.toStringMinutes(timeForLastNotification)}
                      placeholder='Selecciona el tiempo de la notificación'
                      height='1.8rem'
                      width='30%'
                      name='time_for_last_notification'
                      id='time_for_last_notification'
                      onChange={handleChange}
                    />
                  </SelectContainer>
                </Flex>
                <Flex noFlex direction='column' padding='0px 0px 0px 1.5rem;'>
                  <h5>Servicio en sucursales</h5>
                  <Text>
                    Cuando el servicio entre al estatus de "Lavado de auto", los usuarios recibirán un mensaje indicando
                    que su vehículo pronto estará listo. Elija en cuánto tiempo desea enviar esta notificación:
                  </Text>
                  {this._renderOffices(values, setFieldValue)}
                  {
                    !this.state.showForm && (
                      <Button
                        padding='0px'
                        margin='20px 0px'
                        height='2rem'
                        type='submit'
                        onClick={() => {
                          this.setState(prevState => {
                            return {
                              ...prevState,
                              showForm: true
                            }
                          })
                        }}
                        noHover
                      >
                        Agregar sucursal
                      </Button>
                    )
                  }
                  {
                    this.state.showForm && (
                      <NewOffice onCancel={() => {
                        this.setState({
                          showForm: false
                        })
                      }} />
                    )
                  }
                </Flex>
                <Flex noFlex direction='column' padding='0px 0px 0px 1.5rem;'>
                  <h5>Calendario de dias festivos</h5>
                  <Text>
                    Podras cargar un archivo con extensión CSV o XLSX, de los
                    cuales unicamente requieren la columna id y date.
                  </Text>
                  <div>
                    <input
                      type='file'
                      name='file'
                      id='file'
                      accept='.csv,.xlsx'
                      multiple={false}
                      style={{ marginTop: 20, marginBottom: 20 }}
                      onChange={e => setFieldValue('file', e.currentTarget.files[0])}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DatePicker
                      locale='es'
                      inline
                      disabled
                      highlightDates={holidays}
                    />
                    <Label>Días Festivos</Label>
                  </div>
                </Flex>
                <Flex padding='20px 0px' align='center' justify='center'>
                  <Button hasLoading type='submit' onClick={handleSubmit}>
                    {
                      isSubmitting ? <div className='login_loader' /> : 'GUARDAR'
                    }
                  </Button>
                </Flex>
              </FormContainer>
            )
          }}
        </Formik>
      </Container>
    )
  }
}

function mapStateToProps (state) {
  const { holidays } = state.Configuration
  return {
    configuration: state.Configuration,
    holidays
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getSettings: () => dispatch(getSettings()),
    getOffices: () => dispatch(getOffices()),
    handleSubmit: (form, file) => dispatch(handleSubmit(form, file)),
    deleteOffice: id => dispatch(deleteOffice(id)),
    getHolidays: () => dispatch(fetchHolidays())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminConfig)
