import React from 'react'
import PropTypes  from 'prop-types'
import {
  MainContainer,
  LeftContent,
  RightContent,
  Row,
  StyledText,
  DateContainer
} from './styled'
import {
  blue,
  green,
  red,
  yellow,
  lightGray
} from '../../colorPallette.json'
import moment from 'moment'

const formatDate = (date, hour, createdAt) => {
  moment.locale('es', {
    months : 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_')
  })
  let formatedDate = moment(date).format('DD MMMM')
  let hours = hour && hour.substring(0, 2)
  let minutes = hour && hour.substring(3, 5)
  let formatedHour = hour && moment().hour(hours).minutes(minutes).format('hh:mm A')
  return formatedHour ? `${formatedDate} ${formatedHour}` : formatedDate
}

const switchColorStatus = (status) => {
  switch (status) {
    case 'CAN':
      return {
        color: red,
        text: 'Cita Cancelada'
      }
    case 'STOPPED':
      return {
        color: yellow,
        text: 'Servicio Detenido'
      }
    case 'ING':
      return {
        color: blue,
        text: 'Cita Solicitada'
      }
    case 'REA':
      return {
        color: green,
        text: 'Cita Realizada'
      }
    case 'NRE':
      return {
        color: red,
        text: 'Cita No Realizada'
      }
    case 'FINISHED':
      return {
        color: green,
        text: 'Servicio Finalizado'
      }
    case 'SERVICE':
      return {
        color: blue,
        text: 'En Servicio'
      }
    default:
      return {
        color: blue,
        text: '........'
      }
  }
}

const AppointmentCard = ({
  onClick,
  data: {
    appointment_id,
    profile: {
      name,
      last_name,
      mother_last_name
    },
    service_type,
    consultant: {
      name: consultant_name,
      created_at: createdAt
    },
    date,
    hour,
    status,
    order_number: orderNumber
  },
  ...props
}) => {
  return (
    <MainContainer
      style={{
        ...props.style,
        left: props.style.left,
        top: props.style.top,
        width: props.style.width,
        height: props.style.height - 20
      }}
      onClick={() => onClick(appointment_id || orderNumber)}
    >
      <LeftContent>
        <Row>
          <StyledText
            color={blue}
            fontWeight={400}
            margin='0 2rem 0 0'
          >
            {appointment_id
              ? (<>N. Cita #{ appointment_id }</>)
              : (<>N. Ordén #{ orderNumber }</>)
            }
          </StyledText>
          <StyledText
            fontWeight={400}
          >
            Cliente: {`${name} ${last_name} ${mother_last_name}`}
          </StyledText>
        </Row>
        {service_type && (
          <Row>
            <StyledText
              fontWeight={300}
            >
              Tipo de servicio: {service_type}
            </StyledText>
          </Row>
        )}
        <Row>
          <StyledText
            margin='0 .5rem 0 0'
            color={lightGray}
          >
            Asesor
          </StyledText>
          <StyledText
            fontWeight={200}
          >
            {consultant_name}
          </StyledText>
        </Row>
      </LeftContent>
      <RightContent>
        <DateContainer>
          <StyledText
            textalign='right'
            fontWeight={400}
          >
            {formatDate(date, hour, createdAt)}
          </StyledText>
        </DateContainer>
        <StyledText
          color={lightGray}
          fontWeight={400}
        >
          Estatus: <StyledText fontWeight={400} color={switchColorStatus(status).color}>{switchColorStatus(status).text}</StyledText>
        </StyledText>
      </RightContent>
    </MainContainer>
  )
}

AppointmentCard.propTypes = {
  data: PropTypes.shape({
    appointment_id: PropTypes.number,
    profile: PropTypes.shape({
      name: PropTypes.string,
      last_name: PropTypes.string,
      mother_last_name: PropTypes.string
    }),
    service_type: PropTypes.string,
    consultant: PropTypes.shape({
       consultant_name: PropTypes.string
    }),
    date: PropTypes.string,
    hour: PropTypes.string,
    status: PropTypes.oneOf(['CAN', 'stoped', 'ING', 'REA'])
  })
}

export default AppointmentCard
