import React, {Component} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  MainContainer,
  Client,
  LeftSection,
  RightSection,
  ClientName,
  RightSectionTop,
  HorizontalTagContainer,
  VerticalTagContainer,
  ClientData,
  RightSectionBottom,
  ApointmentNumber,
  ApointmentDate,
  GenericText,
  DownloadLink,
  AddsBar,
  GenericTextGray,
  Content,
  TopContent,
  Row,
  AppContainer,
  AppImage,
  Icon
} from './styled'
import { Footer } from '../../components'
import app from '../../assets/img/app4.png'
import { primary } from '../../colorPallette.json'
import moment from 'moment'

class ApointmentConfirmartion extends Component {
  constructor () {
    super()
    this.formatDate = this.formatDate.bind(this)
  }
  formatDate (date, hour) {
    moment.locale('es', {
      months : 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_')
    })
    let formatedDate = moment(date).format('DD MMMM')
    let hours = hour.substring(0, 2)
    let minutes = hour.substring(3, 5)
    let formatedHour = moment().hour(hours).minutes(minutes).format('hh:mm A')
    return `${formatedDate} ${formatedHour}`
  }
  render () {
    const {
      appointment_id,
      car_brand,
      car_model,
      car_year,
      date,
      hour,
      office: {
        name:  officeName
      },
      service_type,
      observations,
      consultant: { name: consultant_name },
      profile: {
        name,
        last_name,
        mother_last_name,
        phone,
        user:{ email }
      }
    } = this.props.location.state
    return (
      <MainContainer>
        <Content>
          <TopContent>
            <Client className='clientt'>
              <LeftSection>
                <ApointmentNumber>
                  {`#${appointment_id}`}
                </ApointmentNumber>
                <GenericText>
                  Fecha programada
                </GenericText>
                <ApointmentDate>
                  {this.formatDate(date, hour)}
                </ApointmentDate>
                <VerticalTagContainer>
                  <GenericTextGray>
                    Sucursal
                  </GenericTextGray>
                  <GenericText>
                    {officeName}
                  </GenericText>
                </VerticalTagContainer>
              </LeftSection>
              <RightSection>
                <RightSectionTop>
                  <ClientName>
                    {name} {last_name} {mother_last_name}
                  </ClientName>
                  <ClientData>
                    <HorizontalTagContainer>
                      <FontAwesomeIcon icon={['fas', 'phone']}/>
                      <span>
                        {phone}
                      </span>
                    </HorizontalTagContainer>
                    <HorizontalTagContainer>
                      <FontAwesomeIcon icon={['fas', 'envelope']}/>
                      <span>
                        {email}
                      </span>
                    </HorizontalTagContainer>
                  </ClientData>
                </RightSectionTop>
                <RightSectionBottom>
                  <Row>
                    <VerticalTagContainer>
                      <GenericTextGray>
                        Vehiculo
                      </GenericTextGray>
                      <GenericText>
                        {car_brand}
                      </GenericText>
                    </VerticalTagContainer>
                    <VerticalTagContainer>
                      <GenericTextGray>
                        Modelo
                      </GenericTextGray>
                      <GenericText>
                        {car_model}
                      </GenericText>
                    </VerticalTagContainer>
                    <VerticalTagContainer>
                      <GenericTextGray>
                        Año
                      </GenericTextGray>
                      <GenericText>
                        {car_year}
                      </GenericText>
                    </VerticalTagContainer>
                  </Row>
                  <Row>
                    <VerticalTagContainer>
                      <GenericTextGray>
                        Observaciones
                      </GenericTextGray>
                      <GenericText>
                        {observations || 'Ninguna'}
                      </GenericText>
                    </VerticalTagContainer>
                  </Row>
                  <Row>
                    <VerticalTagContainer>
                      <GenericTextGray>
                        Tipo de servicio
                      </GenericTextGray>
                      <GenericText>
                        {service_type}
                      </GenericText>
                    </VerticalTagContainer>
                  </Row>
                  <Row>
                    <VerticalTagContainer>
                      <GenericTextGray>
                        Asesor
                      </GenericTextGray>
                      <GenericText>
                        {consultant_name}
                      </GenericText>
                    </VerticalTagContainer>
                  </Row>
                </RightSectionBottom>
              </RightSection>
              <DownloadLink href='http://onelink.to/pyy799'>
                VER MÁS
              </DownloadLink>
            </Client>
            <AppContainer>
              <AppImage src={app}/>
              <GenericText
                center
                padding='0 6rem'
              >
                También puedes revisar tu cita desde la app y acceder a muchas más funciones.
              </GenericText>
              <GenericText
                color={primary}
                center
                fontSize='1.2rem'
                marginTop='1rem'
              >
                DESCÁRGALA GRATIS
              </GenericText>
            </AppContainer>
          </TopContent>
          <AddsBar>
            Para cualquier duda o aclaración, puede comunicarse con nosotros al siguiente numero:
            <GenericText
              color={primary}
              center
              fontSize='1.2rem'
              marginTop='1rem'
            >
              <Icon />
              01 (993) 358-0600
            </GenericText>
          </AddsBar>
        </Content>
        <Content>
          <Footer />
        </Content>
      </MainContainer>
    )
  }
}

export default ApointmentConfirmartion