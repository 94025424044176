import {
  formatURL
} from '../adminAppointments/utils'
import { getUserFromSession } from '../../utils/session'

const URL = process.env.REACT_APP_BASE_URL

export const registerNewWorkerUser = (form) => {
  return async (dispatch, getState) => {
    try {
      const {
        Connections: {
          selected: {
            connection
          }
        }
      } = getState()
      form['connection'] = connection
      const response = await fetch(`${URL}/rest-auth/registration/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(form)
      })
      const data = await response.json()
      if (data && data.key) {
        const {
          is_mechanic,
          is_consultant
        } = form
        const q = is_consultant ? 'Consultants' : is_mechanic ? 'Mechanics' : null
        if (q) dispatch(getWorkers(q))
      }
      return data
    } catch (error) {
      console.log(error)
      return error.message
    }
  }
}

function getWorkers (q) {
  return async (dispatch, getState) => {
    try {
      const {
        key: token
      } = getUserFromSession()
      const {
        Connections: {
          selected: {
            connection
          }
        }
      } = getState()
      const actionType = q === 'Consultants' ? 'GET_CONSULTANTS_SUCCESS' : q === 'Mechanics' ? 'GET_MECHANICS_SUCCESS' : null
      const ENDPOINT = formatURL(`${URL}/workers/`, '', '', '', connection)
      console.log('ENDPOINT >>', ENDPOINT)
      const response = await fetch(`${ENDPOINT}&q=${q}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
      const json = await response.json()
      if (Array.isArray(json)) {
        if (actionType) {
          dispatch({
            type: actionType,
            payload: json
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
}
