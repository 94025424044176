export const  OpenMenu = () => {
  return dispatch => {
    dispatch({type: 'OPEN_MENU'})   
  }
}

export const CloseMenu = () => {
  return dispatch => {
    dispatch({type: 'CLOSE_MENU'})   
  }
}