import styled from 'styled-components'
import { GeneralButton } from '../../components'
import {
  primary,
  primaryHover,
  grayLabel,
  red
} from '../../colorPallette'
import background from '../../assets/img/background.png'

export const Container = styled.main`
  height: 100vh;
  display: flex;

  @media (max-height: 650px) {
    height: 90vh;
  }

  @media (max-height: 300px) {
    height: auto;
    padding-top: 3%;
  }
`

export const ImageContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  display: flex;
  flex: 4;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 700px) {
    position: absolute;
    background-image: none;
    width: 100%;
    height: 30%;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Logo = styled.img`
  width: 50%;

  @media (max-width: 700px) {
    width: 0;
  }
`

export const BlueLogo = styled.img`
  width: 0;
  height: 0;

  @media (max-width: 700px) {
    width: ${({ showLogo }) => showLogo ? '35%' : '0'};
    height: auto;
  }
`

export const PaddingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10rem;
  box-sizing: border-box;

  @media (max-width: 1120px) {
    padding: 0 8rem;
  }

  @media (max-width: 1000px) {
    padding: 0 6rem;
  }
  
  @media (max-width: 890px) {
    padding: 0 5rem;
  }

  @media (max-width: 790px) {
    padding: 0 3.7rem;
  }

  @media (max-width: 700px) {
    padding: 0 20vw;
  }

  @media (max-width: 590px) {
    padding: 0 22vw;
  }

  @media (max-width: 480px) {
    padding: 0 18vw;
  }
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: .7rem;

  @media (min-width: 1500px) {
    font-size: 1.5rem;
  }

  @media (min-width: 2000px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }

  @media (max-width: 400px) {
    font-size: 18px;
  }
`

export const ForgotPassword = styled.a`
  color: ${primary};
  font-size: .8rem;
  font-weight: 500;
  cursor: pointer;

  @media (min-width: 2000px) {
    font-size: 1.2rem;
  }

  &:hover {
    color: ${primaryHover}
  }
`

export const Text = styled.span`
  color: ${grayLabel};
  font-size: .8rem;
  margin-bottom: 1.4rem;

  @media (min-width: 2000px) {
    font-size: 1.2rem;
  }
`

export const InputContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  height: 2.3rem;

  @media(min-width: 2000px) {
    margin-bottom: 1.5rem;
  }
`

export const Button = styled(GeneralButton)`
  margin-top: 2.3rem;
  ${({ hasLoading }) => {
    if (hasLoading) {
      return `
        display: flex;
        align-items: center;
        justify-content: center;
      `
    }
  }};
`

export const ErrorSpan = styled.span`
  padding: 5px 0px;
`

export const FormTopError = styled.div`
  padding: 5px 0px;
  width: 100%;
  height: 1.5rem;
  margin-bottom: 5px;
  & > span {
    color: ${red};
    font-size: .7rem;
  }
`