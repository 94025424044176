import React, { Component } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { GoogleMap, withGoogleMap, withScriptjs, Marker } from 'react-google-maps'
import ImageGallery from 'react-image-gallery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons'
import Steps, { Step } from 'rc-steps'
import 'rc-steps/assets/index.css'
import 'rc-steps/assets/iconfont.css'
import {
  MainContainer,
  Client,
  LeftSection,
  RightSection,
  ClientName,
  RightSectionTop,
  HorizontalTagContainer,
  VerticalTagContainer,
  ClientData,
  RightSectionBottom,
  ApointmentNumber,
  ApointmentDate,
  GenericText,
  GenericTextGray,
  Row,
  Icon,
  ContentBottom,
  Status,
  StatusText,
  LoaderContainer,
  WhiteContainer,
  CollapseButton,
  GalleryTypeContainer,
  Collapsable,
  VideoPlayer,
  FlexColumn,
  TitleContainer,
  DescriptionContainer
} from './styled'
import { Heading } from '../adminConfig/styled'
import { getAppointment } from './actions'
import {
  defaultGray,
  primary,
  yellow,
  red,
  green,
  blue
} from '../../colorPallette.json'
import { Loader, PageNotFound } from '../../components'
import {
  validateGallery,
  getPhotosURL
} from '../../utils'

const MyMapComponent = withScriptjs(withGoogleMap(({ lat, lng, isMarkerShown }) => {
  return (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat, lng }}
    >
      {isMarkerShown && <Marker position={{ lat, lng }} />}
    </GoogleMap>
  )
}))

const switchColorStatus = (status) => {
  switch (status) {
    case 'CAN':
      return {
        color: red,
        text: 'Cita Cancelada'
      }
    case 'STOPPED':
      return {
        color: yellow,
        text: 'Servicio Detenido'
      }
    case 'ING':
      return {
        color: blue,
        text: 'Cita Solicitada'
      }
    case 'REA':
      return {
        color: green,
        text: 'Cita Realizada'
      }
    case 'NRE':
      return {
        color: red,
        text: 'Cita No Realizada'
      }
    case 'FINISHED':
      return {
        color: green,
        text: 'Servicio Finalizado'
      }
    case 'SERVICE':
      return {
        color: blue,
        text: 'En Servicio'
      }
    default:
      return {
        color: blue,
        text: '........'
      }
  }
}

const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

class ApointmentDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      notFound: false,
      collapsable: {
        collapsePhotoService: true,
        collapsePhotoReceive: true,
        collapseVideoService: true,
        collapseVideoReceive: true
      }
    }
    this.id = null
    this.formatDate = this.formatDate.bind(this)
    this.renderGallery = this.renderGallery.bind(this)
  }

  formatDate (date, hour) {
    moment.locale('es', {
      months: MONTHS
    })
    let formatedDate = moment(date).format('DD MMMM')
    let hours = hour && hour.substring(0, 2)
    let minutes = hour && hour.substring(3, 5)
    let formatedHour = hour && moment()
      .hour(hours)
      .minutes(minutes)
      .format('hh:mm A')
    return hour ? `${formatedDate} ${formatedHour}` : formatedDate
  }

  async componentDidMount () {
    const { getAppointment } = this.props
    const { pathname } = this.props.location
    const id = pathname.split('/')[pathname.split('/').length - 1]
    this.id = id
    this.setState(prevState => {
      return {
        ...prevState,
        isLoading: true
      }
    })
    const appointment = await getAppointment(id)
    if (appointment && _.isObject(appointment) && appointment.id) {
      this.setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          notFound: false
        }
      })
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          notFound: true
        }
      })
    }
  }

  getCurrentStatus (status) {
    switch (status) {
      case 'ING':
        return 0
      case 'REA':
        return 1
      case 'SERVICE':
        return 2
      case 'STOPPED':
        return 2
      default:
        return 3
    }
  }

  renderStars (raiting) {
    const icons = []
    for (let i = 0; i < raiting; i++) {
      icons.push(<FontAwesomeIcon icon={faStar} size='1x' style={{ color: 'gold' }} />)
    }
    return icons
  }

  render () {
    const { isLoading, notFound } = this.state
    const { appointment, location } = this.props
    if (isLoading) {
      return (
        <MainContainer>
          <Heading>
            <Link to={location && location.state && location.state.fromCalendar ? '/admin/calendario' : '/admin/citas'}>
              <Icon icon={['fas', 'chevron-left']} color={primary} />
              REGRESAR
            </Link>
          </Heading>
          <ContentBottom>
            <LoaderContainer>
              <Loader />
              <span>Cargando</span>
            </LoaderContainer>
          </ContentBottom>
        </MainContainer>
      )
    }
    if (!_.isEmpty(appointment) && !isLoading && !notFound) {
      const {
        appointment_id,
        car_brand,
        car_model,
        car_year,
        date,
        hour,
        office: {
          name: officeName
        },
        service_type,
        observations,
        status,
        order_number,
        raiting,
        comments,
        photos,
        videos,
        consultant: { name: consultant_name },
        profile: {
          name,
          last_name,
          mother_last_name,
          phone,
          user: { email }
        }
      } = appointment
      const canceledOrNRE = new RegExp(/(CAN|NRE)/)
      return (
        <MainContainer>
          <Heading>
            <Link to={location && location.state && location.state.fromCalendar ? '/admin/calendario' : '/admin/citas'}>
              <Icon icon={['fas', 'chevron-left']} color={primary} />
              REGRESAR
            </Link>
          </Heading>
          <WhiteContainer>
            <Client className='clientt'>
              <LeftSection>
                <ApointmentNumber>{`#${appointment_id || order_number}`}</ApointmentNumber>
                <GenericText>Fecha programada</GenericText>
                <ApointmentDate>{this.formatDate(date, hour)}</ApointmentDate>
                <VerticalTagContainer>
                  <GenericTextGray>Sucursal</GenericTextGray>
                  <GenericText>{officeName}</GenericText>
                </VerticalTagContainer>
                {appointment_id && (
                  <VerticalTagContainer>
                    <GenericTextGray>N. Orden</GenericTextGray>
                    <GenericText>{order_number || 'N/A'}</GenericText>
                  </VerticalTagContainer>
                )}
                {
                  raiting &&
                  <VerticalTagContainer>
                    <GenericTextGray>Calificación de servicio</GenericTextGray>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      {this.renderStars(raiting)}
                    </div>
                  </VerticalTagContainer>
                }
                {
                  comments &&
                  <VerticalTagContainer>
                    <GenericTextGray>Comentarios del servicio</GenericTextGray>
                    <GenericText>{comments || 'N/A'}</GenericText>
                  </VerticalTagContainer>
                }
              </LeftSection>
              <RightSection>
                <Status>
                  <StatusText>
                    Estatus: <StatusText color={switchColorStatus(status).color}>{switchColorStatus(status).text}{' '}</StatusText>
                  </StatusText>
                  {
                    !canceledOrNRE.test(status) && (
                      <Steps claasName='status' status={status === 'STOPPED' ? 'error' : 'process'} current={this.getCurrentStatus(status)} direction='horizontal'>
                        <Step title={appointment_id ? 'Cita agendada' : 'Ordén recibida'} />
                        <Step title={`${appointment_id ? 'Cita' : 'Ordén'} realizada`} />
                        <Step title='En servicio' />
                        <Step title='Servicio Finalizado' />
                      </Steps>
                    )
                  }
                </Status>
                <RightSectionTop>
                  <ClientName>
                    Cliente: {name} {last_name} {mother_last_name}
                  </ClientName>
                  <ClientData>
                    <HorizontalTagContainer>
                      <Icon
                        icon={['fas', 'phone']}
                        color={defaultGray}
                        rotate='90deg'
                      />
                      <span>{phone}</span>
                    </HorizontalTagContainer>
                    <HorizontalTagContainer>
                      <FontAwesomeIcon icon={['fas', 'envelope']} />
                      <span>{email}</span>
                    </HorizontalTagContainer>
                  </ClientData>
                </RightSectionTop>
                <RightSectionBottom>
                  <Row>
                    <VerticalTagContainer>
                      <GenericTextGray>Vehiculo</GenericTextGray>
                      <GenericText>{car_brand}</GenericText>
                    </VerticalTagContainer>
                    <VerticalTagContainer>
                      <GenericTextGray>Modelo</GenericTextGray>
                      <GenericText>{car_model}</GenericText>
                    </VerticalTagContainer>
                    <VerticalTagContainer>
                      <GenericTextGray>Año</GenericTextGray>
                      <GenericText>{car_year}</GenericText>
                    </VerticalTagContainer>
                  </Row>
                  <Row>
                    <VerticalTagContainer>
                      <GenericTextGray>Observaciones</GenericTextGray>
                      <GenericText>{observations || 'Ninguna'}</GenericText>
                    </VerticalTagContainer>
                  </Row>
                  { service_type && (
                    <Row>
                      <VerticalTagContainer>
                        <GenericTextGray>Tipo de servicio</GenericTextGray>
                        <GenericText>{service_type}</GenericText>
                      </VerticalTagContainer>
                    </Row>
                  )}
                  <Row>
                    <VerticalTagContainer>
                      <GenericTextGray>Asesor</GenericTextGray>
                      <GenericText>{consultant_name}</GenericText>
                    </VerticalTagContainer>
                  </Row>
                </RightSectionBottom>
              </RightSection>
            </Client>
            {
              (appointment.latitude && appointment.longitude && appointment.address) && (
                <>
                  <div style={{ paddingLeft: '30px' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      El cliente solicito que su vehiculo sea recogido en la siguiente dirección:
                    </span>
                    <br />
                    <br />
                    <span style={{ fontWeight: 'bold' }}>
                      <a
                        style={{
                          textDecoration: 'none',
                          color: primary
                        }}
                        href={`http://www.google.com/maps/place/${appointment.latitude},${appointment.longitude}/@${appointment.latitude},${appointment.longitude},17z`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {appointment.address}
                      </a>
                    </span>
                  </div>
                  <MyMapComponent
                    isMarkerShown
                    lat={parseFloat(appointment.latitude)}
                    lng={parseFloat(appointment.longitude)}
                    googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyCD8MKt7SemFgwmlBzMmsSmTzr0u9-dDto&v=3.exp&libraries=geometry,drawing,places'
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `300px`, padding: '10px 30px 30px 30px' }} />}
                    mapElement={<div style={{ height: `100%`, display: 'flex' }} />}
                  />
                </>
              )
            }
            {this.renderGallery(photos, videos)}
          </WhiteContainer>
        </MainContainer>
      )
    }
    if (_.isEmpty(appointment) && notFound) {
      return (
        <MainContainer>
          <Heading>
            <Link to={location && location.state && location.state.fromCalendar ? '/admin/calendario' : '/admin/citas'}>
              <Icon icon={['fas', 'chevron-left']} color={primary} />
              REGRESAR
            </Link>
          </Heading>
          <ContentBottom>
            <PageNotFound isAppointment id={this.id} />
          </ContentBottom>
        </MainContainer>
      )
    }
    return null
  }

  renderGallery (photos, videos) {
    if (validateGallery(photos, videos)) {
      const { collapsable } = this.state
      const servicePhotos = getPhotosURL(photos.service, 'photo')
      const receptionPhotos = getPhotosURL(photos.reception, 'photo')
      const receptionVideos = getPhotosURL(videos.reception, 'video', this.renderVideo)
      const serviceVideos = getPhotosURL(videos.service, 'video', this.renderVideo)
      const galleries = [
        { name: 'Fotos Recepción', gallery: receptionPhotos, field: 'collapsePhotoReceive' },
        { name: 'Fotos Servicio', gallery: servicePhotos, field: 'collapsePhotoService' },
        { name: 'Videos Recepción', gallery: receptionVideos, field: 'collapseVideoReceive', isVideo: true },
        { name: 'Videos Servicio', gallery: serviceVideos, field: 'collapseVideoService', isVideo: true }
      ]
      return (
        <FlexColumn>
          <TitleContainer>
            <ClientName>Galería</ClientName>
          </TitleContainer>
          <div>
            {galleries.map(galleryItem => {
              const { name, gallery, field, isVideo } = galleryItem
              return gallery && (
                <GalleryTypeContainer>
                  <CollapseButton onClick={() => this.handleCollapse(field)}>
                    {name}
                    <FontAwesomeIcon
                      icon={collapsable[field] ? faChevronDown : faChevronUp }
                      size='1x'
                      style={{ color: 'black' }}
                    />
                  </CollapseButton>
                  <Collapsable isCollapsed={collapsable[field]}>
                    {isVideo
                      ? <div className='video-container'>
                        <ImageGallery
                          items={gallery}
                          showNav={false}
                          showPlayButton={false}
                          showIndex
                          thumbnailPosition='top'
                          showFullscreenButton={!isVideo}
                        />
                      </div>
                      : <ImageGallery
                        items={gallery}
                        showNav={false}
                        showPlayButton={false}
                        showIndex
                        thumbnailPosition='top'
                        showFullscreenButton={!isVideo}
                        className='video-container'
                      />
                    }
                  </Collapsable>
                </GalleryTypeContainer>
              )
            })}
          </div>
        </FlexColumn>
      )
    } return false
  }

  handleCollapse (field) {
    const { collapsable } = this.state
    const objectKeys = Object.keys(collapsable)
    objectKeys.map(key => {
      if (key !== field) {
        collapsable[key] = true
      }
      return true
    })
    collapsable[field] = !collapsable[field]
    this.setState({ collapsable })
  }

  renderVideo (item) {
    const { original } = item
    return (
      <div>
        <VideoPlayer
          controls
          src={original}
        />
        {item.description &&
          <DescriptionContainer>
            {item.description}
          </DescriptionContainer>
        }
      </div>
    )
  }
}

function mapStateToProps ({ Appointments }) {
  return {
    appointment: Appointments.filteredAppointment
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getAppointment: id => dispatch(getAppointment(id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApointmentDetail)
