import { put, take, call, delay, fork, cancel, all } from 'redux-saga/effects'
import { orderByDate } from '../containers/adminAppointments/actions'
import watchMechanics from './mechanics'
import watchConsultants from './consultants'

function fetchAppointmentsSuccess (payload) {
  return {
    type: 'GET_APPOINTMENTS_SUCCESS',
    payload
  }
}

function * fetchAppointments (token, url, orderBy) {
  while (true) {
    try {
      const appoitments = yield call(fetch, url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
      const json = yield appoitments.json()
      const data = orderByDate(json, orderBy)
      yield put(fetchAppointmentsSuccess(data))
    } catch (error) {
      console.log(error)
    }
    yield delay(30000)
  }
}

function * watchAppointments () {
  while (true) {
    const { token, url, orderBy } = yield take('GET_APPOINTMENTS')
    const backgroundTask = yield fork(
      fetchAppointments,
      token,
      url,
      orderBy
    )
    yield take('GET_APPOINTMENTS_OUT')
    yield cancel(backgroundTask)
  }
}

export default function * rootSagas () {
  yield all([
    watchAppointments(),
    watchMechanics(),
    watchConsultants()
  ])
}
