import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getUserFromSession } from './session'

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = getUserFromSession()
    const userHasAccess = (user && user.key)
    return (
      <Route
        {...rest}
        render={props =>
          userHasAccess ? (
            <Component {...props} {...rest} />
          ) : (
            <Redirect
              to={{
                pathname: '/admin'
              }}
            />
          )
        }
      />
    )
  }
  