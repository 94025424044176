import React from 'react'
import PropTypes from 'prop-types'
import { Button } from './styled'

const GeneralButton = ({...props}) => (
  <Button 
    {...props}
  />
)

GeneralButton.prototype = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  background: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string
}

export default GeneralButton