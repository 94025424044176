import styled from 'styled-components'
import {
  adminGray,
  black
} from '../../colorPallette'

export const Container = styled.div`
  background-color: ${adminGray};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 1rem;
  @media (max-width: 500px) {
    justify-content: space-between;
    min-height: 3rem;
  }
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${black};
  padding: 1rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
`

export const MenuButton = styled.button`
  display: none;
  height: 100%;
  width: 3rem;
  @media (max-width: 500px) {
    display: flex;
  }
  align-items: center;
  justify-content: center
`
