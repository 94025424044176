import styled from 'styled-components'

export const MainContainer = styled.div`
  /* width: 100%; */
  height: 90%;
  padding: 1rem;
`

export const Colors = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 10px 5px;
`

export const ColorBox = styled.div`
  width: 8rem;
  height: 1rem;
  display: flex;
`

export const ColorSquare = styled.div`
  width: 1rem;
  height: 1rem;
  background: ${({ color }) => color};
  margin-right: 10px;
`
