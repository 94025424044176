import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Container = styled.div`
  background-color: #F5F5F5;
  padding: 1.5rem 0rem;
  width: 100%;
  height: auto;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
`

export const FormContainer = styled.div`
  display: flex;
  background-color: #FFF;
  width: 95%;
  box-sizing: border-box;
  border: 1px solid #878E94;
  overflow: scroll;
  flex-direction: column;
`

export const Flex = styled.div`
  flex: ${({ size }) => size || 1};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  width: ${({ width }) => width};
  ${({ padding }) => padding && `padding: ${padding}`};
`

export const SelectContainer = styled.div`
  width: 100%;
  padding: 1rem 0px;
`

export const Office = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0px;
`

export const Text = styled.span`
  font-size: 0.8rem;
  width: 70%;
  ${({ paddingB }) => {
    if (paddingB) {
      return `padding-bottom: 5px;`
    }
  }};
  color: ${({ color }) => color ? '#232F88' : '#000'};
  font-weight: ${({ color }) => color ? 'bold' : 'normal'};
`

export const Icon = styled(FontAwesomeIcon).attrs({
  color: '#000'
})`
  font-size: 100%;
  padding: 10px;
`

export const Flexible = styled.div`
  display: flex;
`

export const Heading = styled.h4`
  width: 95%;
  margin: 0;
  padding-bottom: 1rem;
  font-size: 1.1rem;
  a {
    color: #232F88;
  }
`

export const DeleteButton = styled.button`
  cursor: pointer;
  font-size: 15px;
  border: none;
  background-color: #e53935;
  border-radius: 4px;
  margin-left: 1rem;
  &:hover {
    background-color: #c62828;
  }
  &:active {
    outline: none;
  }
`

export const Label = styled.span`
  padding: 10px 0px;
  display: block;
  ::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background-color: #36C541;
  }
`
