import styled from 'styled-components'
import {
  primary,
  primaryHover,
  background,
  white,
  grayLabel
} from '../../colorPallette.json'

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
  background-color: ${background};
  height: 100%;
`

export const Heading = styled.h3`
  text-transform: uppercase;
  font-weight: 500;
  margin: 20px 0px 30px 0px;
`

export const FormContainer = styled.div`
  diplay: flex;
  flex-direction: column;
  background-color: ${white};
  border: 1px solid #000;
  padding: 10px 45px 10px 45px;
  width: 75%;
`

export const SaveButton = styled.button`
  background: ${primary};
  color: ${white};
  border: none;
  outline: none;
  padding: 10px 30px;
  font-weight: 100;
  box-sizing: border-box;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  cursor: pointer;
  &:hover{
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    background: ${primaryHover};
  }
`
export const NewAdminButton = styled.button`
  background: ${primary};
  color: ${white};
  border: none;
  outline: none;
  padding: 10px 20px;
  font-weight: 100;
  box-sizing: border-box;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 14px;
  height: 2.7rem;
  transition: 0.3s;
  cursor: pointer;
  &:hover{
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    background: ${primaryHover};
  }
`
export const Label = styled.label`
  display: block;
  font-size: 12px;
  color: ${grayLabel};
  margin: 30px 0px 10px 0px;
`

export const Row = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0px;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
