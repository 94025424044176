import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  defaultGray,
  primary,
  red,
  white,
  black
} from '../../colorPallette.json'
import { Form, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const AdminContainer = styled.div`
  ${({ isAdmin }) => {
    if (isAdmin) {
      return `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #F5F5F5;
        padding: 1.5rem 0rem;
        height: auto;
      `
    }
  }};
  
`

export const Maincontainer = styled.div`
  width: ${({ isAdmin }) => isAdmin ? '95%' : 'auto'};
  height: ${({ isAdmin }) => isAdmin ? 'none' : '100%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ isAdmin }) => !isAdmin ? '3.5rem' : '2rem'};
  & > footer {
    margin-top: 5rem;
  }
  background-color: #FFF;
  border: 1px solid #878E94;
  height: 100%;
`

export const Title = styled.h1`
  color: ${primary};
  font-size: 1.2rem;
  font-weight: 700;
  margin: 2rem 0;
`

export const Legend = styled.span`
  font-size: .8rem;
  color: ${primary};
`

export const StyledForm = styled(Form)`
  width: 80%;
  display: flex;
  flex-direction: column;
  /* margin: 3rem 0; */
  @media (max-width: 900px) {
    width: 95%;
  }
  @media (max-width: 320px) {
    min-height: 68rem;
  }
`

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
  align-items: flex-end;
  ${({ margin }) => margin && `margin: ${margin}`};
  @media (max-width: 320px) {
    flex-direction: column;
    margin: .5rem 0;
  }
`

export const IntructionsText = styled.span`
  font-size: .7rem;
  margin-bottom: .3rem;
  ${({ margin }) => margin && `margin: ${margin}`};
`

export const Block = styled.div`
  width: ${({ width }) => width};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: ${({ marginTop }) =>  marginTop && '1.5rem'};
  @media (max-width: 750px) {
    margin-top: .2rem;
  }
`

export const TextArea = styled(Field)`
  width: 100%;
  height: 7rem;
  color: ${defaultGray};
  border: 1px solid;
  padding: .5rem;
  outline: none;
  box-sizing: border-box;
  @media (max-width: 750px) {
    height: 6rem;
  }
`

export const Top = styled.div`
  width: 90%;
  margin-bottom: 1rem;
`

export const LinkTo = styled(Link)`
  color: ${primary};
`

export const CheckboxTextContainer = styled.div`
  width: 14.5rem;
  display: flex;
  justify-content: space-between;
  margin-left: .5rem;
`

export const BottomContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ isAdmin }) => {
    if (!isAdmin) {
      return `
        padding: 2rem 0;
        min-height: 8rem;    
      `
    } else {
      return `
        padding: 2rem 0rem;
      `
    }
  }};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ height }) => `height: ${height};`};
  width: 49%;
  & .react-datepicker-wrapper {
    box-sizing: border-box;
    margin-top: 2rem;
    width: 90%;
  }
  & .react-datepicker__input-container, .react-datepicker__input-container > input {
    width: 100%;
    box-sizing: border-box;
  }
  & .react-datepicker__input-container > input {
    padding: 0 1rem;
    height: 2rem;
    box-sizing: border-box;
  }
  & .react-datepicker__time-list-item {
    padding: .7rem !important;
  }
  ${({ forceflexEnd }) => {
        if (forceflexEnd) {
          return `
            justify-content: flex-end;
          `
        }
      }
  }
  @media (max-width: 757px) {
    ${({ flexEnd }) => {
        if (flexEnd) {
          return `
            justify-content: flex-end;
          `
        }
      }
    }
  }
  @media (max-width: 757px) {
    ${({ responsiveHeight }) => {
        if (responsiveHeight) {
          return `
            height: auto;
          `
        }
      }
    }
  }
  @media (max-width: 320px) {
   width: 100%;
   margin: 1rem 0;
  }
`

export const MessageError = styled.div`
  margin-top: 5px;
  min-height: .4rem !important;
  position: relative;
  & > span {
    color: ${red};
    font-size: .7rem;
    position: absolute;
    @media (max-width: 560px) {
      font-size: .6rem;
    }
  }
`

export const LoaderContainer = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100% - 3.5rem);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Input = styled.input`
  width: 100%;
  height: 2rem;
  border: 1px solid #737373;
  outline: none;
  padding: 0 1rem;
  color: #737373;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  ${({ disabled }) => {
    if (disabled) {
      return `
        background-color: #d3d3d3;
        opacity: 0.8;
      `
    }
  }};
`

export class CustomInput extends Component {
  render () {
    const { disabled } = this.props
    const text = this.props.value !== '' ? this.props.value : this.props.placeholder
    return (
      <Input
        type='button'
        value={text}
        onClick={this.props.onClick}
        disabled={disabled}
      />
    )
  }
}

export const Box = styled.div`
  background: ${white};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25rem;
  height: 20rem;
  justify-content: space-around;
  box-shadow: 0px 0px 10px -1px ${black};
  border-radius: .2rem;
  padding: 1rem;
  & span {
    text-align: center;
    color: ${black};
    margin: 1rem 0;
    font-weight: 600;
    letter-spacing: 1px;
    ${({ status }) => (status !==  'success' && status !== 'failed') && `animation: bumping .9s linear infinite`};
    padding: 1rem;
  }
  @keyframes bumping {
    0% {
      transform: scale(.95,.95);
    }
    50% {
      transform: scale(1.05,1.05);
    }
    100% {
      transform: scale(.95, .95) ;
    }
  }
`


export const TopBox = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CenterBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BottomBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 2;
`

export const StyledIcon = styled(FontAwesomeIcon)`
  animation: bump .9s linear 1;
  width: 5rem !important;
  height: 5rem !important;
  @keyframes bump {
    0% {
      transform: scale(.5,.5);
    }
    50% {
      transform: scale(1.05,1.05);
    }
    75% {
      transform: scale(.95,.95);
    }
    100% {
      transform: scale(1,1) ;
    }
  }
`

export const LoadingContainerForAdmin = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.30);
`
