import React from 'react'

const HelloWorld = props => {
  return (
    <div
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'orange'
      }}
    >
      <span>Hello world</span>
    </div>
  )
}

export default HelloWorld
