import React, { Component } from 'react'
import { connect } from 'react-redux'
import Calendar from '../../components/Calendar'
import { Heading } from '../adminConfig/styled'
import { Container, CalendarWrapper } from './styled'
import { formatAppointments } from './actions'

class AppointmentsCalendar extends Component {
  render () {
    const { appointments, history } = this.props
    return (
      <Container>
        <Heading>AGENDA DE CITAS</Heading>
        <CalendarWrapper>
          <Calendar events={appointments} history={history} />
        </CalendarWrapper>
      </Container>
    )
  }
}

function mapStateToProps ({ Appointments }) {
  return {
    appointments: formatAppointments(Appointments.appointments)
  }
}

export default connect(mapStateToProps)(AppointmentsCalendar)
