import styled from 'styled-components'
import {
  defaultGray,
  darkBlue,
  lightGray,
  primary,
  yellow
} from '../../colorPallette.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const MainContainer = styled.div`
  background-color: #F5F5F5;
  padding: 1.5rem 0rem 0rem 0rem;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
`

export const WhiteContainer = styled.div`
  display: flex;
  background-color: #FFF;
  width: 95%;
  box-sizing: border-box;
  border: 1px solid #878E94;
  margin-bottom: 30px;
  flex-direction: column;
`

export const ContentTop = styled.div`
  display: flex;
  height: 5%;
  width: 95%;
  a {
    color: #232F88;
    text-decoration: none;
  }
`

export const ContentBottom = styled.div`
  display: flex;
  height: 95%;
  width: 95%;
  align-items: center;
  justify-content: center;
`

export const Client = styled.div`
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: 560px) {
    width: 95%;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    padding: 1rem;
  }
  @media (max-width: 800px) {
    width: 90%;
  }
`

export const ApointmentNumber = styled.div`
  color: ${primary};
  font-size: 1.5rem;
  margin-bottom: 1rem;
`

export const ApointmentDate = styled.div`
  font-weight: 400;
  color: ${primary};
  font-size: 1.3rem;
  margin-top: .5rem;
  padding-right: 3rem;
`

export const LeftSection = styled.div`
  width: 30%;
  @media (max-width: 450px) {
    width: 100%;
  }
`

export const RightSection = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  @media (max-width: 450px) {
    width: 100%;
  }
`

export const ClientName = styled.span`
  font-size: 1.6rem;
  margin: .2rem 0;
`

export const RightSectionTop = styled.div`
  display: flex;
  flex-direction: column;
`

export const RightSectionBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ big }) => big ? '20rem' : '8rem'};
`

export const Row = styled.div`
  display: flex;
  width: 100%;
`

export const ClientData = styled.div`
  width: 100%;
  display: flex;
`

export const HorizontalTagContainer = styled.div`
  display: flex;
  width: 9rem;
  font-size: .9rem;
  color: ${defaultGray};
  font-weight: 300;
  margin: 1.2rem 1rem 0 0;
  & svg {
    font-size: .9rem;
    margin-right: .7rem;
  }
  @media (max-width: 560px) {
    font-weight: 500;
  }
`

export const VerticalTagContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem .5rem 0 0;
`

export const GenericText = styled.span`
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  color: ${({ color }) => color || darkBlue};
  text-align: ${({ center }) => center ? 'center' : 'left'}
  padding: ${({ padding }) => padding || 0};
  margin-top: ${({ marginTop }) => marginTop || 0};
  @media (max-width: 1050px) {
    padding: 0;
  }
`

export const GenericTextGray = styled.span`
  font-size: .8rem;
  color: ${defaultGray};
  font-weight: 200;
  margin-bottom: .5rem;
  @media (max-width: 430px) {
    font-weight: 400;
  }
`

export const DownloadLink = styled.a`
  color: ${primary};
  margin-top: 2.5rem;
`

export const AddsBar = styled.div`
  width: 100%;
  height: 4rem;
  background: ${lightGray};
  margin: 2.5rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
  text-align: center;
`

export const TopContent = styled.div`
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Icon = styled(FontAwesomeIcon)`
  ${({ rotate }) => `transform: rotate(${rotate});`};
  margin-right: .3rem;
`

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Status = styled.div`
  display: flex;
  flex-direction: column;
  & .rc-steps-item-finish .rc-steps-item-icon {
    border-color: ${lightGray} !important;
    background: ${lightGray} !important;
  }
  & .rc-steps-item-process .rc-steps-item-icon {
    background: ${yellow} !important;
    border-color: ${yellow} !important;
  }
  & .rc-steps-item-process .rc-steps-item-title {
    color: ${yellow} !important;
  }
  & .rc-steps-item-finish .rc-steps-item-tail:after {
    background-color: ${defaultGray};
  }
`

export const StatusText = styled.span`
  color: ${({ color }) => color || defaultGray};
  font-size: 1.3rem;
  margin-bottom: 10px;
`

export const CollapseButton = styled.button`
  font-size: 1.3rem;
  border-width: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  outline: 0;
`

export const GalleryTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`

export const Collapsable = styled.div`
  transition: max-height .5s linear;
  overflow: hidden;
  max-height: 0px;
  ${({ isCollapsed }) => !isCollapsed && 'max-height: 2000px'};
`

export const VideoPlayer = styled.video`
  height: 100%;
  width: 100%;
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitleContainer = styled.div`
  text-align: center;
`

export const DescriptionContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 70px;
  color: #FFF;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
`
