import { combineReducers } from 'redux'
import User from './users.js'
import Cars from './cars.js'
import Appointments from './appointments.js'
import Menu from './menu.js'
import Configuration from './config'
import Workers from './workers'
import Connections from './connections'
import UI from './ui'

const rootReducers = combineReducers({
  User,
  Cars,
  Appointments,
  Menu,
  Configuration,
  Workers,
  Connections,
  UI
})

export default rootReducers
