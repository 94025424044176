import React from 'react'
import {
  Footer
} from '../'
import {
  Container,
  Text,
  TextBold,
  LinkTo
} from './styled'

export const PageNotFound = ({ isPage, isAppointment, id, session }) => {
  if (isPage && !isAppointment) {
    return (
      <Container>
        <TextBold isPage={isPage}>
          Ops! Error 404
        </TextBold>
        <Text isPage={isPage}>
          Lo sentimos, pero la página que busca no existe.
        </Text>
        <LinkTo to='/admin/citas'>
          Regresar a la pagina de inicio.
        </LinkTo>
      </Container>
    )
  }
  if (!isPage && isAppointment) {
    return (
      <Container>
        <TextBold>
          {`No encontramos la cita #${id}`}
        </TextBold>
        <Text>
          Contacta con soporte tecnico o refresca la pagina.
        </Text>
        <LinkTo to='/admin/citas'>
          Regresa a la lista de citas.
        </LinkTo>
      </Container>
    )
  }
  return (
    <>
      <Container>
        <TextBold>
          Uh-Oh!
        </TextBold>
        <Text>
          La pagina que buscas no se encontro.
        </Text>
        <LinkTo to='/'>
          Regresar a la pagina de inicio
        </LinkTo>
      </Container>
      <Footer />
    </>
  )
}

export default PageNotFound
