export const singIn = (username, push) => {
  return dispatch => {
    let user = {
      username,
      type: 'agent'
    }
    if (username.length < 6) {
      dispatch(loginError())
    } else {
      push('agente')
      dispatch(login(user))
    }
  }
}

function login(payload) {
  return {
    type: 'LOGIN',
    payload: payload
  }
}

function loginError() {
  return {
    type: 'ERROR'
  }
}
