import _ from 'lodash'

export const getAppointment = (id) => {
  return (dispatch, getState) => {
    let store = getState()
    let { appointments } = store.Appointments
    let formatedID = parseInt(id)
    let appointment
    if(_.isNaN(formatedID)) {
      appointment = appointments.find(({
        order_number
      }) => order_number === id)
    } else {
      appointment = appointments.find(({
        appointment_id
      }) => appointment_id === formatedID)
    }
    if (!_.isEmpty(appointment)) {
      dispatch(filterAppointmentSuccess(appointment))
    }
    return appointment
  }
}

function filterAppointmentSuccess (payload) {
  return {
    type: 'FILTER_APPOINTMENT_SUCCES',
    payload
  }
}
