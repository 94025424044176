import { put, take, call, delay, fork, cancel } from 'redux-saga/effects'
import { formatURL } from '../containers/adminAppointments/utils'

const URL = process.env.REACT_APP_BASE_URL

function fetchConsultantsSuccess (payload) {
  return {
    type: 'GET_CONSULTANTS_SUCCESS',
    payload
  }
}

function * fetchConsultants (token, q, connection) {
  while (true) {
    try {
      const ENDPOINT = formatURL(`${URL}/workers/`, '', '', '', connection)
      const consultants = yield call(fetch, `${ENDPOINT}&q=${q}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
      const json = yield consultants.json()
      if (Array.isArray(json)) yield put(fetchConsultantsSuccess(json))
    } catch (error) {
      console.log(error)
    }
    yield delay(30000)
  }
}

function * watchConsultants () {
  while (true) {
    const { token, q, connection } = yield take('GET_CONSULTANTS')
    const backgroundTask = yield fork(fetchConsultants, token, q, connection)
    yield take('GET_CONSULTANTS_OUT')
    yield cancel(backgroundTask)
  }
}

export default watchConsultants
