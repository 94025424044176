import React, { Component } from 'react'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import {
  Tabs,
  Loader
} from '../../components'
import {
  MainContainer,
  WhiteContainer
} from './styled'
import { Heading } from '../adminConfig/styled'
import {
  LoadingContainerForAdmin
} from '../makeApointment/styled'
import { getUserFromSession } from '../../utils/session'
import {
  generateRamdomPW
} from '../../utils'
import { registerNewWorkerUser } from './actions'

class AdminPeople extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount () {
    const { dispatch, connection } = this.props
    const user = getUserFromSession()
    const {
      key: token
    } = user
    dispatch({
      type: 'GET_CONSULTANTS',
      token,
      q: 'Consultants',
      connection
    })
    dispatch({
      type: 'GET_MECHANICS',
      token,
      q: 'Mechanics',
      connection
    })
  }
  async handleSubmit (form, actions, type) {
    const {
      email,
      name,
      phone,
      external_id
    } = form
    const {
      registerNewWorkerUser
    } = this.props
    this.setState({
      isLoading: true
    })
    if (typeof registerNewWorkerUser === 'function') {
      const pw = generateRamdomPW()
      const values = {
        email,
        name,
        phone,
        password1: pw,
        password2: pw
      }
      if (type === 'Consultants') {
        values['is_consultant'] = true
        values['consultant_id'] = external_id
      }
      if (type === 'Mechanics') {
        values['is_mechanic'] = true
        values['mechanic_id'] = external_id
      }
      const response = await registerNewWorkerUser(values)
      this.setState({
        isLoading: false
      }, () => {
        if (response.key) {
          swal('Completado', 'se ha creado satisfactoriamente la cuenta.', 'success', {
            timer: 2000,
            button: false,
            closeOnClickOutside: false
          })
        } else {
          if (response && response.email) {
            swal('Uh-Oh!', response.email.pop(), 'error', {
              timer: 4000,
              button: false,
              closeOnClickOutside: false
            })
          } else {
            swal('Oops!', 'Algo salio mal, intentalo de nuevo o más tarde.', 'error', {
              timer: 2000,
              button: false,
              closeOnClickOutside: false
            })
          }
        }
        actions.setSubmitting(false)
        actions.resetForm({
          external_id: '',
          name: '',
          email: '',
          phone: ''
        })
      })
    }
  }
  render () {
    const {
      isLoading
    } = this.state
    return (
      <MainContainer>
        <Heading>ASESORES/MECÁNICOS</Heading>
        <WhiteContainer>
          {
            isLoading &&
            <LoadingContainerForAdmin>
              <Loader />
            </LoadingContainerForAdmin>
          }
          <Tabs
            handleSubmit={this.handleSubmit}
          />
        </WhiteContainer>
      </MainContainer>
    )
  }
  componentWillUnmount () {
    const {
      dispatch
    } = this.props
    dispatch({ type: 'GET_CONSULTANTS_OUT' })
    dispatch({ type: 'GET_MECHANICS_OUT' })
  }
}

function mapDispatchToProps (dispatch) {
  return {
    registerNewWorkerUser: form => dispatch(registerNewWorkerUser(form)),
    dispatch
  }
}

function mapStateToProps ({ Connections: { selected } }) {
  return {
    connection: selected ? selected.connection : null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPeople)
