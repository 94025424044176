import styled from 'styled-components'
import {
  primary,
  white
} from '../../colorPallette.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export const MainContainer = styled.div`
  width: 14rem;
  background: #1E2125;
  border: 1px solid;
  height: 100vh;
  box-sizing: border-box;
  @media (max-width: 500px) {
    position: absolute;
    left: ${({ left }) => left};
    ${({ changeDisplay }) => changeDisplay ? `animation: .6s show 1` : `animation: .6s hide 1`};
    z-index: 10;
  }
  @keyframes show {
    0% {
      left: -14rem;
    }
    100% {
      left: 0;
    }
  }
  @keyframes hide {
    0% {
      left: 0;
    }
    100% {
      left: -14rem;
    }
  }
`

export const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  font-size: .8rem;
  height: 1.8rem;
  color: ${white};
  background: ${({ to, actualpath }) => to === actualpath ? primary : 'transparent'}
  text-decoration: none;
  align-items: center;
  padding-left: 1.5rem;
  margin-top: .5rem;
  box-sizing: border-box;
  transition: .3s;
  &:hover {
    background: ${primary};
  }
`

export const Icon = styled(FontAwesomeIcon).attrs({
  color: white
})`
  font-size: 100%;
  margin-right: .5rem;
`

export const Image = styled.img`
  height: 5rem;
`

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
`

export const CloseButton = styled.button`
  display: none;
  @media (max-width: 500px) {
    display: flex;
    width: 100%;
    font-size: .8rem;
    height: 1.8rem;
    color: ${white};
    align-items: center;
    padding-left: 1.5rem;
    margin-top: .5rem;
    box-sizing: border-box;
    transition: .3s;
    outline: none;
    border: none;
    background: transparent;
    &:hover {
      background: ${primary};
    }
  }
`

export const Collapsable = styled.div`
  display: flex;
  flex-direction: column;
`

export const LocationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`

export const LocationText = styled.span`
  color: ${white};
  font-size: .8rem;
  text-align: center;
  width: 80%;
  font-weight: bold;
`

export const LocationButton = styled.div`
  display: flex;
  width: 100%;
  font-size: .8rem;
  height: 1.8rem;
  color: ${white};
  align-items: center;
  padding-left: 1.5rem;
  margin-top: .5rem;
  box-sizing: border-box;
  transition: .3s;
  cursor: pointer;

  &:hover {
    background: ${primary}
  }
`

export const LocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  max-height: 70px;
  transition: max-height .2s linear;
  overflow: hidden;

  ${({ isCollapsed }) => isCollapsed && `
    max-height: 0px;
  `}
`

export const LocationName = styled.span`
  font-size: .8rem;
  padding: .5rem 0 .5rem .2rem;
  color: ${white};
  cursor: pointer;
  ${({ selected }) => selected && `
    background: ${primary};
    cursor: default;
  `}

  &:hover {
    background: ${primary}
  }
`
