import * as Yup from 'yup'
import {
  getTomorrow
} from './utils'

// ['Servicio de diagnostico', 'Servicio de mantenimiento', 'Servicio de reparación']

export function getPublicSchema (offices) {
  return Yup.object().shape({
    name: Yup.string().required('Campo requerido'),
    lastname: Yup.string().required('Campo requerido'),
    motherLastName: Yup.string().required('Campo requerido'),
    email: Yup.string().email('Email invalido').lowercase('El correo debe estar escrito en minusculas').required('Campo requerido'),
    servicetype: Yup.string()
      .oneOf(
        ['Servicio de diagnostico', 'Servicio de mantenimiento', 'Servicio de reparación'],
        'Opciones validas Diagnostico, Mantenimiento o Reparación'
      ).required('Campo requerido'),
    brand: Yup.string(),
    year: Yup.string()
      .when('vin', (vin, schema) => {
        return (vin === '') ? schema.required() : schema.notRequired()
      }),
    vin: Yup.string().length(17, 'El VIN debe tener un largo de 17 caracteres.')
      .when('year', (year, schema) => {
        return (year === '') ? schema.required() : schema.notRequired()
      }),
    phone: Yup.string()
      .min(10, 'Debe tener al menos 10 digitos')
      .required('Campo requerido'),
    office: Yup.string()
      .required('Campo requerido'),
    model: Yup.string(),
    checkterms: Yup.bool()
      .oneOf([true], 'Campo requerido').required('Campo requerido'),
    appointmentDate: Yup.date()
      .min(
        getTomorrow(true),
        'Las citas solo estan disponibles apartir del dia siguiente al que se esta agendando'
      ).when('office', (office, schema) => {
        return !offices.includes(office) ? schema.notRequired() : schema.required('Campo requerido')
      }),
    appointmentHour: Yup.date()
      .when('office', (office, schema) => {
        return !offices.includes(office) ? schema.notRequired() : schema.required('Campo requerido')
      }),
    car_id: Yup.string()
  },
  ['vin', 'year']
  )
}

export const adminScheme = (offices) => {
  return Yup.object().shape({
    name: Yup.string().required('Campo requerido'),
    lastname: Yup.string().required('Campo requerido'),
    motherLastName: Yup.string().required('Campo requerido'),
    email: Yup.string().email('Email invalido').lowercase('El correo debe estar escrito en minusculas').required('Campo requerido'),
    servicetype: Yup.string()
      .oneOf(
        ['Servicio de diagnostico', 'Servicio de mantenimiento', 'Servicio de reparación'],
        'Opciones validas Diagnostico, Mantenimiento o Reparación'
      ).required('Campo requerido'),
    brand: Yup.string(),
    year: Yup.string()
      .when('vin', (vin, schema) => {
        return (vin === '') ? schema.required() : schema.notRequired()
      }),
    vin: Yup.string().length(17, 'El VIN debe tener un largo de 17 caracteres.')
      .when('year', (year, schema) => {
        return (year === '') ? schema.required() : schema.notRequired()
      }),
    phone: Yup.string()
      .min(10, 'Debe tener al menos 10 digitos')
      .required('Campo requerido'),
    office: Yup.string()
      .required('Campo requerido'),
    model: Yup.string(),
    appointmentDate: Yup.date()
      .min(
        getTomorrow(true),
        'Las citas solo estan disponibles apartir del dia siguiente al que se esta agendando'
      ).when('office', (office, schema) => {
        return !offices.includes(office) ? schema.notRequired() : schema.required()
      }),
    appointmentHour: Yup.date()
      .when('office', (office, schema) => {
        return !offices.includes(office) ? schema.notRequired() : schema.required()
      }),
    car_id: Yup.string()
  },
  ['vin', 'year']
  )
}
