import styled from 'styled-components'
import {
  defaultGray,
  white
} from '../../colorPallette.json'

export const MainContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  background: ${white};
  cursor: pointer;
  -webkit-box-shadow: 2px 3px 5px 0px rgba(50, 50, 50, 0.21);
  -moz-box-shadow:    2px 3px 5px 0px rgba(50, 50, 50, 0.21);
  box-shadow:         2px 3px 5px 0px rgba(50, 50, 50, 0.21);
  transition: all 350ms;
  &:hover {
    -webkit-box-shadow: 2px 3px 11px 0px rgba(50, 50, 50, 0.58);
    -moz-box-shadow:    2px 3px 11px 0px rgba(50, 50, 50, 0.58);
    box-shadow:         2px 3px 11px 0px rgba(50, 50, 50, 0.58);
  }
`

export const LeftContent = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  /* align-items: flex-end; */
  justify-content: flex-end;
  /* background-color:  red; */
`

export const RightContent = styled.div`
  display: flex;
  /* flex: 2; */
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding: 0.5rem;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${({ justify }) => justify}
`

export const StyledText = styled.span`
  font-weight: ${({ fontWeight }) => fontWeight || 100};
  color: ${({ color }) => color || defaultGray};
  text-align: ${({ textalign }) => textalign || 'left'}
  margin: ${({ margin }) => margin}
`

export const DateContainer = styled.div`
  display: flex;
  padding: 0;
  justify-content: flex-end;
`
