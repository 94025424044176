import _ from 'lodash'
import { getUserFromSession } from '../../utils/session'

const URL = process.env.REACT_APP_BASE_URL

export const GetAppointments = () => {
  return (dispatch, getState) => {
    let { key } = getUserFromSession()
    fetch(`${URL}/appointments-history/`,
      {
        headers: {
          Authorization: `Token ${key}`
        }
      })
      .then(response => response.json())
      .then(response => {
        let appointments = response.results
        let { Appointments: { orderBy } } = getState()
        let formated = orderByDate(appointments, orderBy)
        dispatch(getAppointmentsSucces(formated))
      })
  }
}

export const ChangeOrder = (order) => {
  return dispatch => {
    dispatch(orderBySuccess(order))
  }
}

export function orderByDate (appointments, order) {
  const formated = _.orderBy(appointments, ['id'], [order])
  return formated
}

export const GetAppointmentsFiltered = (status, serviceType, search) => {
  return (dispatch, getState) => {
    let { key } = getUserFromSession()
    fetch(`${URL}/appointments-history/?status__in=${status}&service_type__in=${serviceType}&search=${search}`,
      {
        headers: {
          Authorization: `Token ${key}`
        }
      })
      .then(response => response.json())
      .then(response => {
        console.log('RESPONSE >>>', response)
        let appointments = response.results
        let { Appointments: { orderBy } } = getState()
        let formated = orderByDate(appointments, orderBy)
        dispatch(getAppointmentsSucces(formated))
      })
  }
}

function getAppointmentsSucces (payload) {
  return {
    payload,
    type: 'GET_APPOINTMENT_SUCCES'
  }
}

function orderBySuccess (payload) {
  return {
    payload,
    type: 'ORDER_BY_SUCCESS'
  }
}