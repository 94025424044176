import React from 'react'
import PropTypes from 'prop-types'
import {
  Label,
  CheckboxContainer,
  Container,
  CustomCheckbox,
  CheckMark
} from './styled'
import { Field } from 'formik'

const generateId = name => {
  const id = typeof name === 'string' ? name.substring(0, 3) : name
  return id
}

const Checkbox = ({ values, children, name, onChange, margin, value, names, checkeds, ...props }) => {
  return (
    <Container margin={margin}>
      {typeof values === 'string' || typeof values === 'number'
        ? (
          <CheckboxContainer
          >
            <CustomCheckbox>
              <Field
                onChange={onChange}
                component='input'
                type='checkbox'
                id={name}
                name={name}
                checked={value}
                {...props}
              />
              <CheckMark />
              <div>
                {children || (<Label htmlFor={`checkbox_${generateId(values)}`}>{values}</Label>)}
              </div>
            </CustomCheckbox>
          </CheckboxContainer>)
        : values && values.map((option, index) => {
          if (typeof option === 'object') {
            const { name, value } = option
            return (
              <CheckboxContainer
                key={index}
              >
                <CustomCheckbox>
                  <Field
                    onChange={onChange}
                    component='input'
                    type='checkbox'
                    id={`${value}`}
                    name={`${value}`}
                    value={value}
                    checked={checkeds && checkeds[index]}
                    {...props}
                  />
                  <CheckMark />
                  <Label htmlFor={`${value}`}>{name}</Label>
                </CustomCheckbox>
              </CheckboxContainer>
            )
          }
          return (
            <CheckboxContainer
              key={index}
            >
              <CustomCheckbox>
                <Field
                  onChange={onChange}
                  component='input'
                  type='checkbox'
                  id={`${option}`}
                  name={(names) ? names[index] : option}
                  value={option}
                  checked={(checkeds) && checkeds[index]}
                  {...props}
                />
                <CheckMark />
                <Label htmlFor={`${option}`}>{option}</Label>
              </CustomCheckbox>
            </CheckboxContainer>
          )
        })}
    </Container>
  )
}

Checkbox.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.element,
  margin: PropTypes.string
}

export default Checkbox
