import _ from 'lodash'

const SMS_TOKEN_SECRET = process.env.REACT_APP_SMS_TOKEN_SECRET
const SMS_TOKEN_ID = process.env.REACT_APP_SMS_TOKEN_ID
const SMS_API_URL = process.env.REACT_APP_SMS_API_URL
const AWS_S3_URL = process.env.REACT_APP_AWS_S3_URL
const token64 = window.btoa(`${SMS_TOKEN_ID}:${SMS_TOKEN_SECRET}`)

export async function sendSMS (from, to, body, params) {
  try {
    const response = await fetch(SMS_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'aplication/json',
        'Authorization': `Basic ${token64}`
      },
      body: JSON.stringify({
        from,
        to,
        body,
        ...params
      })
    })
    const data = await response.json()
    if (data && (data.type === 'SENT')) {
      return [data, null]
    } else {
      return [null, data]
    }
  } catch (error) {
    console.log(error)
    return error
  }
}

export const generateRamdomPW = () => {
  return Math.random().toString(36).substr(2, 8)
}

export const validateGallery = (photos, videos) => {
  if (!_.isEmpty(photos) || !_.isEmpty(videos)) {
    const photosValues = Object.values(photos)
    const validPhotos = photosValues.filter(photoArray => photoArray.length > 0)
    const videoValues = Object.values(videos)
    const validVideos = videoValues.filter(videoArray => videoArray.length > 0)
    if (!_.isEmpty(validPhotos) || !_.isEmpty(validVideos)) {
      return true
    }
    return false
  }
  return false
}

export const getPhotosURL = (photos, type, renderItem = undefined) => {
  if (photos.length > 0) {
    const deepPhotos = [...JSON.parse(JSON.stringify(photos))].filter(photo => photo.in_s3)
    const photosURL = deepPhotos.map(photo => {
      photo['original'] = `${AWS_S3_URL}${type === 'photo' ? photo.image.split('/media')[1] : `/${photo.video}`}`
      const fixedThumbnail = type === 'photo' ? AWS_S3_URL + photo.thumbnail.split('/media')[1] : `${AWS_S3_URL}/${photo.thumbnail}`
      photo['thumbnail'] = fixedThumbnail
      photo['description'] = photo.comments || ''
      if (renderItem) {
        photo['renderItem'] = renderItem
      }
      return photo
    })
    return photosURL
  }
  return false
}
