import styled from 'styled-components'
import {
  defaultGray,
  darkBlue,
  lightGray,
  primary
} from '../../colorPallette.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const MainContainer = styled.div`
  padding-top: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1280px) and (min-height: 850px) {
    min-height: 100vh;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Client = styled.div`
  padding: 2rem;
  margin-top: 2rem;
  display: flex;
  width: 60%;
  border: 1px solid;
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 560px) {
    width: 95%;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    padding: 1rem;
  }
  @media (max-width: 800px) {
    width: 90%;
  }
`

export const ApointmentNumber = styled.div`
  color: ${primary};
  font-size: 1.5rem;
  margin-bottom: 1rem;
`

export const ApointmentDate = styled.div`
  font-weight: 400;
  color: ${primary};
  font-size: 1.5rem;
  margin-top: .5rem;
  padding-right: 5rem;
`

export const LeftSection = styled.div`
  width: 30%;
  @media (max-width: 450px) {
    width: 100%;
  }
`

export const RightSection = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  @media (max-width: 450px) {
    width: 100%;
  }
`

export const ClientName = styled.span`
  font-size: 1.6rem;
  margin: .2rem 0;
`

export const RightSectionTop = styled.div`
  display: flex;
  flex-direction: column;
`

export const RightSectionBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ big }) => big ? '20rem' : '8rem'};
`

export const Row = styled.div`
  display: flex;
  width: 100%;
`

export const ClientData = styled.div`
  width: 100%;
  display: flex;
`

export const HorizontalTagContainer = styled.div`
  display: flex;
  width: 9rem;
  font-size: .9rem;
  color: ${defaultGray};
  font-weight: 300;
  margin: 1.2rem 1rem 0 0;
  & svg {
    font-size: .9rem;
    margin-right: .7rem;
  }
  @media (max-width: 560px) {
    font-weight: 500;
  }
`

export const VerticalTagContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem .5rem 0 0;
`

export const GenericText = styled.span`
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  color: ${({ color }) => color || darkBlue};
  text-align: ${({ center }) => center ? 'center' : 'left'}
  padding: ${({ padding }) => padding || 0};
  margin-top: ${({ marginTop }) => marginTop || 0};
  @media (max-width: 1050px) {
    padding: 0;
  }
`

export const GenericTextGray = styled.span`
  font-size: .8rem;
  color: ${defaultGray};
  font-weight: 200;
  margin-bottom: .5rem;
  @media (max-width: 430px) {
    font-weight: 400;
  }
`

export const DownloadLink = styled.a`
  color: ${primary};
  margin-top: 2.5rem;
`

export const AddsBar = styled.div`
  width: 100%;
  height: 4rem;
  background: ${lightGray};
  margin: 2.5rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
  text-align: center;
`

export const TopContent = styled.div`
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`

export const AppContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 800px) {
    width: 90%;
  }
`

export const AppImage = styled.img`
  width: 70%;
`

export const Icon = styled(FontAwesomeIcon).attrs({
  color: primary,
  icon: ['fas', 'phone']
})`
  margin-right: .3rem;
`