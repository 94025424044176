import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  Checkbox,
  GeneralInput,
  InputSelect
} from '../../components'
import logoWhite from '../../assets/img/Logowhite.png'
import logoBlue from '../../assets/img/LogoBlue.png'
import {
  Container,
  ImageContainer,
  Logo,
  FormContainer,
  PaddingContainer,
  FlexRow,
  Title,
  // ForgotPassword,
  Text,
  Button,
  InputContainer,
  BlueLogo,
  ErrorSpan,
  FormTopError
} from './styled'
import { Formik } from 'formik'
import { schema } from './schema'
import { MessageError } from '../makeApointment/styled'
import { setSession } from '../../utils/session'
import {
  loginWithEmailAndPassword,
  retrieveUserInfo,
  getUserType,
  getOfficesConnections
} from './actions'
import { getCleanError } from './utils'

class AdminLogin extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rememberMe: false,
      showLogo: true,
      error: ''
    }
    this.handleSubmitByKey = null
    this.onChangeCheck = this.onChangeCheck.bind(this)
    this.onChangeText = this.onChangeText.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.keyListener = this.keyListener.bind(this)
    this.getConnections = this.getConnections.bind(this)
    this.selectConnection = this.selectConnection.bind(this)
  }
  keyListener (event) {
    if (event.keyCode === 13) {
      if (
        this.formikRef &&
        typeof this.formikRef === 'object'
      ) {
        const { handleSubmit } = this.formikRef
        handleSubmit()
      }
    }
  }
  componentDidMount () {
    const { dispatch } = this.props
    dispatch(getOfficesConnections())
    window.addEventListener('keydown', this.keyListener)
    var user = window.localStorage.getItem('rememberMe')
    if (user) user = JSON.parse(user)
    this.changeLogoVisibility()
    window.addEventListener('resize', this.changeLogoVisibility.bind(this))
    if (user && user.rememberMe) {
      this.setState({
        rememberMe: user.rememberMe
      })
    }
  }
  componentWillUnmount () {
    window.removeEventListener('keydown', this.keyListener)
  }
  initialValues () {
    var user = window.localStorage.getItem('rememberMe')
    var connection = null
    if (user) user = JSON.parse(user)
    if (user && user.connection) connection = user.connection
    if (user && user.email) {
      return {
        email: user.email,
        password: '',
        connection: connection || ''
      }
    }
    return { email: '', password: '', connection: '' }
  }
  async onSubmit (values, actions) {
    const { history, dispatch, connections } = this.props
    const { rememberMe } = this.state
    let { email, connection } = values
    const getConnection = connections.filter(conn => conn.name === connection)
    try {
      const userType = await getUserType(email)
      const {
        is_staff: isStaff,
        is_superuser: isSuperuser
      } = userType
      if (isStaff) {
        const [credentials, error] = await loginWithEmailAndPassword(values)
        const cleanError = error && getCleanError(error)
        this.setState(prevState => {
          if (cleanError) {
            return {
              ...prevState,
              error: cleanError
            }
          }
          return {
            ...prevState,
            error: ''
          }
        }, () => {
          actions.setTouched(false)
          actions.setSubmitting(false)
          actions.setValues(this.initialValues())
          dispatch({
            type: 'SELECT_CONNECTION',
            payload: getConnection.shift()
          })
        })
        if (!error) {
          const [user] = await retrieveUserInfo(credentials)
          user['is_superuser'] = isSuperuser
          user['is_staff'] = isStaff
          setSession({ ...user, ...credentials })
          dispatch({ type: 'GET_USER_FROM_LOGIN', payload: { ...user, ...credentials } })
          history.push('/admin/citas')
        }
        if (rememberMe && !error) {
          window.localStorage.setItem('rememberMe', JSON.stringify({
            email: values.email,
            rememberMe,
            connection
          }))
        }
        if (!rememberMe) {
          window.localStorage.removeItem('rememberMe')
        }
      } else {
        if (_.isEmpty(userType)) {
          this.setState(prevState => {
            return {
              ...prevState,
              error: 'Por el momento no podemos acceder al servidor'
            }
          }, () => {
            actions.setTouched(false)
            actions.setSubmitting(false)
            actions.setValues(this.initialValues())
          })
        } else {
          this.setState(prevState => {
            return {
              ...prevState,
              error: 'El correo electrónico o la contraseña son incorrectos.'
            }
          }, () => {
            actions.setTouched(false)
            actions.setSubmitting(false)
            actions.setValues(this.initialValues())
          })
        }
      }
    } catch (error) {
      console.log('Error >>>>', error)
    }
  }
  getConnections () {
    const { connections } = this.props
    return connections.map(connection => connection.name)
  }
  selectConnection (event, handleChange) {
    const { value } = event.target
    const { dispatch, connections } = this.props
    const getConnection = connections.filter(conn => conn.name === value)
    if (!_.isEmpty(getConnection)) {
      dispatch({
        type: 'SELECT_CONNECTION',
        payload: getConnection.shift()
      })
    }
    handleChange(event)
  }
  render () {
    const { showLogo, error, rememberMe } = this.state
    return (
      <Container>
        <ImageContainer>
          <Logo src={logoWhite} />
          <BlueLogo src={logoBlue} showLogo={showLogo} />
        </ImageContainer>
        <Formik
          initialValues={this.initialValues()}
          validationSchema={schema}
          onSubmit={this.onSubmit}
          ref={ref => { this.formikRef = ref }}
        >
          {
            ({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting
            }) => {
              return (
                <FormContainer>
                  <PaddingContainer>
                    <Title>INICIAR SESIÓN</Title>
                    <Text>Ingrese su correo electrónico y contraseña</Text>
                    <FormTopError>
                      {error && <ErrorSpan>{error}</ErrorSpan>}
                    </FormTopError>
                    <InputContainer>
                      <GeneralInput
                        placeholder='Correo electrónico'
                        height='1.9rem'
                        onChange={handleChange}
                        name='email'
                        id='email'
                        autoComplete='off'
                        autoCapitalize='none'
                        value={values.email}
                        hasError={errors.email && touched.email}
                      />
                      <MessageError>
                        {errors && errors.email && touched.email && <ErrorSpan>{errors.email}</ErrorSpan>}
                      </MessageError>
                    </InputContainer>
                    <InputContainer>
                      <GeneralInput
                        placeholder='Contraseña'
                        height='1.9rem'
                        onChange={handleChange}
                        id='password'
                        type='password'
                        name='password'
                        value={values.password}
                        hasError={errors.password && touched.password}
                      />
                      <MessageError>
                        {errors && errors.password && touched.password && <ErrorSpan>{errors.password}</ErrorSpan>}
                      </MessageError>
                    </InputContainer>
                    <InputContainer>
                      <InputSelect
                        margin='7px 0 0 0'
                        options={this.getConnections()}
                        placeholder='Selecciona una sucursal'
                        height='2rem'
                        width='100%'
                        name='connection'
                        id='connection'
                        disabled={_.isEmpty(this.getConnections())}
                        onChange={event => this.selectConnection(event, handleChange)}
                      />
                      <MessageError>
                        {errors.connection && touched.connection && <span>{errors.connection}</span>}
                      </MessageError>
                    </InputContainer>
                    <FlexRow>
                      <Checkbox
                        margin='0'
                        values='Recordarme'
                        value={rememberMe}
                        onChange={this.onChangeCheck}
                      />
                      {/* <ForgotPassword>Olvidé mi contraseña</ForgotPassword> */}
                    </FlexRow>
                  </PaddingContainer>
                  <Button hasLoading disabled={!isValid} onClick={handleSubmit}>
                    {
                      isSubmitting ? <div className='login_loader' /> : 'INGRESAR'
                    }
                  </Button>
                </FormContainer>
              )
            }
          }
        </Formik>
      </Container>
    )
  }
  changeLogoVisibility () {
    if (window.innerHeight < 500) {
      this.setState({ showLogo: false })
    } else {
      this.setState({ showLogo: true })
    }
  }
  onChangeText (event) {
    const name = event.target.name
    const value = event.target.value
    this.setState({ [name]: value })
  }
  onChangeCheck (event) {
    const rememberMe = event.target.checked
    this.setState({ rememberMe })
  }
}

function mapStateToProps (state) {
  return {
    connections: state.Connections.connections
  }
}

export default connect(mapStateToProps, null)(AdminLogin)
