import { createStore, applyMiddleware, compose } from 'redux'
import rootReducers from '../reducers'
import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import rootSagas from '../sagas'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
const DEV = process.env.NODE_ENV === 'development'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['Configuration']
}

const sagaMiddleware = createSagaMiddleware()

const persistedReducer = persistReducer(persistConfig, rootReducers)
const composed = DEV ? compose(
  applyMiddleware(thunkMiddleware, logger, sagaMiddleware)
) : compose(
  applyMiddleware(thunkMiddleware, sagaMiddleware)
)

export default (() => {
  const store = createStore(
    persistedReducer,
    composed
  )
  const persistor = persistStore(store)
  // persistor.purge()
  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('../reducers').default
      store.replaceReducer(
        persistedReducer(persistConfig, nextRootReducer)
      )
    })
  }
  return { persistor, store }
})()

sagaMiddleware.run(rootSagas)
