import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import swal from '@sweetalert/with-react'
import {
  Container,
  StyledLink,
  MenuButton
} from './styled'
import { onLogout } from './actions'
import { OpenMenu } from '../../actions/'

const AdminHeader = ({ dispatch, history, ...props }) => {
  const logout = () => {
    swal('¿Deseas salir de la sesión actual?', {
      dangerMode: true,
      buttons: ['Cancelar', 'Salir']
    })
      .then(value => {
        if (value) {
          dispatch(onLogout(history))
          // props.history.replace('/')
        }
      })
  }
  const open = () => {
    dispatch(OpenMenu())
  }
  return (
    <Container>
      <MenuButton onClick={open}>
        <FontAwesomeIcon
          icon={['fas', 'bars']}
          size='lg'
        />
      </MenuButton>
      <StyledLink
        onClick={logout}
      >
        CERRAR SESIÓN
      </StyledLink>
    </Container>
  )
}

export default connect()(AdminHeader)
