import { put, take, call, delay, fork, cancel } from 'redux-saga/effects'
import { formatURL } from '../containers/adminAppointments/utils'

const URL = process.env.REACT_APP_BASE_URL

function fetchMechanicsSuccess (payload) {
  return {
    type: 'GET_MECHANICS_SUCCESS',
    payload
  }
}

function * fetchMechanics (token, q, connection) {
  while (true) {
    try {
      const ENDPOINT = formatURL(`${URL}/workers/`, '', '', '', connection)
      const mechanics = yield call(fetch, `${ENDPOINT}&q=${q}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
      const json = yield mechanics.json()
      if (Array.isArray(json)) yield put(fetchMechanicsSuccess(json))
    } catch (error) {
      console.log(error)
    }
    yield delay(30000)
  }
}

function * watchMechanics () {
  while (true) {
    const { token, q, connection } = yield take('GET_MECHANICS')
    const backgroundTask = yield fork(fetchMechanics, token, q, connection)
    yield take('GET_MECHANICS_OUT')
    yield cancel(backgroundTask)
  }
}

export default watchMechanics
