import React from 'react'
import ReactDOM from 'react-dom'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'
import {
  faCalendarAlt,
  faCalendarPlus,
  faCalendarCheck,
  faCheckCircle,
  faTimesCircle,
  faCircle,
  faClock,
  faTrashAlt,
  faDotCircle
} from '@fortawesome/free-regular-svg-icons'
import {
  faMobileAlt,
  faCar,
  faArrowRight,
  faHome,
  faTasks,
  faPhone,
  faEnvelope,
  faCog,
  faUser,
  faArrowCircleDown,
  faSearch,
  faChevronLeft,
  faBars,
  faTrash,
  faMapMarkerAlt,
  faChevronRight,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons'

// Add icons to the library
library.add(
  faFacebookF,
  faTwitter,
  faInstagram,
  faCalendarAlt,
  faMobileAlt,
  faCar,
  faArrowRight,
  faHome,
  faTasks,
  faPhone,
  faEnvelope,
  faCalendarCheck,
  faCalendarPlus,
  faCog,
  faUser,
  faArrowCircleDown,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faChevronLeft,
  faCircle,
  faBars,
  faClock,
  faTrashAlt,
  faTrash,
  faDotCircle,
  faMapMarkerAlt,
  faChevronRight,
  faChevronDown
)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
