import moment from 'moment'

export const getTomorrow = (yup) => {
  var tomorrow
  if (yup) {
    tomorrow = moment()
  } else {
    tomorrow = moment().add(1,'days')
  }
  if (tomorrow.day() === 0) tomorrow = tomorrow.add(1, 'day').format()
  return new Date(tomorrow)
}

export const reGex = /(Cárdenas|Comalcalco|Palenque)/

export function calculateSundays () {
  let start = moment()
  let end = moment(new Date(2039, 1, 1))
  let day = 0
  let result = []
  let current = start.clone()

  while (current.day(7 + day).isBefore(end)) {
    result.push(new Date(current.clone()))
  }
  return result
}

export function getTime (isMin) {
  var day = new Date()
  day.setMinutes(0)
  if (isMin) {
    return new Date(day.setHours(9))
  }
  return new Date(day.setHours(17))
}

export function excludeHours () {
  var hour = new Date()
  var hour2 = new Date()
  var hours = []
  hour.setMinutes(30)
  hour.setHours(13)
  hours.push(hour)
  hour2.setHours(13)
  hour2.setMinutes(45)
  hours.push(hour2)
  return hours
}