import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider, connect } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configStore from './store'
import { PublicRoutes, AdminRoutes } from './routes'
import { MainContainer, Loader } from './components'
import {
  LoadingContainerForAdmin
} from './containers/makeApointment/styled'
import { createGlobalStyle } from 'styled-components'
import { HelloWorld } from './containers'
const { store, persistor } = configStore

export const STORE = store

const GlobalStyle = createGlobalStyle`
  html {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 32px;
    @media (max-width: 2560px) {
      font-size: 26px;
    }
    @media (max-width: 2048px) {
      font-size: 22px;
    }
    @media (max-width: 1600px) {
      font-size: 20px;
    }
    @media (max-width: 1400px) {
      font-size: 18px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
    }
    @media (max-width: 750px) {
      font-size: 14px;
    }
    @media (max-width: 500px) {
      font-size: 13px;
    }
  }

  body {
    margin: 0;
  }
`

function Activity({ show }) {
  if (show) {
    return (
      <LoadingContainerForAdmin>
        <Loader />
      </LoadingContainerForAdmin>
    )
  }
  return null
}

function mapStateToProps({ UI }) {
  return {
    show: UI.activityIndicator
  }
}

const ActivityIndicator = connect(mapStateToProps, null)(Activity)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <MainContainer>
              <GlobalStyle />
              <Switch>
                <Route path='/ruta/rutas/saludo' component={HelloWorld} />
                <Route path='/admin' component={AdminRoutes} />
                <Route path='/' component={PublicRoutes} />
              </Switch>
              <ActivityIndicator />
            </MainContainer>
          </Router>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
