import styled from 'styled-components'

export const Background = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(1, 1, 1, 0.2);
  position: fixed;
  top: 0;
`

export const Window = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 45px 35px;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.25);
`

export const Title = styled.h3`
  text-transform: uppercase;
  font-family: Helvetica, Arial, sans-serif;
  color: #656565;
  font-size: 17px;
  text-align: center;
`

export const Description = styled.p`
  &:first-letter {
    text-transform: uppercase;
  }
  font-family: Helvetica, Arial, sans-serif;
  color: #656565;
  font-size: 14px;
  font-weight: 100;
  margin-top: 20px !important;
  text-align: center;
`
