import styled from 'styled-components'
import {
  white,
  defaultGray,
  lightGray
} from '../../colorPallette.json'

export const Container = styled.div`
  background-color: #F5F5F5;
  padding-top: 1.5rem;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`

export const BottomRow = styled.div`
  display: flex;
  width: 95%;
  height: 90%;
`

export const LeftContent = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const RightContent = styled.section`
  flex: 3;
  height: 100%;
  padding: 0 2rem;
`

export const TopRow = styled.div`
  height: 4rem;
`

export const Filters = styled.div`
  background: ${white};
  display: flex;
  flex-direction: column;
  border: 1px solid ${defaultGray};
  @media (max-width: 375px) {
    overflow-y: auto;
  }
`

export const TitleTextRow = styled.div`
  height: 3.5rem;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

export const TextRow = styled.div`
  height: 1rem;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: ${lightGray};
  font-weight: 200;
`

export const FilterRows = styled.div`
  flex: 1;
`

export const Title = styled.h2`
  font-weight: 700;
  width: 100%;
`
