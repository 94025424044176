import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  white,
  primary
} from '../../colorPallette.json'

export const Container = styled.div`
  padding: 10rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    padding: 4rem 1rem;
  }
`

export const TextBold = styled.div`
  font-size: ${({ isPage }) => isPage ? '4rem' : '2rem'};
  color: ${primary};
  font-weight: 600;
  /* margin-bottom: 1rem; */
`

export const Text = styled.div`
  font-size: ${({ isPage }) => isPage ? '1.5rem' : '1rem'};
`

export const LinkTo = styled(Link)`
  margin-top: 1rem;
  width: 15rem;
  height: 1.5rem;
  background: ${primary};
  text-decoration: none;
  color: ${white};
  text-align: center;
  padding-top: .3rem;
  transition: .3s;
`
