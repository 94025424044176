import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import styled from 'styled-components'

export const Flex = styled.div`
  display: flex;
  flex: ${({ size }) => size || 1};
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
`

export const ListContainer = styled.div`
  height: 480px;
  overflow: scroll;
  width: 100%;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 60%;
`

export const InputWrapper = styled.div`
  margin-bottom: 20px;
`

export const Label = styled.label`
  font-size: 0.7rem;
  font-weight: bold;
`

const Info = ({ label, value, size = 1 }) => {
  return (
    <div style={{ paddingLeft: 10, flex: size }}>
      <label style={{ fontSize: 12, fontWeight: 'bold' }}>{label}</label>
      <p style={{ margin: 0, fontWeight: '300' }}>{value}</p>
    </div>
  )
}

const iconStyles = {
  position: 'absolute',
  right: 10,
  top: 10,
  fontSize: 15
}

const Top = styled.div`
  display: flex;
  flex: 1;
`

const Bottom = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-bottom: 5px;
  position: relative;
`

export const Worker = ({ info, selected, onSelect, index }) => {
  const { external_id, name, email, phone, id } = info
  return (
    <Box
      onClick={() => onSelect(index, info)}
      className='people__shadow'
    >
      {
        (selected === id) &&
        <FontAwesomeIcon
          icon={faCheckCircle}
          color='green'
          style={iconStyles}
        />
      }
      <Top>
        <Info size={1.5} label='Nombre' value={name} />
        <Info label='ATA ID' value={external_id} />
      </Top>
      <Bottom>
        {email && <Info size={1.5} label='Correo electronico' value={email} />}
        {phone && <Info label='Teléfono' value={phone} />}
      </Bottom>
    </Box>
  )
}
