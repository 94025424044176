const initialState = {
  consultants: [],
  mechanics: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_CONSULTANTS_SUCCESS':
      return {
        ...state,
        consultants: payload
      }
    case 'GET_MECHANICS_SUCCESS':
      return {
        ...state,
        mechanics: payload
      }
    default:
      return state
  }
}
