import swal from 'sweetalert'
const URL = process.env.REACT_APP_BASE_URL

export const updateUser = (username, email) => {
  return async (dispatch, getState) => {
    const {
      User,
      User: {
        key
      }
    } = getState()
    try {
      let response = await fetch(`${URL}/rest-auth/user/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${key}`
        },
        body: JSON.stringify({
          username,
          email
        })
      })
      let result = await response.json()
      dispatch({
        type: 'GET_USER_FROM_LOGIN',
        payload: {
          ...User,
          ...result
        }
      })
      result.hasOwnProperty('detail') && swal('Ocurrió un error', 'Algo salió mal, por favor vuelva a intentarlo.', 'error')
    } catch (error) {
      swal('Ocurrió un error', 'Algo salió mal, por favor vuelva a intentar.', 'error')
    }
  }
}

export const changePassword = async (password1, password2, key) => {
  try {
    let response = await fetch(`${URL}/rest-auth/password/change/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${key}`
      },
      body: JSON.stringify({
        new_password1: password1,
        new_password2: password2
      })
    })
    let result = await response.json()
    result.hasOwnProperty('detail') && swal('Ocurrió un error', result.detail, 'error')
    result.hasOwnProperty('new_password2') && swal('Ocurrió un error', result['new_password2'][0], 'error')
    if (result.hasOwnProperty('detail') && result.detail === "La nueva contraseña ha sido guardada.") {
      return swal(result.detail, '','success')
    }
  } catch (error) {
    swal('Ocurrió un error', 'Algo salió mal, por favor vuelva a intentar.', 'error')
  }
}

export const adminRegister = async (data, key) => {
  try {
    let response = await fetch(`${URL}/admin-register/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${key}`
      },
      body: JSON.stringify(data)
    })
    let userData = await response.json()
    swal('Usuario creado satisfactoriamente', '','success')
    return userData
  } catch (err) {
    swal('Ocurrió un error', 'Algo salió mal, por favor vuelva a intentar.', 'error')
  }
}
