import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Input,
  Icon,
  Button
} from './styled'

const SearchInput = ({ onChange, onClick, placeholder, style }) => {
  return (
    <Container style={style}>
      <Button onClick={onClick}>
        <Icon />
      </Button>
      <Input id='searchBox' onChange={onChange} type='search' placeholder={placeholder} />
    </Container>
  )
}

SearchInput.propTypes = {
  onClick: PropTypes.func
}

export default SearchInput
