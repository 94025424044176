import styled from 'styled-components'
import {
  grayLabel,
  defaultGray,
  white,
  primary
} from '../../colorPallette.json'

export const Container = styled.div`
  display: flex;
  margin: 1rem;
  flex-direction: column;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  height: 17px;
  margin-bottom: 12px;
`

export const Label = styled.label`
  margin-left: 10px;
  margin-right: 15px;
  &:first-letter {
    text-transform: uppercase;
  }
  color: ${grayLabel};
`

export const CustomCheckbox = styled.label`
  display: flex;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  user-select: none;
  height: 100%
  align-items: center;
  
  & div {
    margin-left: 5px;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & input:checked ~ span {
    background-color: ${primary};
    transition: .3s;
  }

  & input:checked ~ span:after {
    display: block;
  }

  & span:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid ${white};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`

export const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: ${white}
  border: 1px solid ${defaultGray};

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`
