import React, { Component } from 'react'
import { GeneralButton, Footer } from '../../components'
import {
  Title,
  Container,
  HomeTop,
  TopImage,
  ScheduleBox,
  SlimText,
  FlexibleBox,
  Text,
  IconBox,
  MiddleTopLeftContainer,
  MiddleTopMessage,
  MiddleTopContainer,
  MiddleBottomContainer,
  MiddleIconsContainer,
  BottomContainer,
  Icon,
  BrandsContainer,
  MiddleLeftBottom,
  ScheduleText,
  ArrowDown,
  Button,
  Image,
  ArrowDownContainer
} from './styled'
import Assets from '../../assets/img'
import app from '../../assets/img/app_home.png'
import { connect } from 'react-redux'
import {
  getOfficesConnections
} from '../adminLogin/actions'

class Home extends Component {
  constructor() {
    super ()
    this.state = {
      scrolled: false
    }
    this.onClick = this.onClick.bind(this)
  }
  onClick () {
    document.querySelector('.info').scrollIntoView(
      { 
      behavior: 'smooth' 
      }
    )
      this.setState({ scrolled: true })
  }
  componentDidMount () {
    const { dispatch } = this.props
    dispatch(getOfficesConnections())
  }
  render () {
    const { history: {push} } = this.props
    const { scrolled } = this.state
    return (
      <Container>
        <HomeTop>
          <ScheduleBox>
            <Title>¿TU AUTO REQUIERE MANTENIMIENTO O REPARACIÓN?</Title>
            <ScheduleText>
              Agenda una cita y nosotros te brindaremos el servicio que necesitas.
            </ScheduleText>
            <GeneralButton onClick={() => push('/agendar-cita')}>
              <SlimText>SOLICITAR CITA</SlimText>
            </GeneralButton>
          </ScheduleBox>
          <Image alt='Chrysler Pacifica' src={app} />
        </HomeTop>
        <ArrowDownContainer>
          <Button
            scrolled={scrolled}
            onClick={this.onClick}
          >
            <ArrowDown />
          </Button>
        </ArrowDownContainer>
        <MiddleTopContainer className='info'>
          <MiddleTopLeftContainer>
            <MiddleTopMessage>
              <Title>OBTÉN MÁS BENEFICIOS UTILIZANDO NUESTRA APP</Title>
              <Text>Agenda tu cita desde nuestra aplicación móvil y descubre múltiples beneficios:</Text>
            </MiddleTopMessage>
            <IconBox>
              <Icon icon={['far', 'calendar-alt']} />
              <Text>Programa tus siguientes citas desde tu teléfono.</Text>
            </IconBox>
          </MiddleTopLeftContainer>
          <FlexibleBox flex={1}>
            <TopImage alt='Smartphone' src={Assets.app1} />
          </FlexibleBox>
        </MiddleTopContainer>
        <MiddleBottomContainer>
          <MiddleIconsContainer>
            <IconBox>
              <FlexibleBox>
                <Icon icon={['fas', 'mobile-alt']} />
              </FlexibleBox>
              <Text>Revisa el estatus de tu automóvil desde cualquier lugar.</Text>
            </IconBox>
            <IconBox middleBottom>
              <Icon icon={['fas', 'home']} />
              <Text>Solicita la recolección de tu vehículo a domicilio.</Text>
            </IconBox>
            <IconBox middleBottom>
              <Icon icon={['fas', 'car']} />
              <Text>Y mucho más.</Text>
            </IconBox>
            <MiddleLeftBottom>
              <Title>DESCÁRGALA EN TU CELULAR</Title>
              <FlexibleBox>
                <a rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.kondosoft.automotrizTabasco' target='_blank'>
                  <img alt='google play' src={Assets.googlePlayLg} />
                </a>
                <a rel="noopener noreferrer" href='https://apps.apple.com/mx/app/garage-ata/id1468124017' target='_blank'>
                  <img alt='app store' src={Assets.appStoreLg} />
                </a>
              </FlexibleBox>
            </MiddleLeftBottom>
          </MiddleIconsContainer>
          <FlexibleBox flex={1}>
            <FlexibleBox flex={1}>
              <TopImage alt='App' src={Assets.app2} />
            </FlexibleBox>
            <FlexibleBox flex={1}>
              <TopImage alt='Store' src={Assets.app3} />
            </FlexibleBox>
          </FlexibleBox>
        </MiddleBottomContainer>
        <BottomContainer>
          <FlexibleBox flex={1}>
            <Title>Encuentra el mejor servicio para tu vehículo.</Title>
          </FlexibleBox>
          <FlexibleBox flex={1}>
            <Text>Somos distribuidores oficiales.</Text>
          </FlexibleBox>
          <BrandsContainer>
            <img alt='Chrysler Logo' src={Assets.chryslerLogo} />
            <img alt='Dodge Logo' src={Assets.dodgeLogo} />
            <img alt='Fiat Logo' src={Assets.fiatLogo} />
            <img alt='Jeep Logo' src={Assets.jeepLogo} />
            <img alt='Ram Logo' src={Assets.ramLogo} />
          </BrandsContainer>
        </BottomContainer>
        <Footer />
      </Container>
    )
  }
}

export default connect(null, null)(Home)
