const initialState = {}

const User = (state = initialState, {type, payload}) => {
  switch (type) {
    case 'GET_USER_FROM_LOGIN':
      return {
        ...state,
        ...payload
      }
    case 'LOGOUT':
      return {}
    default:
      return state
  }
}

export default User
