const initialSate = {
  appointments: [],
  appointmentsCalendar: [],
  orderBy: 'asc',
  filteredAppointment: {}
}

export default (state = initialSate, { type, payload }) => {
  switch (type) {
    case 'GET_APPOINTMENTS_SUCCESS':
      return {
        ...state,
        appointments: payload
      }
    case 'ORDER_BY_SUCCESS':
      return {
        ...state,
        orderBy: payload
      }
    case 'FILTER_APPOINTMENT_SUCCES':
      return {
        ...state,
        filteredAppointment: payload
      }
    case 'GET_APPOINTMENT_CALENDAR_SUCCES':
      return {
        ...state,
        appointmentsCalendar: payload
      }
    default:
      return state
  }
}