const getUserFromSession = () => {
  if (sessionStorage && sessionStorage.ata_tab) {
    const user = JSON.parse(sessionStorage.ata_tab)
    return user
  }

  return false
}

const setSession = user => {
  sessionStorage.ata_tab = JSON.stringify(user)
}

const updateSession = updates => {
  sessionStorage.ata_tab = JSON.stringify({
    ...getUserFromSession(),
    ...updates
  })
}

const destroySession = () => !sessionStorage.clear()

export { getUserFromSession, setSession, updateSession, destroySession }
  