import { getUserFromSession } from '../../utils/session'
import {
  yellow,
  green,
  blue,
  red,
  orange
} from '../../colorPallette.json'

const URL = process.env.REACT_APP_BASE_URL

export const getAppointments = () => {
  return dispatch => {
    let { key } = getUserFromSession()
    fetch(`${URL}/appointments-history/`, {
      headers: {
        Authorization: `Token ${key}`
      }
    })
      .then(response => response.json())
      .then(response => {
        let appointments = formatAppointments(response.results)
        dispatch(appointmentsSuccess(appointments))
      })
      .catch(e => window.alert(JSON.stringify(e)))
  }
}

function appointmentsSuccess(payload) {
  return {
    type: 'GET_APPOINTMENT_CALENDAR_SUCCES',
    payload
  }
}

export function formatAppointments(appointments = []) {
  var formatedAppointments = []
  appointments.forEach(current => {
    const date = new Date(current.date)
    date.setDate(date.getDate() + 1)
    const after15Min = new Date(current.date)
    after15Min.setDate(after15Min.getDate() + 1)
    if (current.hour) {
      const [h, m, s] = current.hour.split(':')
      date.setHours(h)
      date.setMinutes(m)
      date.setSeconds(s)
      after15Min.setHours(h)
      after15Min.setMinutes('15')
      after15Min.setSeconds(s)
    }
    var aux = {}
    aux['title'] = current.appointment_id ? `#${current.appointment_id}` : current.order_number
    aux['start'] = date
    aux['end'] = after15Min
    aux['status'] = switchColorStatus(current.status).text
    aux['color'] = switchColorStatus(current.status).color
    aux['id'] = current.appointment_id ? current.appointment_id : current.order_number
    formatedAppointments.push(aux)
  })
  return formatedAppointments
}

function switchColorStatus(status) {
  switch (status) {
    case 'CAN':
      return {
        color: red,
        text: 'CANCELADO'
      }
    case 'STOPPED':
      return {
        color: yellow,
        text: 'DETENIDO'
      }
    case 'SERVICE':
      return {
        color: orange,
        text: 'SERVICIO'
      }
    case 'ING':
      return {
        color: blue,
        text: 'CITA'
      }
    default:
      return {
        color: green,
        text: 'FINISHED'
      }
  }
}
