import styled from 'styled-components'

export const Container = styled.div`
  background-color: #F5F5F5;
  padding: 1.5rem 0rem;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
`

export const CalendarWrapper = styled.div`
    width: 95%;
    background: #FFF;
    /* padding: 1.5rem; */
    flex: 1;
`
