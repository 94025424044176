import swal from '@sweetalert/with-react'
import { destroySession } from '../../utils/session'
const URL = process.env.REACT_APP_BASE_URL

export const onLogout = (history) => {
  return async dispatch => {
    try {
      const response = await fetch(`${URL}/rest-auth/logout/`, {
        method: 'POST'
      })
      const isLoguedOut = await response.json()
      console.log(isLoguedOut)
      if (isLoguedOut && isLoguedOut.detail) {
        destroySession()
        dispatch({ type: 'LOGOUT' })
        dispatch({ type: 'GET_APPOINTMENTS_OUT' })
        dispatch({ type: 'SELECT_CONNECTION', payload: null })
        history.replace('/')
      } else {
        swal('Uh-Oh!', 'No podemos cerrar sesión por el momento', 'warning', {
          timer: 2000,
          button: false,
          closeOnClickOutside: false
        })
      }
    } catch (error) {
      swal('Uh-Oh!', error.message, 'warning', {
        timer: 4000,
        button: false,
        closeOnClickOutside: false
      })
    }
  }
}
